import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";

import { ContentService } from "../services/content.service";
import { DateService } from "../services/date.service";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
})
export class NewsComponent implements OnInit {
  content: any;
  title: any;
  prefixedDa: any;
  subscriptionPrefix = "/subscriptionform";
  currentLang: string = null;
  subscriberForm: FormGroup;
  subSubmitted:boolean = false;
  unSubSubmitted:boolean = false;

  constructor(
    private contentService: ContentService,
    private languageService: LanguageService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dateService: DateService,
    private _toastr: ToastrService
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnInit() {
    this.subscriberForm = this.formBuilder.group({
      email:['',[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      isTermsAccepted:[false]
    });

    if (!this.contentService.getContent()) {
      this.contentService.onContentEventLoaded().subscribe((any) => {
        this.setup();
      });
      this.contentService.loadPublichedContent();
    } else {
      this.setup();
    }
  }

  get f(){
    return this.subscriberForm.controls
  }

  setup() {
    this.content = this.contentService.getContent();
    this.content.prefixes.forEach((element) => {
      if (element.prefix === this.currentLang+"/news") {
        this.prefixedDa = element.articles;
        element.elements.forEach((element) => {
          if (element.shortName == "newslist_title") {
            this.title = element.text1;
          }
        });
      }
    });
    this.prefixedDa.sort((val1, val2) => {
      return val2.createdAt > val1.createdAt ? 1 : -1;
    });
  }

  onSelect(article) {
    // console.log(article.shortName);
    this.router.navigateByUrl(this.currentLang+"/news/" + article.shortName);
  }
/* -----SUBSCRIBE---------- */
  subscribe(){
    this.subSubmitted = true;
    this.unSubSubmitted = false;
    let data = this.subscriberForm.value;
    if(this.subscriberForm.invalid || !data.isTermsAccepted){
      return;
    }
 
    let subscriberObj = {
      email : data.email
    }

    this.contentService.subscribe(subscriberObj).subscribe(res=>{
      this._toastr.success( this.getText(this.subscriptionPrefix, "subscription_successfull_msg"));
    },err=>{

      this._toastr.error(this.getText(this.subscriptionPrefix, "subscription_unsuccessfull_msg"));
    })
  }
/* -----UN-SUBSCRIBE---------- */
  unSubscribe(){
    this.unSubSubmitted = true;
    this.subSubmitted = false; 
    let data = this.subscriberForm.value;
    if(this.subscriberForm.invalid){
      return;
    }
    let subscriberObj = {
      email : data.email
    }

    this.contentService.unSubscribe(subscriberObj).subscribe(res=>{
      this._toastr.success( this.getText(this.subscriptionPrefix, "subscription_unsubscribe_success_msg"));
    },err=>{
      this._toastr.error(this.getText(this.subscriptionPrefix, "subscription_unsubscribe_fail_msg"));
    })
  }

  getText(prefix: string, shortName: string) {
    return (this.content = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  getBodyText(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(this.currentLang + prefix, shortName);
  }

}
