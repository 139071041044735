import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {EnvironmentService} from "./environment.service";
import {ConsultationResponse} from "../models/consultationresponse";
import {Observable} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {HttpParams} from "../../../node_modules/@angular/common/http";
import {ConsultationResponsePageableList} from "../models/consultationresponsepageablelist";

const httpOptions = {
  headers: new HttpHeaders({
    // 'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable()
export class ConsultationdataService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  addConsultationResponce (consultationResponse: ConsultationResponse, files: Blob[]): Observable<ConsultationResponse> {

    const formData: FormData = new FormData();

    for (const file in files) {
      if (file) {
        formData.append('files', files[file]);
      }
    }

    formData.append('consultationResponse', new Blob([JSON.stringify(consultationResponse)], { type: 'application/json; charset=UTF-8' }));

    return this.http.post(this.environmentService.getEnvironment().serviceurl_portalcache + "consultation/response/create", formData, httpOptions)
      .pipe(
        tap((data: any) => {
         // console.log(data);
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  public getConsultationResponses(planversionid: string, page: number, pageSize: number): Observable<ConsultationResponsePageableList> {
    const params = new HttpParams()
      .set('planversionid', planversionid)
      .set("page", page.toString())
      .set("size", pageSize.toString());

    return this.http.get<ConsultationResponsePageableList>(
      this.environmentService.getEnvironment().serviceurl_portalcache + "consultation/response/published/get/all",
      { params })
      .pipe(
        tap((data: ConsultationResponsePageableList) => {
          return data;
        }),
        catchError((err) => {
          throw 'Error in source. Details: ' + JSON.stringify(err);
        })
      );
  }

  public getConsultationResponsesBySearch(planversionid: string, term: string, page: number, pageSize: number): Observable<ConsultationResponsePageableList> {
    const params = new HttpParams()
      .set('planversionid', planversionid)
      .set("term", term)
      .set("page", page.toString())
      .set("size", pageSize.toString());

    return this.http.get<ConsultationResponsePageableList>(
      this.environmentService.getEnvironment().serviceurl_portalcache + "consultation/response/published/search",
      { params })
      .pipe(
        tap((data: ConsultationResponsePageableList) => {
          // console.log("ConsultationResponse search result: " + JSON.stringify(data, null, 3));
          return data;
        }),
        catchError((err) => {
          throw 'Error in source. Details: ' + JSON.stringify(err);
        })
      );
  }

}
