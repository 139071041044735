import { Component, OnInit, TemplateRef } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { MapService } from "../services/map.service";
import { PlandataService } from "../services/plandata.service";
import { ContentService } from "../services/content.service";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-share-link",
  templateUrl: "./share-link.component.html",
  styleUrls: ["./share-link.component.scss"],
})
export class ShareLinkComponent implements OnInit {

  currentLang: string = null;
  modalRef: BsModalRef;
  isCopied: boolean = false;
  private _sharedUrl: string = "";
  shareCompleteUrl: any;
  content: any;
  sharelinkPrefix = "/sharelink";
  // rootPrefix = "da";

  constructor(
    private _modalService: BsModalService,
    private _toastr: ToastrService,
    private _mapService: MapService,
    private _plandataService: PlandataService,
    private _router: Router,
    private contentService: ContentService,
    private languageService: LanguageService
  ) {
     this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnInit() {}

  getLink(template: TemplateRef<any>) {
    this.isCopied = false;
    let sitepath = this._router.url.split("?")[0];
    if (this._router.url.toLowerCase().indexOf("/page/") > -1) {
      if(sitepath.includes("search")){
        this._sharedUrl = this.processSearchUrl(this.getBaseUrl()+sitepath);
      }
      else this._sharedUrl = this.getBaseUrl() + sitepath + this.getMapUrl();
    } else {
      this._sharedUrl = this.getBaseUrl() + sitepath;
    }
    this.setShareLinkurl(this._sharedUrl);
    this.modalRef = this._modalService.show(template, {
      class: "modal-dialog-centered",
    });
  }

  processSearchUrl(baseURL:string):string{
    let term:string = this._mapService.getTerm();

    if(term.length > 1){
      const [type, searchTerm] = term.split('-');
      if(type == "1" && searchTerm != ""){
        const[x,y] = searchTerm.split(" ");
        return baseURL+"?coordinates="+x+"%20"+y+this.getMapUrl().replace("?","&");
      }
      else if(type == "0" && searchTerm != ""){
        return baseURL + "?text=" + searchTerm + this.getMapUrl().replace("?","&");
      }
    }
    else return baseURL+this.getMapUrl();
  }

  private getMapUrl(): string {
    let mapCenter = this._mapService.getMapCenter();
    let zoom = this._mapService.getMapZoomLevel();
    let timeLineIdx = this._plandataService.currentTimeLineIndex;
    let activeTypes = this._mapService.zoneLayersActive;
    let mapUrlParts =
      "?timeLineIdx=" +
      timeLineIdx +
      "&zoom=" +
      zoom +
      "&x=" +
      mapCenter[0] +
      "&y=" +
      mapCenter[1];
    if (activeTypes.length > 0) {
      mapUrlParts += "&activeTypes=" + activeTypes.toString();
    }
    return mapUrlParts;
  }

  showSuccess(event) {
    this.isCopied = true;
    this._toastr.success(this.getText(this.sharelinkPrefix, "sharelink_click_to_copy"));
  }

  getText(prefix: string, shortName: string) {
    return (this.content = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  getAltText(prefix: string, shortName:string){
    return this.contentService.getAltTextByShortName(this.currentLang +prefix, shortName);
  }

  private getBaseUrl(): string {
    return window.location.origin + "";
  }

  private setShareLinkurl(url: string) {
    this.shareCompleteUrl = url;
  }
}
