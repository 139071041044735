import {Injectable} from '@angular/core';

@Injectable()
export class EnvironmentService {
    constructor(){

    }

    getEnvironment() {
        return {
            serviceurl_portalcache: '/portalcache/api/v1/',
            serviceurl_protectedportalcache: '/protectedportalcache/api/v1/',
            serviceurl_content: '/content/api/latest/',
            serviceurl_mapping: '/mapping/api/latest/',
        }
    }
}