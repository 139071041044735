export class MapHelper {
  constructor() {}

  static getCustomProps(props: any, params: any): any {
    if (props && props.parameters) {
      for (const key in props.parameters) {
        if (key) {
          params[key] = props.parameters[key];
        }
      }
    }
    if (props && props.params) {
      for (const key in props.params) {
        if (key) {
          params[key] = props.params[key];
        }
      }
    }
    return params;
  }

  static getParams(url: string, params: any): any {
    if (url) {
      const values = url.split("?");
      if (values && values.length > 1) {
        const pairs = values[1].split("&");
        for (const pair in pairs) {
          if (pair) {
            const vals = pairs[pair].split("=");
            if (vals && vals.length > 1) {
              params[vals[0]] = vals[1];
            }
          }
        }
      }
    }
    return params;
  }

  static layerSort(firstItem: any, secondItem: any) {
    if (firstItem.sort < secondItem.sort) {
      return -1;
    }
    if (firstItem.sort > secondItem.sort) {
      return 1;
    }
    return 0;
  }

  static addTokenToParams(token: string, params: any): any {
    let values = token.split("&");
    if (values && values.length > 1) {
      for (const key in values) {
        if (key) {
          const vals = values[key].split("=");
          if (vals && vals.length > 1) {
            params[vals[0]] = vals[1];
          }
        }
      }
    } else {
      values = token.split("=");
      if (values && values.length > 1) {
        params[values[0]] = values[1];
      }
    }
    return params;
  }
}
