import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as OlCoordinate from "ol/coordinate";
import * as OlExtent from "ol/extent";
import GMLFormat2 from "ol/format/GML2";
import GMLFormat3 from "ol/format/GML3";
import WFSFormat from "ol/format/WFS";
import { Tile as TileLayer } from "ol/layer";
import VectorLayer from "ol/layer/Vector";
import { TileWMS, Vector as VectorSource } from "ol/source";
import { Stroke, Style } from "ol/style";
import { Subscription } from "rxjs";
import { MapHelper } from "../map/util/mapHelper";
import { ContentService } from "../services/content.service";
import { DateService } from "../services/date.service";
import { LanguageService } from "../services/language.service";
import { MapService } from "../services/map.service";
import { PlandataService } from "../services/plandata.service";
import { RoutingService } from "../services/routing.service";

const regExpGeoPoint: RegExp = /^(\d*(\,?\d*)?) (\d*(\,?\d*)?)$/;
const regExpLatLongPoint: RegExp = /^(\d*(\.?\d*)?) (\d*(\.?\d*)?)$/;
const regExpLatLongPointDotComma: RegExp = /^(\d*(\.?\d*)?) (\d*(\,?\d*)?)$/;
const regExpLatLongPointCommaDot: RegExp = /^(\d*(\,?\d*)?) (\d*(\.?\d*)?)$/;

@Component({
  selector: "app-service-layers",
  templateUrl: "./service-layers.component.html",
  styleUrls: ["./service-layers.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceLayersComponent implements OnInit {
  private subscriptions: Subscription[] = new Array();

  private _modelOverlayers: any = {};
  _mapDefinition: any = null;

  displayItem: any = null;
  currentLang: string = null;

  checkedMapDefinition:number = 0;

  layersGroupVisible = {};

  //Prepare internal 'WMS layers' url based on "layer" and "style" parameter configuration in admin ui
  urlHost: string = window.location.origin;
  urlPart1: string = this.urlHost+"/geoserver/servicelayer/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&LAYER=servicelayer%3A";
  /* urlPart1: string = "http://localhost:8080/geoserver/servicelayer/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&LAYER=servicelayer%3A"; */
  urlPart2: string = "&STYLE=servicelayer%3A";
  urlPart3: string = "&exceptions=application%2Fvnd.ogc.se_inimage&SRS=EPSG%3A7791&WIDTH=18&HEIGHT=18";

  constructor(
    private planService: PlandataService,
    private languageService: LanguageService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private routeService: RoutingService,
    private contentService: ContentService,
    public dateService: DateService,
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    if(this.mapService.isMapLoaded()){
      this.displayItem = this.planService.mapDefinition
    }else{
      this.subscriptions.push(
        this.planService.getMapDefinition().subscribe((mapDefinition) => {
          let mapDefinitionTemp = mapDefinition;
          //Getting specific data within mapdefination api call
          if (
            mapDefinitionTemp &&
            mapDefinitionTemp.mapDefinition &&
            mapDefinitionTemp.mapDefinition.layerGroups &&
            mapDefinitionTemp.mapDefinition.layerGroups.length > 0
          ) {
            this._mapDefinition = mapDefinitionTemp.mapDefinition.layerGroups;

            let mapDefinitionLength = this._mapDefinition.length;

            this.displayItem = {};
            for (let i = 0; i < mapDefinitionLength; i++) {
              if(!this.displayItem[this._mapDefinition[i].orderColumn]){
                this.displayItem[this._mapDefinition[i].orderColumn] = [];
              }
              this.displayItem[this._mapDefinition[i].orderColumn].push(this._mapDefinition[i]);
            }

            this.planService.mapDefinition = this.displayItem;
          }
        })
      );
    }
  }

  getHTML(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(
      this.currentLang + prefix,
      shortName
    );
  }

  keys() {
    return this.displayItem ? Object.keys(this.displayItem) : [];
  }

  layersGroupToggle(groupName) {
    if (this.layersGroupVisible[groupName]) {
      delete this.layersGroupVisible[groupName];
    } else {
      this.layersGroupVisible[groupName] = true;
    }
  }

  checkUncheckModelOverlay(event,modelOverlayer){
    event.preventDefault();
    /*    console.log(JSON.stringify(modelOverlayer)) */
    modelOverlayer['checked'] = !modelOverlayer['checked']

    if(modelOverlayer['checked']){
      this.checkedMapDefinition = this.checkedMapDefinition + 1;
      this.setServiceLayerIsOn(true);
    }else{
      this.checkedMapDefinition = this.checkedMapDefinition - 1;
      if (this.checkedMapDefinition == 0) {
        this.setServiceLayerIsOn(false);
      }
    }

    if(modelOverlayer && modelOverlayer['defaultType'] === 'WMS'){
      if(modelOverlayer['checked'] && ((modelOverlayer['internalLayerName'] && modelOverlayer['stilartName'] && modelOverlayer['layerSource'] === 'INTERNAL')
        || (modelOverlayer['externalWmsUrl'] && modelOverlayer['layerSource'] === 'EXTERNAL'))){
        let includeWmsLayerInMap = this.getWmsModelOverlay(modelOverlayer,modelOverlayer['layerSource']);
        this._modelOverlayers[modelOverlayer.name] = includeWmsLayerInMap
        this.mapService.getMap().addLayer(includeWmsLayerInMap);
      }else{
        this.removeModelLayer(modelOverlayer['name']);
      }
    }else if(modelOverlayer && modelOverlayer['defaultType'] === 'WFS'){
      if(modelOverlayer['checked'] && modelOverlayer['externalWfsUrl']){
        //Add WFS layer code
        this.createWFSOverLayer(modelOverlayer);
      }else{
        //Remove WFS layer code
        this.removeModelLayer(modelOverlayer['name'])
      }
    }
    // this.createWFSOverLayer({'externalWfsUrl':"https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WFSServer?request=GetFeature&service=WFS&outputFormat=GML3&version=1.1.0&typeName=dmp:HABITAT_OMR&title=HABITAT_OMR"});
    //console.log("CHECKED/ UNCHECKED/")
  }

  openLinkField(url){
    window.open(url,'_blank')
  }

  getWmsModelOverlay(wmsModelOverlayer,layerSource){
    let url = null;
    let params = {};
    let opacity = 0.75;

    if(layerSource === 'INTERNAL'){
      url = this.urlHost+'/geoserver/servicelayer/wms';
      /*      url = 'http://localhost:8080/geoserver/servicelayer/wms';  */
      params = {
        'LAYERS':'servicelayer:' + wmsModelOverlayer['internalLayerName'], //layer
        'STYLES':'servicelayer:' + wmsModelOverlayer['stilartName'], //Style
        'FORMAT': "image/png"
      }
    }else if(layerSource === 'EXTERNAL'){
      url = wmsModelOverlayer['externalWmsUrl'];
      params = {
        'FORMAT': "image/png",
        'crossOrigin': "anonymous"
      }
    }else{
      return null;
    }

    //Overwrite the params by doing the configurion within options field
    if(wmsModelOverlayer['layerProps'] && typeof wmsModelOverlayer['layerProps'] === 'object' && wmsModelOverlayer['layerProps'] !== null){
      opacity = wmsModelOverlayer['layerProps']['opacity'] ? wmsModelOverlayer['layerProps']['opacity'] : opacity;
      if(wmsModelOverlayer['layerProps']['params']){
        this.overWriteParams(params,wmsModelOverlayer['layerProps']['params'])
      }
    }

    let includeWmsLayerInMap = new TileLayer({
      'opacity': opacity,
      'source': new TileWMS({
        'url': url,
        'params':params
      })

    })
    return includeWmsLayerInMap;
  }

  createWFSOverLayer(overlay): void {
    /*  console.log("Crete WFS overlay") */
    const props = overlay.layerProps as any;
    let layerParams: any = {};
    layerParams = MapHelper.getCustomProps(props, layerParams);
    //console.log(JSON.stringify(layerParams))
    layerParams = MapHelper.getParams(overlay.externalWfsUrl, layerParams);
    //console.log(JSON.stringify(layerParams))
    const urlparts = overlay.externalWfsUrl.split('?');

    if (urlparts && urlparts.length > 1) {
      overlay.externalWfsUrl = urlparts[0] + '?';
    }
    for (const key in layerParams) {
      if (key) {
        overlay.externalWfsUrl += key + '=' + layerParams[key] + '&';
      }
    }

    const loadingStrategy = function(extent, resolution) {
      if(this.resolution && this.resolution != resolution){
        this.loadedExtentsRtree_.clear();
      }
      return [extent];
    };

    const thisme = this;
    const vectorSource = new VectorSource({
      format: new WFSFormat({ gmlFormat: new GMLFormat2() }),
      loader: function (extent, resolution, projection) {
        // this.resolution = resolution;
        /* console.log(overlay.externalWfsUrl) */
        // console.log(thisme.mapService.currentMapEPSG.toLowerCase())
        let url = new URL(overlay.externalWfsUrl + '&srsName=epsg:25832');
        /*  console.log(url) */
        let params =url.searchParams;

        if(params && params.get("filter") && extent && extent.length ==4 ) {
          const oParser = new DOMParser();
          let oDOM = oParser.parseFromString(decodeURIComponent(params.get("filter")), "application/xml");
          let bbox = oDOM.getElementsByTagName("BBOX");
          if(bbox && bbox.length>0) {
            let lowerCorner = bbox[0].getElementsByTagName("lowerCorner");
            if(lowerCorner && lowerCorner.length>0) {
              lowerCorner[0].innerHTML = OlCoordinate.format(OlExtent.getBottomLeft(extent), '{x} {y}', 3);
            }
            let upperCorner = bbox[0].getElementsByTagName("upperCorner");
            if(upperCorner && upperCorner.length>0) {
              upperCorner[0].innerHTML = OlCoordinate.format(OlExtent.getTopRight(extent), '{x} {y}', 3);
            }
            bbox[0].setAttribute("srsName",'epsg:25832');
          }
          var oSerializer = new XMLSerializer();
          var sXML = oSerializer.serializeToString(oDOM);

          params.delete("filter");

          url = new URL(url.toString() + '&filter=' + encodeURIComponent(sXML));

        } else if(extent && extent.length ==4) {
          url = new URL(url.toString() + '&bbox=' + extent.join(','));
        }

        /* console.log("==>"+url.href) */
        thisme.mapService.callByProxy(url.href).subscribe(response => {
          if(params.get("version") && params.get("version").indexOf("1.0.0") > -1) {
            vectorSource.addFeatures(new WFSFormat({gmlFormat: new GMLFormat2() }).readFeatures(response.body));
          }else {
            vectorSource.addFeatures(new WFSFormat({gmlFormat: new GMLFormat3() }).readFeatures(response.body));
          }
        });
      },
      strategy: loadingStrategy,
      // projection: OlProj.get("EPSG:25832")
    });

    let stroke = {};
    if(overlay.layerProps && overlay.layerProps.stroke ){
      stroke = overlay.layerProps.stroke;
    }
    const vector = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke(stroke),
      })
    });

    this._modelOverlayers[overlay.name] = vector;
    //@ts-ignore
    this.mapService.getMap().addLayer(vector);
  }

  overWriteParams(params,source){
    for(let key of Object.keys(source)){
      /* console.log(key) */
      if(typeof source[key] === 'object' && source[key] !== null){
        if(!params[key.toUpperCase()]){
          params[key.toUpperCase()] = {};
        }
        this.overWriteParams(params[key.toUpperCase()],source[key])
      }else{
        params[key.toUpperCase()] = source[key];
      }
    }
  }

  removeModelLayer(modelOverlayerName){
    let isFoundRecord:boolean  = false;
    if(this._modelOverlayers && this._modelOverlayers[modelOverlayerName]){
      // console.log("Found And Remove::")
      this.mapService.getMap().removeLayer(this._modelOverlayers[modelOverlayerName]);
      delete this._modelOverlayers[modelOverlayerName];
    }
  }

  private setServiceLayerIsOn(isOn: boolean) {
    this.mapService.setServiceLayerIsOn(isOn);
    this.mapService.getMap().getLayers().forEach(layer => layer.getSource().changed());
  }
}
