import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TimePlanversionEvent } from "../models/timeplanversionevent";
import { ZoneFeature } from "../plandataDefinition/zoneFeature";
import { ContentService } from "../services/content.service";
import { MapService } from "../services/map.service";
import { PlandataService } from "../services/plandata.service";
import { RoutingService } from "../services/routing.service";

import { NgbCarousel, NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";

import { DomSanitizer } from "@angular/platform-browser";
import { DescriptionTextPdf } from "../plandataDefinition/descriptionTextPdf";
import { PlanInfo } from "../plandataDefinition/planInfo";
import { DateService } from "../services/date.service";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "zones-info",
  templateUrl: "./zones.component.html",
  styleUrls: ["./zones.component.scss"],
  providers: [NgbCarouselConfig],
})
export class ZonesComponent implements OnInit, OnDestroy {
  @Input() isAutonomousModeOn;
  @Input() autonomousSelectedFeatureCallback;
  @ViewChild("zonesInfo", { read: ElementRef, static: false })
  zonesInfoRef: ElementRef;

  //private subscriptions: Subscription[] = new Array();
  private subscription: Subscription = new Subscription();
  private mapReady1: Subscription = Subscription.EMPTY;
  private mapReady2: Subscription = Subscription.EMPTY;

  currentLang: string = null;
  // infoPrefix = "da/plan/info";
  // tabsPrefix = "da/plan/tabs";
  contentString: any;
  infoContent: any;
  tabsContent: any;

  historicClass: string;
  lawtextContent: any;
  lawtextPrefix = "/plan/lawtext";
  innerWidth: any;
  currDiv: string = "info";
  singleZone: ZoneFeature = null;
  lawTextZoneId: string;
  lawTextZoneType: string;
  titlePrefixZoneId: string;
  allZonesLawText: string;
  descriptionTextPdf: DescriptionTextPdf;
  otherSelectedZones = new Array();
  lawDefault: boolean = false;
  multipleLayers: boolean = false;
  singleLayer: boolean = false;
  selectedZones: ZoneFeature[] = new Array();
  listFeatures: ZoneFeature[] = new Array();
  hoveredFeatureZones: ZoneFeature[] = new Array();
  selectedBoxFeatureRefId: string = "";
  private _paramsDefined: boolean = false;
  private _showZoneData = false;
  public subscribers: any = {};
  timeLine: any;
  showTimeSlider: boolean = false;
  endValue: string;
  startValue: string;
  private _mapIndex = new Array();
  private _activeTimeLine = new Array();
  _chosenDate = -1;
  //for history line time slider
  nextIndx: number = 0;
  prevIndx: number = 8;
  withCordinates: boolean = false;
  fromConsultation: boolean = false;

  @ViewChild("mycarousel", { static: true }) carousel: NgbCarousel;

  constructor(
    private planService: PlandataService,
    private mapService: MapService,
    private routingService: RoutingService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    public dateService: DateService,
    private languageService: LanguageService,
    public sanitizer: DomSanitizer
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.mapReady1.unsubscribe();
    this.mapReady2.unsubscribe();
    //this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private getRouteParams() {
    let coordinates = new Array();
    if (this.route.snapshot.params["x"] && this.route.snapshot.params["y"]) {
      coordinates.push(this.route.snapshot.params["x"]);
      coordinates.push(this.route.snapshot.params["y"]);
    }

    if (
      this.route.snapshot.params["planversionid"] &&
      this.route.routeConfig.path.indexOf("/s/") > -1
    ) {
      let zone = this.mapService.findZoneFeature(
        this.route.snapshot.params["planversionid"],
        this.planService.getLayerNameByTextName(
          this.route.snapshot.params["name"]
        ),
        this.route.snapshot.params["id"]
      );
      if (zone) {
        this.mapService.removeSelectedFeatures(zone);
        this.handlingFeatures([zone], coordinates);
        if (coordinates.length > 1) {
          this.mapService.clickedCoordinates = coordinates;
          let zoneFeatures =
            this.mapService.getFeaturesByCooordinat(coordinates);
          if (zoneFeatures.length > 1) {
            this.multipleLayers = true;
            // other zones
            let other = zoneFeatures;
            for (let i = 0; i < other.length; i++) {
              if (zone.refId != other[i].refId)
                this.otherSelectedZones.push(other[i]);
            }
          }
        }
      } else {
        this.singleLayer = true;
        this.singleZone = null;
        //console.log("TODO2: Feature not found!!!!");
        this.setupTimeLine();
      }
    }
  }

  private initSetup() {
    this.mapService.selectEnabled = true;
    this.mapService.deactivateDrawingTool(true);
    let me = this;

    this.subscription.add(
      this.route.params.subscribe((params) => {
        this.withCordinates = false;
        me._paramsDefined = true;
        let coordinates = new Array();
        if (params["x"] && params["y"]) {
          coordinates.push(params["x"]);
          coordinates.push(params["y"]);
          this.withCordinates = true;
        }

        if (
          params["planversionid"] &&
          this.route.routeConfig.path.indexOf("/s/") > -1
        ) {
          me._showZoneData = true;
          let zone = me.mapService.findZoneFeature(
            params["planversionid"],
            me.planService.getLayerNameByTextName(params["name"]),
            params["id"]
          );
          if (zone) {
            // console.log("Found zone | zone: " + JSON.stringify(zone, null, 3));
            me.mapService.removeSelectedFeatures(zone);
            me.handlingFeatures([zone], coordinates);
            if (coordinates.length > 1) {
              this.mapService.clickedCoordinates = coordinates;
              let zoneFeatures =
                this.mapService.getFeaturesByCooordinat(coordinates);
              if (zoneFeatures.length > 1) {
                this.multipleLayers = true;
                // other zones
                let other = zoneFeatures;
                for (let i = 0; i < other.length; i++) {
                  if (zone.refId != other[i].refId)
                    this.otherSelectedZones.push(other[i]);
                }
              }
            }
          } else {
            this.singleLayer = true;
            this.singleZone = null;
            this.lawDefault = false;
            if (coordinates.length > 0) {
              this.mapService.clickedCoordinates = coordinates;
              this.mapService.addIconMarker(coordinates);
            }
            this.setupTimeLine();
          }
        }

        if (this.isAutonomousModeOn) {
          me._showZoneData = true;
          me.handlingFeatures(me.mapService.getSelectedFeature(), coordinates);
        }

        if (
          this.route.routeConfig.path &&
          this.route.routeConfig.path.indexOf("/m/") > -1
        ) {
          me._showZoneData = true;
          this.mapService.clickedCoordinates = coordinates;
          this.mapService.addIconMarker(coordinates);
          this.mapService.setFeature(coordinates);
          me.handlingFeatures(me.mapService.getSelectedFeature(), coordinates);
        }

        if (
          this.route.routeConfig.path &&
          this.route.routeConfig.path &&
          this.route.routeConfig.path.indexOf("/n/") > -1
        ) {
          me._showZoneData = true;
          if (coordinates.length > 0) {
            this.mapService.clickedCoordinates = coordinates;
            let zoneFeatures =
              this.mapService.getFeaturesByCooordinat(coordinates);
            me.handlingFeatures(zoneFeatures, coordinates);
            this.mapService.addIconMarker(coordinates);
          }

          if (params["type"] && params["type"] == "nozone") {
            this.showNoZoneMessage();
          }
        }

        this.subscription.add(
          this.mapService.onFeatureHover().subscribe((zoneFeatures) => {
            me.hoveredFeatureZones = zoneFeatures;
          })
        );
      })
    );

    this.subscription.add(
      this.route.queryParams.subscribe((queryParams) => {
        this.fromConsultation = queryParams.consultation;
        if (queryParams.timeLineIdx) {
          this.getRouteParams();
          // console.log("NO ZONE2");
        }
      })
    );
  }

  ngOnInit() {
    this._paramsDefined = false;
    this._showZoneData = false;
    const me = this;
    if (!this.mapService.isMapLoaded()) {
      if (this.mapReady1.closed) {
        this.mapReady1 = this.mapService.onMapReady().subscribe((status) => {
          me.initSetup();
        });
      }
    } else {
      me.initSetup();
    }

    this.subscription.add(
      this.planService
        .onTimePlanversionChanged()
        .subscribe((event: TimePlanversionEvent) => {
          //console.log(this.planService.currentTimeLineIndex + " : "+event.timeLineIndex);
          let urlTimeIndex = -1;
          if (this.route.snapshot.queryParams) {
            if (this.route.snapshot.queryParams.timeLineIdx != null) {
              urlTimeIndex = this.route.snapshot.queryParams.timeLineIdx;
              let currentTimeIndex = this.planService.currentTimeLineIndex;
              if (
                urlTimeIndex != undefined &&
                urlTimeIndex != -1 &&
                currentTimeIndex != urlTimeIndex
              ) {
                if (!this.mapService.isMapLoaded()) {
                  let me = this;
                  if (this.mapReady2.closed) {
                    this.mapReady2 = this.mapService
                      .onMapReady()
                      .subscribe((status) => {
                        me.changePageForTimeChange(
                          me.planService.currentTimeLineIndex
                        );
                      });
                  }
                } else {
                  //console.log("Map loaded");
                  this.changePageForTimeChange(currentTimeIndex);
                }
              }
            }
          }
        })
    );

    if (!this._paramsDefined) {
      this.handlingFeatures(this.mapService.getSelectedFeature(), []);
    } else {
      if (!this._showZoneData) {
        this.lawDefault = true;
        this.singleLayer = false;
        this.multipleLayers = false;
      }
    }
  }

  private changePageForTimeChange(currentTimeIndex: number): void {
    let coordinates = new Array();
    if (this.route.snapshot.params["x"] && this.route.snapshot.params["y"]) {
      coordinates.push(this.route.snapshot.params["x"]);
      coordinates.push(this.route.snapshot.params["y"]);
    }
    let zoneFeatures = null;
    if (coordinates.length > 0) {
      zoneFeatures = this.mapService.getFeaturesByCooordinat(coordinates);
    } else if (
      this.route.snapshot.params["id"] &&
      this.route.snapshot.params["name"]
    ) {
      //console.log("named zonefeature found");
      let refId = this.route.snapshot.params["id"];
      let layerName = this.planService.getLayerNameByTextName(
        this.route.snapshot.params["name"]
      );
      let zoneFeatures = this.mapService.findZoneFeatures(layerName, refId);
      if (this.singleZone && zoneFeatures && zoneFeatures.length != 0) {
        let featureFound = null;
        for (let i = 0; i < zoneFeatures.length; i++) {
          if (this.singleZone.refId == zoneFeatures[i].refId) {
            featureFound = zoneFeatures[i];
          }
        }
        if (featureFound) {
          this.changeSingleZoneWithOutCoordinatRoutePath(
            featureFound,
            currentTimeIndex
          );
        }
      } else {
        this.changeNoZoneMessageRoutePath(currentTimeIndex);
      }
      return;
    }

    if (zoneFeatures && zoneFeatures.length != 0) {
      if (this.singleZone && zoneFeatures.length == 1) {
        if (this.singleZone.refId == zoneFeatures[0].refId) {
          //console.log("found zonefeature single before: "+this.singleZone.planInfo.planversionId +" : "+zoneFeatures[0].planInfo.planversionId);
          this.changeSingleZoneRoutePath(
            zoneFeatures[0],
            coordinates[0],
            coordinates[1],
            currentTimeIndex
          );
        } else {
          //console.log("found zonefeature change" +this.singleZone.refId == zoneFeatures[0].refId);
          this.changeZoneMessageRoutePath(
            coordinates[0],
            coordinates[1],
            "otherzone",
            currentTimeIndex
          );
        }
      } else {
        let countSameRefId = 0;
        let foundZone = null;
        for (let i = 0; i < zoneFeatures.length; i++) {
          if (
            this.singleZone &&
            zoneFeatures[i].refId == this.singleZone.refId
          ) {
            foundZone = zoneFeatures[i];
            countSameRefId++;
          }
        }

        if (countSameRefId > 1) {
          //console.log("found zonefeature multi");
          this.changeMultiZoneRoutePath(
            coordinates[0],
            coordinates[1],
            currentTimeIndex
          );
        } else if (countSameRefId == 1) {
          //console.log("found zonefeature same");
          this.changeSingleZoneRoutePath(
            foundZone,
            coordinates[0],
            coordinates[1],
            currentTimeIndex
          );
        } else {
          //console.log("found zonefeature new");
          this.changeZoneMessageRoutePath(
            coordinates[0],
            coordinates[1],
            "otherzone",
            currentTimeIndex
          );
        }
      }
    } else {
      //console.log("No found zonefeature");
      if (coordinates.length > 0) {
        this.changeZoneMessageRoutePath(
          coordinates[0],
          coordinates[1],
          "nozone",
          currentTimeIndex
        );
      } else {
        this.changeNoZoneMessageRoutePath(currentTimeIndex);
      }
    }
  }

  private showNoZoneMessage() {
    this.setupTimeLine();
    this.singleZone = null;
    this.singleLayer = true;
    this.lawDefault = false;
  }

  private handlingFeatures(zoneFeatures: ZoneFeature[], coordinates: number[]) {
    this.listFeatures = zoneFeatures;
    this.selectedZones = this.listFeatures;
    if (this.listFeatures && this.listFeatures.length) {
      this.lawDefault = false;
      if (this.listFeatures.length === 1) {
        this.singleLayer = true;
        this.multipleLayers = false;
        let zoneFeature = this.selectedZones[0];
        this.initiateSingleZone(zoneFeature);
        this.setupTimeLine();
        this.mapService.addIconMarker(coordinates);
        this.mapService.flyTofeatureByZoneType(
          zoneFeature.refId,
          zoneFeature.zoneType
        );
      } else if (this.selectedZones.length >= 1) {
        this.multipleLayers = true;
        this.singleLayer = false;
        this.mapService.addIconMarker(coordinates);
        this.mapService.flyToFeaturesByZoneTypes(this.selectedZones);
      }
    } else {
      this.lawDefault = true;
      this.singleLayer = false;
      this.multipleLayers = false;
    }
  }

  getText(prefix: string, shortName: string) {
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  getAltText(prefix, shortName) {
    return this.contentService.getAltTextByShortName(
      this.currentLang + prefix,
      shortName
    );
  }

  getBodyText(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(
      this.currentLang + prefix,
      shortName
    );
  }

  getContentElement(prefix: string, shortName: string): any {
    return this.contentService.getCmsElementByShortName(
      this.currentLang + prefix,
      shortName
    );
  }

  formatDate(datetime: Date, includeTime: boolean) {
    let result: string = "-";
    if (includeTime) {
      result = new Date(datetime).toLocaleString("da-DK", {
        timeZone: "Europe/Copenhagen",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      result = new Date(datetime).toLocaleDateString("da-DK", {
        timeZone: "Europe/Copenhagen",
      });
    }
    return result;
  }

  highlightZoneStyle(layerName: string, styleType: string, refId: string) {
    this.selectedBoxFeatureRefId = refId;
    this.mapService.setFeatureStyle(layerName, refId, styleType, "highlight");
  }

  selectedZoneStyle(layerName: string, styleType: string, refId: string) {
    if (this.selectedBoxFeatureRefId == refId) {
      this.selectedBoxFeatureRefId = "";
    }
    if (this.singleLayer) {
      this.mapService.setFeatureStyle(layerName, refId, styleType, styleType);
    } else {
      this.mapService.setFeatureStyle(layerName, refId, styleType, "selected");
    }
  }

  initiateSingleZone(zone: ZoneFeature) {
    this.otherSelectedZones = [];
    this.singleZone = zone;
    const refId = this.singleZone.refId;
    let zoneType: string = this.singleZone.zoneType;
    const planversionId = this.singleZone.planInfo.planversionId;

    // const status = "consultation"; //this.singleZone.planInfo.status;
    const status = this.singleZone.planInfo.type == "consultation" ? "consultation" : "approved";

    const layerName = this.singleZone.layerName;

    // console.log("initSingleZone | zone: " + JSON.stringify(zone, null, 3));

    this.lawTextZoneId = this.planService.getLawstextByZoneId(
      refId,
      planversionId,
      status,
      this.currentLang
    );

    this.lawTextZoneType = this.planService.getLawstextByZoneType(
      zoneType.toString(),
      planversionId,
      status,
      this.currentLang
    );

    this.titlePrefixZoneId = this.planService.getTitlePrefixByZoneId(
      refId,
      zoneType.toString(),
      planversionId,
      status,
      this.currentLang
    );

    this.allZonesLawText = this.planService.getAllZonesLawText(
      planversionId,
      status,
      this.currentLang
    );

    this.descriptionTextPdf = this.planService.getDescriptionTextPdf(
      planversionId,
      this.singleZone,
      this.currentLang
    );

    for (let i in this.selectedZones) {
      if (
        this.selectedZones[i].refId !== this.singleZone.refId ||
        this.selectedZones[i].zoneType != this.singleZone.zoneType ||
        this.selectedZones[i].planInfo.status != this.singleZone.planInfo.status
      ) {
        this.otherSelectedZones.push(this.selectedZones[i]);
      }
    }
    this.highlightZoneStyle(
      layerName,
      this.singleZone.planInfo.styleType,
      refId
    );
  }

  private setupTimeLine() {
    //===History Line on paragraph page==========
    this._mapIndex = new Array();
    this.timeLine = this.planService.getTimeline().reverse();
    for (let i = 0; i < this.timeLine.length; i++) {
      this._mapIndex.push(i);
    }
    this._mapIndex = this._mapIndex.reverse();
    this._chosenDate = this.findIndex(this.planService.currentTimeLineIndex);

    // set showed index
    //  console.log(this._chosenDate + " : " +JSON.stringify(this._mapIndex));
    //this._activeTimeLine

    if (this._chosenDate == 0) {
      this.showTimeSlider = false;
    } else {
      this.showTimeSlider = true;
    }
    this.startValue = "0";
    const first = Math.ceil(this.timeLine.length / 8);
    const second = first - 1;
    const third = second * 8;

    this.endValue = third.toString();
  }

  showDate(index: number) {
    if (this._chosenDate != index) {
      this._chosenDate = index;
      /*if(this._chosenDate  == 0) {
        this.showTimeSlider = false;
      }else {
        this.showTimeSlider = true;
      }*/
      this.loadPlanversionData(index);
    }
  }

  private loadPlanversionData(sliderindex: number) {
    this._chosenDate = sliderindex;
    let index = this.findIndex(sliderindex);
    this.mapService.setMapLoaded(false);
    this.planService.setPlanVersionFromHistorik(index);
  }

  private findIndex(index: number) {
    return this._mapIndex[index];
  }

  getZoneStatusText(planInfo: PlanInfo) {
    const isHistoric = this.planService.isCurrentTimeLineHistoric();

    if (isHistoric) {
      return this.getText(this.lawtextPrefix, "lawtext_is_historic");
    }

    switch (planInfo.status) {
      case "approved":
        return this.getText(this.lawtextPrefix, "lawtext_status_current");

      case "consultation":
        let dt = new Date(planInfo.consultationEnd);
        let formatted_date = this.dateService.format(dt);
        if (dt < this.planService.currentDateTime) {
          return this.getText(
            this.lawtextPrefix,
            "lawtext_status_in_consultation_hist"
          );
        }
        return (
          this.getText(
            this.lawtextPrefix,
            "lawtext_status_consultation_deadline"
          ) + ` ( ${formatted_date} )`
        );

      default:
        return this.getText(
          this.lawtextPrefix,
          "lawtext_status_expired_without_acceptance"
        );
    }
  }

  hideResponseListButtonOnPastTime(planInfo: PlanInfo) {
    if (planInfo.status == "consultation") {
      let dt = new Date(planInfo.consultationEnd);
      let formatted_date = this.dateService.format(dt);
      if (dt <= this.planService.currentDateTime) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkIDs(refId: string) {
    if (this.hoveredFeatureZones.find((f) => f.refId === refId)) {
      return true;
    } else if (this.selectedBoxFeatureRefId == refId) {
      return true;
    } else {
      return false;
    }
  }

  private changeMultiZoneRoutePath(
    xcoord: number,
    ycoord: number,
    timeLineIdx: number
  ) {
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/m/" +
        xcoord +
        "/" +
        ycoord +
        "?timeLineIdx=" +
        timeLineIdx
    );
  }

  private changeSingleZoneRoutePath(
    zone: ZoneFeature,
    xcoord: number,
    ycoord: number,
    timeLineIdx: number
  ) {
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/s/" +
        zone.planInfo.planversionId +
        "/" +
        xcoord +
        "/" +
        ycoord +
        "/" +
        zone.layerName +
        "/" +
        zone.refId +
        "?timeLineIdx=" +
        timeLineIdx
    );
  }

  private changeSingleZoneWithOutCoordinatRoutePath(
    zone: ZoneFeature,
    timeLineIdx: number
  ) {
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/s/" +
        zone.planInfo.planversionId +
        "/" +
        zone.layerName +
        "/" +
        zone.refId +
        "?timeLineIdx=" +
        timeLineIdx
    );
  }

  private changeZoneMessageRoutePath(
    xcoord: number,
    ycoord: number,
    type: string,
    timeLineIdx: number
  ) {
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/n/" +
        xcoord +
        "/" +
        ycoord +
        "/" +
        type +
        "?timeLineIdx=" +
        timeLineIdx
    );
  }

  public getTitlePrefixZoneId(zone: any): string {
    // return this.planService.getTitlePrefixByZoneId(zone.refId, zone.zoneType[0], zone.planInfo.planversionId, zone.planInfo.status, this.currentLang);
    return this.planService.getTitlePrefixByZoneId(
      zone.refId,
      zone.zoneType[0],
      zone.planInfo.planversionId,
      "consultation",
      this.currentLang
    );
  }

  private changeNoZoneMessageRoutePath(timeLineIdx: number) {
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/n/nozone?timeLineIdx=" +
        timeLineIdx
    );
  }

  goToZoneFromZonesList(zone: ZoneFeature) {
    if (this.isAutonomousModeOn && this.autonomousSelectedFeatureCallback) {
      this.autonomousSelectedFeatureCallback(zone);
      return;
    }

    let timeLineIdx = this.planService.currentTimeLineIndex;
    let coordinates = this.mapService.clickedCoordinates;
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/s/" +
        zone.planInfo.planversionId +
        "/" +
        coordinates[0] +
        "/" +
        coordinates[1] +
        "/" +
        zone.layerName +
        "/" +
        zone.refId +
        "?timeLineIdx=" +
        timeLineIdx
    );
  }

  goBackToMultipleLayers() {
    let timeLineIdx = this.planService.currentTimeLineIndex;
    let coordinates = this.mapService.clickedCoordinates;
    this.routingService.changeRoutePath(
      this.route.parent.routeConfig.path +
        "/zone/m/" +
        coordinates[0] +
        "/" +
        coordinates[1] +
        "?timeLineIdx=" +
        timeLineIdx
    );
    //this.routingService.changeRoutePath(this.route.parent.routeConfig.path + "/zone/m/" + this.selectedZones[0].planInfo.planversionId + "/" + coordinates[0] + "/" + coordinates[1] + "?timeLineIdx=" + timeLineIdx);
    this.selectedZoneStyle(
      this.singleZone.layerName,
      this.singleZone.planInfo.styleType,
      this.singleZone.refId
    );
  }

  goToSearch() {
    this.router.navigate(["/" + this.currentLang + "/page/search"]);
  }

  goToZoneFromSingleZone(zone: ZoneFeature) {
    let coordinates = this.mapService.clickedCoordinates;
    if (coordinates) {
      let timeLineIdx = this.planService.currentTimeLineIndex;
      this.mapService.setFeatureStyle(
        zone.layerName,
        zone.refId,
        zone.planInfo.styleType,
        zone.planInfo.styleType
      );
      if (
        this.zonesInfoRef &&
        this.zonesInfoRef.nativeElement &&
        this.zonesInfoRef.nativeElement.scrollTop
      ) {
        this.zonesInfoRef.nativeElement.scrollTop = 0;
      }

      this.routingService.changeRoutePath(
        this.route.parent.routeConfig.path +
          "/zone/s/" +
          zone.planInfo.planversionId +
          "/" +
          coordinates[0] +
          "/" +
          coordinates[1] +
          "/" +
          zone.layerName +
          "/" +
          zone.refId +
          "?timeLineIdx=" +
          timeLineIdx
      );
    }
  }

  clickGoToConsultation(featureZone: ZoneFeature) {
    if (
      featureZone &&
      featureZone.planInfo &&
      featureZone.planInfo.planversionId
    ) {
      let timeLineIdx = this.planService.currentTimeLineIndex;
      let urlPath = "";
      if (
        featureZone.planInfo.styleType == "consultationend" ||
        featureZone.planInfo.historic
      ) {
        urlPath =
          this.route.parent.routeConfig.path +
          "/consultation/" +
          featureZone.planInfo.planversionId +
          "/hist" +
          "?timeLineIdx=" +
          timeLineIdx;
      } else {
        urlPath =
          this.route.parent.routeConfig.path +
          "/consultation/" +
          featureZone.planInfo.planversionId +
          "?timeLineIdx=" +
          timeLineIdx;
      }

      this.routingService.changeRoutePath(urlPath);
    }
  }
  //slider
  public prevItem() {
    this.prevIndx = this.prevIndx + 1;
    this.nextIndx = this.nextIndx + 1;
  }

  public nextItem() {
    this.prevIndx = this.prevIndx - 1;
    this.nextIndx = this.nextIndx - 1;
  }

  changePage(featureZone: ZoneFeature) {
    if (
      featureZone &&
      featureZone.planInfo &&
      featureZone.planInfo.planversionId
    ) {
      let subpath =
        "consultation/answer/" +
        featureZone.planInfo.planversionId +
        "/create" +
        "/" +
        featureZone.refId;
      if (this.route.routeConfig.path.indexOf("hist") > -1) {
        subpath += "/hist";
      }
      let path = this.route.parent.routeConfig.path + "/" + subpath;
      this.router.navigate([path]);
    }
  }
}
