import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  TemplateRef
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { ConsultationResponse } from "../models/consultationresponse";
import { ConsultationdataService } from "../services/consultationdata.service";
import { Consultation } from "../models/consultation";
import { PlandataService } from "../services/plandata.service";
import { RoutingService } from "../services/routing.service";
import { MapService } from "../services/map.service";
import { ZoneFeature } from "../plandataDefinition/zoneFeature";
import { ZoneInHoering } from "../models/zoneinhoering";
import { ContentService } from "../services/content.service";
import { EnvironmentService } from "../services/environment.service";
import { PrintService } from "../services/print.service";
import { DateService } from "../services/date.service";
import { LanguageService } from "../services/language.service";
import * as _ from 'lodash';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { Location } from '@angular/common'

import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import {DomSanitizer} from "@angular/platform-browser";
//import { ConsoleReporter } from "jasmine";


@Component({
  selector: "app-hearing",
  templateUrl: "./hearing.component.html",
  styleUrls: ["./hearing.component.scss"],
  queries: {
    tabsContentRef: new ViewChild("tabsContentRef", { static: false }),
  },
})
export class HearingComponent implements OnInit, OnDestroy {
  showAllHearings = false;
  showHearing = false;
  showHearingAnswers = false;
  hearingAnswer = false;
  zonesInHearings = new Array<ZoneInHoering>();
  isSubmitted = false;
  rdtype = "";
  zoneLayers:any = [];
  isHavplan:boolean = false;
  isTillag:boolean  = false;

  typeOptions = new Array<{id:string,value:string}>();
  selectedTypeValue:string = 'Privatperson';

  consultationResponse: ConsultationResponse;

  allConsultations: Consultation[];

  allConsultationResponse: ConsultationResponse[];
  allConsultationResponseMap: Map<String, ConsultationResponse>;
  allConsultationResponsesCurrentPage: number = 0;
  allConsultationResponsesCurrentPageLoaded: boolean = false;
  allConsultationResponsesPageSize: number = 10;
  allConsultationResponsesTotalPages: number;
  allConsultationResponsesTotalElements: number;
  allConsultationResponsesIsLastPage: boolean;

  showHearingAnswerSingle: boolean;
  max: boolean = true;
  hearingConfirmation = false;
  file: any;
  files : any[] = [];
  public tabsContentRef!: ElementRef;
  selectedConsultation: Consultation;
  selectedConsultationResponse: ConsultationResponse;
  showOnlyCurrentConsultations: boolean = false;
  acceptCondition: boolean = false;
  content: any;
  hearingContent: void;
  consultationContent: any;
  consultationResponseContent: any;
  contentString: void;
  consultationPrefix = "/plan/consultation";
  consultationResponsePrefix = "/plan/consultation/response";
  selectZoneActive: boolean = false;
  selectVersionZoneActive: boolean = false;
  selectPlanAsAnswerNotZone: boolean = false;
  selectedZone: any =[];
  selectedZoneLayer:any = [];
  consultationNoteUrl: string = "";
  hearingAnswerAttachmentUrl: string = "";
  showPrintOverlay: boolean = false;
  hoveredFeatureZones: ZoneFeature[] = new Array();
  selectedBoxFeatureRefId: string = "";

  searchTerm: string;
  confirmation: any;
  private subscriptions: Subscription[] = new Array();

  showSelectOnMapAlert: boolean = false;
  showDrawGuide: boolean = false;
  currentLang: string = null;

  isAttachmentInvalid: boolean = false;
  isAttachmentTooBig: boolean = false;
  isAttachmentFilenameTooBig: boolean = false;
  isAttachmentUnsafe: boolean = false;
  isOtherErrorWhenSubmitting: boolean = false;
  getHtmlTexts:string;
  isDropdownChanged:boolean = false;

  modalRef: BsModalRef;
  isConfirmed:boolean = false;
  confirmationModalData:any = null;

  public Editor = InlineEditor;
  ckeditorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic', 'Strike', 'link', 'numberedList', 'bulletedList','|', 'undo', 'redo'],
    heading:{
      options:[
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading2', view: 'h3', title: 'Heading 1', class: 'ck-heading_heading3' },
        { model: 'heading3', view: 'h4', title: 'Heading 2', class: 'ck-heading_heading4' }
      ]
    },
    link: {
      addTargetToExternalLinks: true
    },
    mediaEmbed: {
      previewsInData: true
    }
  };

  constructor(
    private consultationdataService: ConsultationdataService,
    private languageService: LanguageService,
    private plandataService: PlandataService,
    private mapService: MapService,
    private routeService: RoutingService,
    private router: Router,
    private contentService: ContentService,
    private environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private printService: PrintService,
    private _modalService: BsModalService,
    private dateService: DateService,
    public sanitizer: DomSanitizer,
    private location: Location
  ) {
    this.currentLang = this.languageService.prepareLanguage();

    this.typeOptions.push({'id': 'Privatperson' ,'value' : 'Privatperson'});
    this.typeOptions.push({'id': 'Virksomhed' ,'value' : 'Virksomhed'});
    this.typeOptions.push({'id': 'Kommunal myndighed' ,'value' : 'Kommunal myndighed'});
    this.typeOptions.push({'id': 'Statslig myndighed' ,'value' : 'Statslig myndighed'});
    this.typeOptions.push({'id': 'Organisation' ,'value' : 'Organisation'});
    this.typeOptions.push({'id': 'Forening' ,'value' : 'Forening'});
    this.typeOptions.push({'id': 'Region' ,'value' : 'Region'});

  }

  ngOnDestroy() {
    this.plandataService.setRegularPlanVersionTimeLine();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private initSetup() {
    // console.log("INIT::"+this.showOnlyCurrentConsultations);
    this.mapService.selectEnabled = true;
    this.mapService.deactivateDrawingTool(true);
    this.getHtmlTexts = this.currentLang+this.consultationResponsePrefix;

    this.initLayerSwithcer();

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        // Find type
        let planversionID: string = null;
        let answerID: string = null;
        let timeLineData = this.plandataService.getTimeline();
        if (this.route.routeConfig.path.indexOf("print") > -1) {
          this.showPrintOverlay = true;
          this.showAllHearings = false;
                    this.printService.printMaps(timeLineData);
          return;
        } else {
          this.showAllHearings = true;
        }
        let me = this;
        this.subscriptions.push(
          this.mapService.onFeatureHover().subscribe((zoneFeatures) => {
            me.hoveredFeatureZones = zoneFeatures;
          })
        );

        if (this.route.routeConfig.path.indexOf("hist") > -1) {
          this.showOnlyCurrentConsultations = false;
        } else{
          this.showOnlyCurrentConsultations = true;
        }

        this.allConsultations = this.plandataService.getConsultations(
          !this.showOnlyCurrentConsultations
        );

        if (params["planversionid"]) {
          planversionID = params["planversionid"];
          this.selectedConsultation = this.allConsultations.find(
            (c) => c.planversionId === planversionID
          );
          this.setZonesInHearing();
          this.checkPlanType();
        }
        if (params["answerid"]) {
          answerID = params["answerid"];
        }
        if (params["pageNum"]) {
          this.allConsultationResponsesCurrentPage = params["pageNum"];
        }

        if (this.route.routeConfig.path.indexOf("answer") > -1) {
          if (this.route.routeConfig.path.indexOf("show") > -1) {
            /* this.setConfirmation(); */
            this.showConfirmation();
            return;
          }

          if (this.route.routeConfig.path.indexOf("create") > -1) {
            this.selectedConsultation = this.allConsultations.find(
              (c) => c.planversionId === planversionID
            );

            if (this.selectedConsultation) {
              if (this.selectedConsultation.historic) {
                this.changePage("consultation");
              }
              this.mapService.clearSelectedFeatures("All");
              this.mapService.selectEnabled = false;
              let param_zoneId = "";
              if (params["zoneid"]) {
                param_zoneId = params["zoneid"];
              }
              this.makeHearingAnswer(param_zoneId);
            }
            this.checkPlanType();
            return;
          }
          if (answerID != null) {
            this.selectedConsultation = this.allConsultations.find(
              (c) => c.planversionId === planversionID
            );
            if (params["searchTerm"]) {
              this.getConsultationResponseDetailInSearch(answerID, params["searchTerm"]);
            } else {
              this.getConsultationResponseDetail(answerID);
            }
            this.checkPlanType();
            return;
          }

          if (planversionID != null) {
            this.selectedConsultation = this.allConsultations.find(
              (c) => c.planversionId === planversionID
            );
            if (this.selectedConsultation) {
              this.displayHearingAnswers();
            }
            this.checkPlanType();
          }
        } else {
          if (planversionID != null) {
            this.displayHearing(planversionID);
          } else {
            this.displayAllHearings();
          }
        }
      })
    );
  }

  initLayerSwithcer() {
    // this.subscriptions.push(
    //   this.plandataService.onLayerConfigEventChanged().subscribe((any) => {
    //     console.log("===><==")
    //     this.setLayers(any);
    //   })
    // );
    // this.setLayers(this.plandataService.currentPlandata);
    let layerConfig = this.plandataService.currentLayerConfig;
    if(layerConfig){
      this.setLayers(layerConfig);
    }else{
      setTimeout(()=>{
        this.initLayerSwithcer();
      },1000)
    }
  }

  setLayers(layerConfig: any){
    let showLegendConsultation = this.plandataService.showLegendForConsultation();
    let showLegendPostConsultation = this.plandataService.showLegendForPostConsultation();
   // let currentTimeLineIndex = this.plandataService.currentTimeLineIndex;
   // let currentTimelineData = this.plandataService.getTimelineByIndex();
    this.checkPlanType();
    this.zoneLayers = [];
    if (layerConfig && layerConfig.layers) {
      let removeLayerList = ["At","H","He"]
      for (let i = 0; i < layerConfig.layers.length; i++) {
        if (
          (!showLegendConsultation && layerConfig.layers[i].name == "consultation") ||
          (!showLegendPostConsultation && layerConfig.layers[i].name == "consultationEnd")
        ) {

          continue;
        }
        if(layerConfig.layers[i]){
          if(layerConfig.layers[i].subtypes && layerConfig.layers[i].subtypes.length > 0){
            let list = [];
            for(let obj of layerConfig.layers[i].subtypes){
              if(obj.type && (removeLayerList.indexOf(obj.type) == -1)){
                list.push(obj);
              }
            }
            if(list.length > 0){
              this.zoneLayers = this.zoneLayers.concat(list);
            }
          }else{
            this.zoneLayers.push(layerConfig.layers[i]);
          }
        }
      }
    }
  }

  ngOnInit() {
    const me = this;
    if (!this.mapService.isMapLoaded()) {
      this.subscriptions.push(
        this.mapService.onMapReady().subscribe((status) => {
          me.initSetup();
        })
      );
    } else {
      me.initSetup();
    }
  }

/*   setConfirmation() {
    this.confirmation = this.getCMSElement(
      this.currentLang + this.consultationResponsePrefix,
      "responseconfirm_text"
    );
  } */

  changePage(subpath: string, ignoreHistoricParam: boolean = false) {
    if (!this.isTermEmpty() && this.showHearingAnswers) {
      subpath += "/search/"+this.searchTerm;
    }
    if (!ignoreHistoricParam && !this.showOnlyCurrentConsultations) {
      subpath += "/hist";
    }
    let path = this.route.parent.routeConfig.path + "/" + subpath;
    this.router.navigate([path]);
  }

  getLink(subpath: string){
    if (!this.showOnlyCurrentConsultations) {
      subpath += "/hist";
    }
    let path = this.route.parent.routeConfig.path + "/" + subpath;
    return path;
  }

  getText(prefix: string, shortName: string) {
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  getZoneByIndex(index){
    if(this.zonesInHearings && this.zonesInHearings[index]){
      return this.zonesInHearings[index]['refId']+" - "+this.getTitlePrefixZoneId(this.zonesInHearings[index])
    }else{
      return "";
    }
  }

  getZoneLayerByIndex(index){
    if(this.zoneLayers && this.zoneLayers[index]){
      return this.zoneLayers[index]['layerSwitcherDisplayName'][this.currentLang];
    }else{
      return "";
    }
  }

  public getTitlePrefixZoneId(zone: any): string {
    return this.plandataService.getTitlePrefixByZoneId(zone.refId, zone.type[0], zone.planInfo.planversionId, zone.planInfo.status, this.currentLang);
  }

  public getTitlePrefixZoneIdForName(zone: any): string {
    return this.plandataService.getTitlePrefixByZoneId(zone.refId, zone.zoneType[0], zone.planInfo.planversionId, zone.planInfo.status, this.currentLang);
  }

  getHTML(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(prefix, shortName);
  }

  getCMSElement(prefix: string, shortName: string): object {
    return this.contentService.getCmsElementByShortName(prefix, shortName);
  }

  setDialogConfirmation(form: NgForm){
    this.isConfirmed = true;
    this.confirmationModalData = _.cloneDeep(form.value)
    this.submitForm(form, null);
  }

  submitForm(form: NgForm,template: TemplateRef<any>) {

    if (this.selectZoneActive) {

      let geometry = [];
      let refId = [];
      let zoneType = [];
      for(let index of this.selectedZone){
        // geometry.push(this.zonesInHearings[index].geometry); // Not needed we need to sent it as blank so
        refId.push(this.zonesInHearings[index].refId);
        zoneType.push(JSON.stringify(this.zonesInHearings[index].type));
      }
      this.setGeometry(geometry);
      this.consultationResponse.refId = refId.join(',');
      this.consultationResponse.zoneType = ""; //zoneType.join(',');
    }

    if(this.selectVersionZoneActive){
      let zoneType = [];
      for(let index of this.selectedZoneLayer){
        zoneType.push(this.zoneLayers[index].type);
      }
      this.setGeometry([]);
      this.consultationResponse.refId = "";
      this.consultationResponse.zoneType = zoneType.join(',');
    }


    this.isSubmitted = true;
    if (
      !form.valid ||
      (!this.selectPlanAsAnswerNotZone && !this.consultationResponse.geometry)
    ) {
      return false;
    }

    if(this.isConfirmed){
      if(!this.confirmationModalData){
        this.isConfirmed = false;
      }else{
        for(let key of Object.keys(form.value)){
          if(!_.isEqual(this.confirmationModalData[key],form.value[key])){
            this.isConfirmed = false;
            break;
          }
        }
      }
    }

    if(!this.isConfirmed){
      this.modalRef = this._modalService.show(template, {
        class: "modal-lg modal-xlc",
        backdrop: true,
        ignoreBackdropClick: true
      });
      return;
    }

    const filesToSend = [];
    if (this.files) {
        this.files.forEach(file => {
        if (!file.name || !file.name.endsWith(".pdf")) {
          this.isAttachmentInvalid = true;
          this.isAttachmentTooBig = false;
          this.isAttachmentFilenameTooBig = false;
          this.isAttachmentUnsafe = false;
          this.isOtherErrorWhenSubmitting = false;
          return false;
        }
        if(file.name.length > 32){
          this.isAttachmentInvalid = false;
          this.isAttachmentTooBig = false;
          this.isAttachmentFilenameTooBig = true;
          this.isAttachmentUnsafe = false;
          this.isOtherErrorWhenSubmitting = false;
          return false;
        }
        //Default size of file is bytes so we calculate it to KB and then MB(5MB only allow to upload)
        if(file.size/1024/1024 > 5){
          this.isAttachmentInvalid = false;
          this.isAttachmentTooBig = true;
          this.isAttachmentFilenameTooBig = false;
          this.isAttachmentUnsafe = false;
          this.isOtherErrorWhenSubmitting = false;
          return false
        }
        filesToSend.push(file);
      })
    }

    console.log(`@@@ SENDING ${filesToSend.length} files`)

    let that = this;

    that.isAttachmentInvalid = false;
    that.isAttachmentTooBig = false;
    that.isAttachmentFilenameTooBig = false;
    that.isAttachmentUnsafe = false;
    that.isOtherErrorWhenSubmitting = false;

    this.subscriptions.push(
      this.consultationdataService
        .addConsultationResponce(this.consultationResponse, filesToSend)
        .subscribe(
          (data) => {
            this.mapService.deactivateDrawingTool();
            if (data.httpStatus === 200 && data.status === 'SUCCESS') {
              this.sendHearingAnswer();
              return;
            }

            if (data.status === 'FAILURE' && data.content.localizedMessage === 'report.validation.illegal.attachmenttype') {
              that.isAttachmentInvalid = true;
            }

            if (data.status === 'FAILURE' && data.content.localizedMessage === 'report.validation.illegal.attachmentsize') {
              that.isAttachmentTooBig = true;
            }

            if (data.status === 'FAILURE' && data.content.localizedMessage === 'report.validation.illegal.attachmentunsafe') {
              that.isAttachmentUnsafe = true;
            }

          },
          (err) => {
            if (err.status === 413) {
                that.isAttachmentTooBig = true;
                return;
            }
            if (err.status === 400) {
              that.isOtherErrorWhenSubmitting = true;
              return;
            }
            console.log("HTTP Error", err);
          }
        )
    );
  }

  // report file addition to editor
  onFileUpload(event: any) {
    this.confirmationModalData = null;
    const elem = event.target;
    this.isAttachmentInvalid = false;
    this.isAttachmentTooBig = false;
    this.isAttachmentFilenameTooBig = false;
    this.isAttachmentUnsafe = false;
    this.isOtherErrorWhenSubmitting = false;
    // only allow one upload pr attachment

    if (elem.files.length > 10) {
      this.isOtherErrorWhenSubmitting = true;
      return;
    }

    this.files = [];

    for (let file of elem.files) {

      if(file.name.length > 32){
        this.isAttachmentInvalid = false;
        this.isAttachmentTooBig = false;
        this.isAttachmentFilenameTooBig = true;
        this.isAttachmentUnsafe = false;
        this.isOtherErrorWhenSubmitting = false;
        continue;
      }
      //Default size of file is bytes so we calculate it to KB and then MB
      if(file.size/1024/1024 > 5){
        this.isAttachmentInvalid = false;
        this.isAttachmentTooBig = true;
        this.isAttachmentFilenameTooBig = false;
        this.isAttachmentUnsafe = false;
        this.isOtherErrorWhenSubmitting = false;
        continue;
      }

      this.files.push(file);
    };

  }

  seeAllZones() {
    this.max = false;
  }

  hideAllZones() {
    this.max = true;
  }

  displayAllHearings() {
    this.showAllHearings = true;
    this.showHearing = false;
    this.showHearingAnswers = false;
    this.hearingAnswer = false;
    this.hearingConfirmation = false;
    this.showHearingAnswers = false;
    this.showHearingAnswerSingle = false;
    this.scrollTabContentToTop();
  }

  private setZonesInHearing() {
    this.mapService.clearSelectedFeatures("SELECT");
    const zones: ZoneFeature[] = this.mapService.getZoneFeaturesForConsultation(
      this.selectedConsultation.planversionId,
      true
    );
    this.zonesInHearings = new Array<ZoneInHoering>();
    zones
      .filter((zone) => zone.layerName !== "changed")
      .map((zone) => {
      let zoneInHearing = new ZoneInHoering();
      zoneInHearing.planversionId = zone.planInfo.planversionId;
      zoneInHearing.title = this.plandataService.getNameForZoneType(zone);
      zoneInHearing.layerName = zone.layerName;
      zoneInHearing.refId = zone.refId;
      zoneInHearing.type = zone.zoneType;
      zoneInHearing.planInfo = zone.planInfo;
      zoneInHearing.geometry = zone.geometry;
      this.zonesInHearings.push(zoneInHearing);
    });
  }

  createNewHearingAnswer(planversionId: string, zoneId: string) {
    this.changePage(
      "consultation/answer/" + this.selectedConsultation.planversionId + "/create/"+zoneId
    );
  }

  displayHearing(planversionId: string) {
    this.selectedConsultation = this.allConsultations.find(
      (c) => c.planversionId === planversionId
    );
    if (this.selectedConsultation.consultationNoteUUID) {
      this.consultationNoteUrl =
        this.environmentService.getEnvironment().serviceurl_portalcache +
        "file?fileId=" +
        this.selectedConsultation.consultationNoteUUID;
    } else {
      this.consultationNoteUrl = "";
    }
    this.setZonesInHearing();
    this.checkPlanType();
    this.showAllHearings = false;
    this.showHearing = true;
    this.hearingConfirmation = false;
    this.showHearingAnswers = false;
    this.hearingAnswer = false;
    this.scrollTabContentToTop();
  }

  private getConsultationResponseDetail(answerID: string) {
    this.subscriptions.push(
      this.consultationdataService
        .getConsultationResponses(
          this.selectedConsultation.planversionId,
          this.allConsultationResponsesCurrentPage - 1,
          this.allConsultationResponsesPageSize
        )
        .subscribe(
          (data) => {
            this.allConsultationResponse = data.data;
            this.allConsultationResponseMap = data.data.reduce(
              (map, obj) => (map.set(obj.consultationresponseid, obj), map),
              new Map<String, ConsultationResponse>()
            );

            this.allConsultationResponsesTotalPages = data.totalPages;
            this.allConsultationResponsesTotalElements = data.totalElements;
            this.allConsultationResponsesIsLastPage = data.last;
            this.allConsultationResponsesCurrentPageLoaded = true;
            this.displayHearingAnswerSingle(answerID);
          },
          (err) => {
            console.log("HTTP Error", err);
          }
        )
    );
  }

  private getConsultationResponseDetailInSearch(answerID: string, searchTerm: string) {
    this.searchTerm = searchTerm;
    this.searchHearingAnswers(false, answerID);
  }

  private getConsultationResponsesByPlanversion() {
    this.subscriptions.push(
      this.consultationdataService
        .getConsultationResponses(
          this.selectedConsultation.planversionId,
          this.allConsultationResponsesCurrentPage - 1,
          this.allConsultationResponsesPageSize
        )
        .subscribe(
          (data) => {
            this.allConsultationResponse = data.data;
            this.allConsultationResponseMap = data.data.reduce(
              (map, obj) => (map.set(obj.consultationresponseid, obj), map),
              new Map<String, ConsultationResponse>()
            );

            this.allConsultationResponsesTotalPages = data.totalPages;
            this.allConsultationResponsesTotalElements = data.totalElements;
            this.allConsultationResponsesIsLastPage = data.last;
            this.allConsultationResponsesCurrentPageLoaded = true;
          },
          (err) => {
            console.log("HTTP Error", err);
          }
        )
    );
  }

  getAltText(prefix, shortName) {
    return this.contentService.getAltTextByShortName(this.currentLang + prefix, shortName);
  }

  displayHearingAnswers() {
    this.showAllHearings = false;
    this.showHearing = false;
    this.showHearingAnswers = true;
    this.hearingAnswer = false;
    this.allConsultationResponsesCurrentPageLoaded = false;
    this.getConsultationResponsesByPlanversion();
    this.scrollTabContentToTop();
  }

  displayHearingAnswerSingle(consultationresponseId: string) {
    this.showAllHearings = false;
    this.showHearing = false;
    this.showHearingAnswers = false;
    this.hearingAnswer = false;
    this.showHearingAnswerSingle = true;
    this.selectedConsultationResponse = this.allConsultationResponseMap.get(
      consultationresponseId
    );

    let timeLineData = this.plandataService.getTimeline();
    this.plandataService.setConsultationTimeLine(this.selectedConsultationResponse, timeLineData);
    if (
      this.selectedConsultationResponse.geometry &&
      JSON.stringify(this.selectedConsultationResponse.geometry) != "{}"
    ) {
      this.mapService.showHearingAnswerGeometry(
        this.selectedConsultationResponse.geometry
      );
    }

    if (
      this.selectedConsultationResponse.consultationresponse_attachment &&
      this.selectedConsultationResponse.consultationresponse_attachment.length >
        0
    ) {
      this.hearingAnswerAttachmentUrl =
        this.environmentService.getEnvironment().serviceurl_portalcache +
        "consultation/response/file/?fileId=" +
        this.selectedConsultationResponse.consultationresponse_attachment[0].id;
    } else {
      this.hearingAnswerAttachmentUrl = "";
    }
    this.scrollTabContentToTop();
  }

  makeHearingAnswer(zoneId: string) {
    //console.log("ZONEID: "+zoneId);
    this.showAllHearings = false;
    this.showHearing = false;
    this.hearingConfirmation = false;
    this.showHearingAnswers = false;
    this.hearingAnswer = true;
    this.acceptCondition = false;
    if(zoneId) {
      for(let i = 0; i < this.zonesInHearings.length; i ++) {
        if(zoneId == this.zonesInHearings[i].refId) {
          this.rdtype = "selectZone";
          this.selectedZone = [i];
          this.selectedZoneLayer = [];
          this.selectZoneActive = true;
          this.selectPlanAsAnswerNotZone = false;
          this.selectVersionZoneActive = false;
          this.showDrawGuide = false;
          this.mapService.selectEnabled = true;
          this.mapService.deactivateDrawingTool();
          this.setCustomFeatureClickCallback();
          this.setZonesInHearing();
          this.mapService.showSelectedHearingGeometry(
            this.zonesInHearings[this.selectedZone[0]].geometry
          );
        }
      }
    }
    this.consultationResponse = {
      type: "Privatperson",
      isPrivate: "false",
      address: "",
      name: "",
      city: "",
      consultationresponseid: "1",
      email: "",
      geometry: null,
      consultation_description: "",
      othercontact: "",
      planversionID: this.selectedConsultation
        ? this.selectedConsultation.planversionId
        : "",
      postalcode: "",
      category: "tillaeg",
      valid_from: null,
      currentDateTime: null,
      refId: zoneId,
      zoneType: "",
      consultationresponse_attachment: null,
      content: null,
      httpStatus: null,
      status: null,
      planVersionZoneName: null
    };
    this.scrollTabContentToTop();
  }

  sendHearingAnswer() {
    this.changePage(
      "consultation/answer/" + this.selectedConsultation.planversionId + "/show", true
    );
  }

  showConfirmation() {
    this.showAllHearings = false;
    this.showHearing = false;
    this.showHearingAnswers = false;
    this.hearingAnswer = false;
    this.hearingConfirmation = true;
    this.scrollTabContentToTop();
  }

  private scrollTabContentToTop(): void {
    if (this.tabsContentRef) {
      this.tabsContentRef.nativeElement.scrollTop = 0;
    }
  }

  onHistoryConsultationsChange(): void {
    this.showOnlyCurrentConsultations = !this.showOnlyCurrentConsultations;
    this.changePage("consultation");
  }

  showDate(date: string): string {
    return this.dateService.formatDateOnly(date);
  }

  setGeometry(geojson: any): void {
    this.consultationResponse.geometry = geojson;
  }

  draw() {
    this.showDrawGuide = true;
    this.selectZoneActive = false;
    this.selectVersionZoneActive = false;
    this.mapService.setCustomFeatureClickCallback(null, null);
    this.mapService.selectEnabled = false;
    this.mapService.clearSelectedFeatures("CONSULTATIONRESPONSEZONE");
    this.mapService.activeDrawingTool(["POINT", "POLYLINE", "POLYGON"]);
    this.setGeometry(null);
    const me = this;
    this.mapService.setGeometryDrawingCallback(function (geojson) {
      me.setGeometry(JSON.parse(geojson));
    });
  }

  selectAnswerPlan() {
    this.mapService.selectEnabled = true;
    this.mapService.setCustomFeatureClickCallback(null, null);
    this.mapService.clearSelectedFeatures("CONSULTATIONRESPONSEZONE");
    this.mapService.deactivateDrawingTool();
    this.selectZoneActive = false;
    this.selectVersionZoneActive = false;
    this.selectPlanAsAnswerNotZone = true;
    this.showDrawGuide = false;
    this.setGeometry([]);
  }

  selectAnswerZone() {
    this.mapService.clearSelectedFeatures("All");
    this.mapService.selectEnabled = true;
    this.selectZoneActive = !this.selectZoneActive;
    this.selectVersionZoneActive = false;
    this.selectPlanAsAnswerNotZone = false;
    this.showDrawGuide = false;
    this.mapService.deactivateDrawingTool();
    this.setGeometry(null);

    this.consultationResponse.refId = null;
    this.consultationResponse.planVersionZoneName = null;
    this.setCustomFeatureClickCallback();

    this.selectedZone = [];
    this.setZonesInHearing();

    // this.mapService.showSelectedHearingGeometry(
    //   this.zonesInHearings[this.selectedZone].geometry
    // );
  }

  setCustomFeatureClickCallback(){
    let me = this;
    this.mapService.setCustomFeatureClickCallback(function (
      selectedZoneFeatures: ZoneFeature[]
    ) {
      if (selectedZoneFeatures == null || selectedZoneFeatures.length == 0) {
        return;
      }

      let zoneFeatures: ZoneFeature[] = selectedZoneFeatures.filter(
        (f) =>
          f.planInfo.planversionId === me.selectedConsultation.planversionId &&
          f.planInfo.status === "consultation" &&
          f.layerName != "changed"
      );

      // if (zoneFeatures.length === 0) {
      //   me.showSelectOnMapAlert = true;
      //   me.setGeometry(null);
      //   setTimeout(() => {
      //     me.showSelectOnMapAlert = false;
      //   }, 3000);
      //   return;
      // }

      if (zoneFeatures.length > 1) {
        zoneFeatures = zoneFeatures.slice(0, 1);
      }

      if (zoneFeatures.length == 1) {
        // me.setGeometry(zoneFeatures[0].geometry);
        // me.consultationResponse.refId = zoneFeatures[0].refId;
        // me.consultationResponse.zoneType = JSON.stringify(
        //   zoneFeatures[0].zoneType
        // );
        // me.consultationResponse.planVersionZoneName = me.getTitlePrefixZoneIdForName(zoneFeatures[0])

        let index = -1;
        for(let i=0; i < me.zonesInHearings.length; i++){
          if(
             zoneFeatures[0].layerName != "changed" &&
             zoneFeatures[0].refId == me.zonesInHearings[i].refId &&
             zoneFeatures[0].layerName == me.zonesInHearings[i].layerName &&
             _.isEqual(zoneFeatures[0].geometry, me.zonesInHearings[i].geometry)){
            index = i;
            break;
          }
        }

        if(index != -1){
          me.isDropdownChanged = true;

          if(me.selectedZone.indexOf(index) >= 0){

            let removeIndx = me.selectedZone.indexOf(index);
            me.selectedZone.splice(removeIndx,1)
            me.setGeometry(null);
            me.mapService.hearingAnswerDisplayLayer.getSource().clear();

            if(me.selectedZone.length > 0){
              me.setGeometry([]);
            }else{
              me.setGeometry(null);
            }
            for(let index of me.selectedZone){
              me.mapService.showSelectedHearingGeometry(
              me.zonesInHearings[index].geometry
              );
            }
            setTimeout(()=>{
              me.isDropdownChanged = false;
            })
            return;
          }else{
            me.selectedZone.push(index);
            me.mapService.showSelectedHearingGeometry(
              me.zonesInHearings[index].geometry
            );
            if(me.selectedZone.length > 0){
              me.setGeometry([]);
            }else{
              me.setGeometry(null);
            }
            setTimeout(()=>{
              me.isDropdownChanged = false;
            })
            return;
          }
        }
        /* console.log("===>"+me.getTitlePrefixZoneId(zoneFeatures[0])) */
        return;
      }

      // const modalRef = me.modalService.open(ZonesComponent);
      // modalRef.componentInstance.isAutonomousModeOn = true;
      // modalRef.componentInstance.autonomousSelectedFeatureCallback = (
      //   feature: ZoneFeature
      // ) => {
      //   me.setGeometry(feature.geometry);
      //   me.consultationResponse.refId = feature.refId;
      //   me.consultationResponse.zoneType = JSON.stringify(feature.zoneType);
      //   modalRef.dismiss();
      // };
    },
    this.selectedConsultation.planversionId);
  }

  selectAnswerZoneChange() {
    this.showDrawGuide = false;
    // this.mapService.setCustomFeatureClickCallback(null, null);

    this.mapService.hearingAnswerDisplayLayer.getSource().clear();
    if(this.selectedZone.length > 0){
      this.setGeometry([]);
    }else{
      this.setGeometry(null);
    }
    for(let index of this.selectedZone){
      this.mapService.showSelectedHearingGeometry(
      this.zonesInHearings[index].geometry
      );
    }
    // this.mapService.showSelectedHearingGeometry(
    //   this.zonesInHearings[this.selectedZone].geometry
    // );
  }

  selectVersionZone(){
      this.mapService.clearSelectedFeatures("All");
      this.mapService.selectEnabled = false;
      this.selectZoneActive = false;
      this.selectVersionZoneActive = !this.selectVersionZoneActive;
      this.selectPlanAsAnswerNotZone = false;
      this.showDrawGuide = false;
      this.mapService.deactivateDrawingTool();
      this.setGeometry(null);

      this.consultationResponse.refId = null;
      this.consultationResponse.planVersionZoneName = null;
      this.mapService.setCustomFeatureClickCallback(null,null);
      this.selectedZone = [];
      this.selectedZoneLayer = [];
      this.setZonesInHearing();
  }

  selectVersionZoneChange() {
    this.showDrawGuide = false;
    // this.mapService.setCustomFeatureClickCallback(null, null);
    if(this.selectedZoneLayer.length > 0){
      this.setGeometry([]);
    }else{
      this.setGeometry(null);
    }
    this.mapService.hearingAnswerDisplayLayer.getSource().clear();

  }

  selectTypeChange(event){
    if(event && event.target && event.target.value){
      if(event.target.value != this.selectedTypeValue){
        this.consultationResponse.isPrivate = "false";
      }
      for(let obj of this.typeOptions){
        if(obj.id == event.target.value){
          this.selectedTypeValue = obj.value;
        }
      }
    }
  }

  getConsultationStatusText(): string {
    if (this.selectedConsultation) {
      return this.getText(
        this.consultationPrefix,
        this.selectedConsultation.statusInfo.cmstext
      );
    }
    return "";
  }

  checkPlanType(){
    let planStatus = this.getConsultationStatusText();
    if(planStatus.indexOf('Havplan') >=0 || planStatus.indexOf('Ocean Plan') >=0){
      this.isHavplan = true;
    }else if(planStatus.indexOf('Tillæg') >=0 || planStatus.indexOf('Add-on') >=0){
      this.isTillag = true;
    }else{
      this.isHavplan = false;
      this.isTillag = false;
    }
  }

  onAcceptCondition(): void {
    this.confirmationModalData = null;
    this.acceptCondition = !this.acceptCondition;
  }

  searchHearingAnswers(isTermChanged: boolean = true, answerID: any = null) {
    if (isTermChanged) {
      this.allConsultationResponsesCurrentPage = 1;
    }

    if (this.isTermEmpty()) {
      this.displayHearingAnswers();
      return;
    }

    this.showAllHearings = false;
    this.showHearing = false;
    this.showHearingAnswers = true;
    this.hearingAnswer = false;

    this.allConsultationResponsesCurrentPageLoaded = false;
    this.subscriptions.push(
      this.consultationdataService
        .getConsultationResponsesBySearch(
          this.selectedConsultation.planversionId,
          this.searchTerm,
          this.allConsultationResponsesCurrentPage - 1,
          this.allConsultationResponsesPageSize
        )
        .subscribe(
          (data) => {
            this.allConsultationResponse = data.data;
            this.allConsultationResponseMap = data.data.reduce(
              (map, obj) => (map.set(obj.consultationresponseid, obj), map),
              new Map<String, ConsultationResponse>()
            );

            this.allConsultationResponsesTotalPages = data.totalPages;
            this.allConsultationResponsesTotalElements = data.totalElements;
            this.allConsultationResponsesIsLastPage = data.last;
            this.allConsultationResponsesCurrentPageLoaded = true;
            if (answerID)
              this.displayHearingAnswerSingle(answerID);
          },
          (err) => {
            console.log("HTTP Error", err);
          }
        )
    );

    this.scrollTabContentToTop();
  }

  isTermEmpty = () => {
    return !this.searchTerm;
  };

  clearSearch = () => {
    this.allConsultationResponsesCurrentPage = 1;
    this.searchTerm = "";
    this.displayHearingAnswers();
  };

  onConsultationResponsesPageChange = () => {
    this.allConsultationResponsesCurrentPageLoaded = false;

    if (this.isTermEmpty()) {
      this.displayHearingAnswers();
    } else {
      this.searchHearingAnswers(false);
    }
  };

  get printedReportsPercentage() {
    return this.printService.printedReportsPercentage;
  }
  get printFinished() {
    return this.printService.printFinished;
  }

  public displayZoneInhearing(zonefeature: ZoneInHoering) {
    this.mapService.resetFeatureStyle(
      zonefeature.layerName,
      zonefeature.refId,
      zonefeature.planInfo.styleType
    );
    let path =
      this.route.parent.routeConfig.path +
      "/zone/s/" +
      zonefeature.planversionId +
      "/" +
      zonefeature.layerName +
      "/" +
      zonefeature.refId
      +
      "?consultation=1"
    ;
    this.routeService.changeRoutePath(path);
  }

  public setZoneStyle(zonefeature: ZoneInHoering, highlight: boolean) {
    if (highlight) {
      this.selectedBoxFeatureRefId = zonefeature.refId;
      this.mapService.setFeatureStyle(
        zonefeature.layerName,
        zonefeature.refId,
        zonefeature.planInfo.styleType,
        "highlight"
      );
    } else {
      if (this.selectedBoxFeatureRefId == zonefeature.refId) {
        this.selectedBoxFeatureRefId = "";
      }
      this.mapService.resetFeatureStyle(
        zonefeature.layerName,
        zonefeature.refId,
        zonefeature.planInfo.styleType
      );
    }
  }

  featureInMapHovered(refId: string) {
    if (this.hoveredFeatureZones.find((f) => f.refId === refId)) {
      return true;
    } else if (this.selectedBoxFeatureRefId == refId) {
      return true;
    } else {
      return false;
    }
  }

  getSetData(event){
    this.consultationResponse.isPrivate = (this.consultationResponse.isPrivate == "true" ? "false" : "true" );
  }

  getZoneRefIds(){
    if(this.selectedConsultationResponse && this.selectedConsultationResponse.refId){
      let RefIdList = this.selectedConsultationResponse.refId.split(',');
      if(RefIdList && RefIdList.length >= 0){
        return RefIdList;
      }
    }
    return [];
  }

  getDrawingData(){
    if(this.selectedConsultationResponse && this.selectedConsultationResponse.geometry && this.selectedConsultationResponse.geometry['type'] ){
      return true;
    }
  }

  getZoneRefNameById(id){
    for(let zone of this.zonesInHearings){
      if(zone && zone.refId == id){
        return this.getTitlePrefixZoneId(zone);
      }
    }
  }

  getZoneRefNameByIndexList(){
    let refIdList = [];
    for(let index of this.selectedZone){
      refIdList.push(this.zonesInHearings[index].refId);
    }
    return refIdList.join(', ');
  }

  getZoneLayerIds(){
    if(this.selectedConsultationResponse && this.selectedConsultationResponse.zoneType){
      let zoneIdList = this.selectedConsultationResponse.zoneType.split(',');
      if(zoneIdList && zoneIdList.length >= 0){
        return zoneIdList;
      }
    }
    return [];
  }

  getZoneLayerNameById(id){
    for(let zone of this.zoneLayers){
      if(zone && zone.type == id){
        return zone['layerSwitcherDisplayName'][this.currentLang];
      }
    }
  }

  getZoneLayerNameByIndexList(){
    let layerName = [];
    for(let index of this.selectedZoneLayer){
      layerName.push(this.zoneLayers[index].type)
    }
    return layerName.join(', ');
  }

  onResultHover = (zoneId: any) => {
    for(let zone of this.zonesInHearings){
      if(zone && zone.refId == zoneId){
        this.mapService.showSelectedHearingGeometry(zone.geometry);
        return;
      }
    }
    this.mapService.clearSelectedFeatures("MAPSEARCH");
  };

  zoomOnZone(zoneId) {
   // console.log("ZoneId"+zoneId)
    for(let zone of this.zonesInHearings){
      if(zone && zone.refId == zoneId){

        let temp:any = zone;
        this.mapService.flyToFeaturesByZoneTypesArr([temp]);
      }
    }
  }

  goBack() {
    this.location.back();
  }

 /*  resetForm(form: NgForm){
    form.resetForm();
    this.consultationResponse = {
      type: "Privatperson",
      isPrivate: "false",
      address: "",
      name: "",
      city: "",
      consultationresponseid: "1",
      email: "",
      geometry: null,
      consultation_description: "",
      othercontact: "",
      planversionID: this.selectedConsultation
        ? this.selectedConsultation.planversionId
        : "",
      postalcode: "",
      category: "tillaeg",
      valid_from: null,
      refId: null,
      zoneType: "",
      consultationresponse_attachment: null,
      content: null,
      httpStatus: null,
      status: null,
      planVersionZoneName: null
    };
    this.selectZoneActive = false;
    this.selectVersionZoneActive = false;
    this.showDrawGuide = false;
    this.selectPlanAsAnswerNotZone= false;
    this.acceptCondition = false;
    this.isAttachmentInvalid = false;
    this.isAttachmentTooBig = false;
    this.isAttachmentFilenameTooBig = false;
    this.isAttachmentUnsafe = false;
    this.isOtherErrorWhenSubmitting = false;
    this.file = null;
    this.isSubmitted = false;
    this.selectedTypeValue = 'Privatperson';
    this.selectedZoneLayer = [];
    this.selectedZone = [];
    this.selectAnswerZoneChange()
  } */
}
