import {
  Component, EventEmitter,
  OnInit, Output,
} from "@angular/core";
import { ContentService } from "../services/content.service";
import { MapService } from "../services/map.service";
import { LanguageService } from "../services/language.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PlandataService } from "../services/plandata.service";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit {

  @Output() notifyPageChange: EventEmitter<String> = new EventEmitter();

  infoPrefix = "/plan/info";
  tabsPrefix = "/plan/tabs";
  contentString: any;
  infoContent: any;
  tabsContent: any;
  currentLang: string = null;
  planversionId:string;

  innerWidth: any;

  showGoToAnsweringButton: boolean = false;

  constructor(
    private contentService: ContentService,
    private languageService: LanguageService,
    private mapService: MapService,
    private router: Router,
    private route: ActivatedRoute,
    private planService: PlandataService

  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;

    let me = this;
    if (!this.mapService.isMapLoaded()) {
      this.mapService.onMapReady().subscribe((status) => {
        me.mapService.deactivateDrawingTool(true);
        me.mapService.selectEnabled = true;

        const allActiveConsultations = this.planService.getConsultations(false);
        this.showGoToAnsweringButton = allActiveConsultations && allActiveConsultations.length > 0;
        if (this.showGoToAnsweringButton) {
          this.planversionId = allActiveConsultations[0].planversionId;
        }
      });
    } else {
      me.mapService.deactivateDrawingTool(true);
      me.mapService.selectEnabled = true;
    }

    this.planService.currentSeaPlandata$.subscribe(value => {
      if(value && value.consultationVersions[0] && value.consultationVersions[0].planversionId){
        this.planversionId = value.consultationVersions[0].planversionId;
      }
    });

    this.route.params.subscribe((params) => {
      if (!this.mapService.isMapLoaded()) {
        return;
      }
      const allActiveConsultations = this.planService.getConsultations(false);
      this.showGoToAnsweringButton = allActiveConsultations && allActiveConsultations.length > 0;
      if (this.showGoToAnsweringButton) {
        this.planversionId = allActiveConsultations[0].planversionId;
      }
    });

  }

  getText(prefix, shortName) {
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  getHTML(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(prefix, shortName);
  }

  // changePage(subpath: string) {
  //   let path = this.route.parent.routeConfig.path + "/" + subpath;
  //   console.log("path 1::",this.route.parent.routeConfig.path);//da/page
  //
  //   console.log("path 2::",path);//da/page/consultation/answer/undefined
  //   this.router.navigate([path]);
  // }

  changePage(page: string) {
    let newPage = this.setActiveTab(page);
    let parentpath = this.route.parent.routeConfig.path; //this.route.routeConfig.path;
    if(newPage == "zone") {
      let zoneFeatures = this.mapService.getSelectedFeature();
      let coordinates = this.mapService.clickedCoordinates;
      let timeLineIdx = this.planService.currentTimeLineIndex;
      if(zoneFeatures.length == 1) {
        let zone = zoneFeatures[0];
        if(coordinates && coordinates.length > 1) {
          newPage = newPage+"/s/"+zone.planInfo.planversionId+"/"+coordinates[0] +"/"+coordinates[1]+"/"+zone.layerName+"/"+zone.refId+"?timeLineIdx="+timeLineIdx;
        }else {
          newPage = newPage+"/s/"+zone.planInfo.planversionId+"/"+zone.layerName+"/"+zone.refId+"?timeLineIdx="+timeLineIdx;
        }

      }else if (zoneFeatures.length > 1) {
        if(coordinates && coordinates.length > 1) {
          newPage = newPage+"/m/"+coordinates[0] +"/"+coordinates[1]+"?timeLineIdx="+timeLineIdx;
        }
      }
    }

    if(newPage == "consultation") {
      if(this.planService.isHistoryMap()) {
        newPage +="/hist";
      }

    }

    let path = parentpath+"/" + newPage;
    this.router.navigateByUrl(path);
  }

  setActiveTab(pageUrlParts: string) : string {
    let page: string = this.getPageName(pageUrlParts);
    switch (page) {
      case "hist":
        page += "/" + this.planService.currentTimeLineIndex;
        break;
    }

    if (this.innerWidth <= 767) {
      this.notifyPageChange.emit(page);
    }

    return page;
  }

  getPageName(pageUrlParts){
    let page: string = "";
    if(pageUrlParts.indexOf(",")){
      let parts = pageUrlParts.split(",");
      if(parts.length > 0) {
        page = parts[0];
      }
    }else {
      page = pageUrlParts;
    }
    return page;
  }
}
