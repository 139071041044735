export class PlanInfo {
    planversionId: string;
    planversionName: string;
    planversionName_en:string;
    planversionType: string;
    title: string;
    descriptionTexts: any;
    consultationStart: string;
    consultationEnd: string;
    effectiveDate: string;
    status: string;
    historic: boolean;
    styleType: string;
    name: string;
    type?: string;
}
