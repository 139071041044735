import {Injectable} from '@angular/core';

import * as moment from 'moment-timezone';

const DATE_FORMAT : string = "DD/MM-YYYY HH:mm";
const DATA_ONLY_FORMAT : string = "DD/MM-YYYY";
const DEFAULT_TZ : string = "europe/copenhagen";

@Injectable()
export class DateService {


  format(date: any) {
    // console.log("formatDate: " + date);
    return moment(date).tz(DEFAULT_TZ).format(DATE_FORMAT);
  }

  formatDateOnly(date: any) {
    // console.log("formatDateOnly: " + date);
    return moment(date).tz(DEFAULT_TZ).format(DATA_ONLY_FORMAT);
  }

  isDatetimesBefore(dateTimeBefore: Date, compareDateTime: Date) : boolean{
    console.log("formatDate: " + dateTimeBefore +" : "+compareDateTime);
    if(moment(dateTimeBefore).tz(DEFAULT_TZ) < moment(compareDateTime).tz(DEFAULT_TZ)) {
      return true;
    }
    return false;
  } 

  currentDateInUtc() {
    return moment().utc();
  }

  currentDate() {
    return moment().tz(DEFAULT_TZ);
  }


}
