import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {PlandataService} from "../services/plandata.service";
import {MapService} from "../services/map.service";
import {RoutingService} from "../services/routing.service";
import GeoJSON from "ol/format/GeoJSON";
import Feature from "ol/Feature";
import { transform } from 'ol/proj';
import {ZoneFeature} from "../plandataDefinition/zoneFeature";
import {ContentService} from "../services/content.service";
import {Subject, Subscription} from "rxjs";
import MultiPoint from "ol/geom/MultiPoint";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";
import {DateService} from "../services/date.service";
import Geometry from "ol/geom/Geometry";
import intersect from "@turf/intersect";
import lineintersect from "@turf/line-intersect";
import {LanguageService} from "../services/language.service";
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import GeometryType from "ol/geom/GeometryType";

const regExpGeoPoint: RegExp = /^(\d*(\,?\d*)?) (\d*(\,?\d*)?)$/;
const regExpLatLongPoint: RegExp = /^(\d*(\.?\d*)?) (\d*(\.?\d*)?)$/;
const regExpLatLongPointDotComma: RegExp = /^(\d*(\.?\d*)?) (\d*(\,?\d*)?)$/;
const regExpLatLongPointCommaDot: RegExp = /^(\d*(\,?\d*)?) (\d*(\.?\d*)?)$/;

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  private subscriptions: Subscription[] = new Array();



  searchPrefix = "/plan/search";
  // tabsPrefix = "da/plan/tabs";
  searchResultType: any = 0;
  searchMode: number = 0;
  isSearchResultVisible: boolean;
  isDrawToolVisible = false;
  term: string;
  results: any;
  content: any;
  searchDa: any;
  title: any;
  textSearch: any;
  drawText: any;
  drawButton: any;
  isHistoric = false;
  currentLang: string = null;
  searchTypeGeo:string = '1';

  searchInput : Subject<string> = new Subject();

  constructor(
    private planService: PlandataService,
    private languageService: LanguageService,
    private mapService: MapService,
    private route: ActivatedRoute,
    private routeService: RoutingService,
    private contentService: ContentService,
    public dateService: DateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.currentLang = this.languageService.prepareLanguage();
    this.isSearchResultVisible = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    this.results = [];
    this.isSearchResultVisible = false;
    let me = this;

    if (!this.mapService.isMapLoaded()) {
      this.mapService.onMapReady().subscribe((status) => {
        me.mapService.clearSelectedFeatures("ALL");
        me.mapService.selectEnabled = false;
        this.checkSearchTerm();
      });
    } else {
      me.mapService.clearSelectedFeatures("ALL");
      me.mapService.selectEnabled = false;
      this.checkSearchTerm();
    }

    this.isHistoric = this.planService.isHistoryMap();

    this.searchInput.pipe(debounceTime(300),distinctUntilChanged()).subscribe(value =>{
      this.search();
     });

    this.subscriptions.push(
      this.mapService.changeMarkingsSubject.subscribe((isEnabled) => {
        this.search();
      })
    );

  }

  searchInputValueChanged(event){
    this.searchInput.next(event);
  }

  checkSearchTerm() {
    if (this.route.snapshot.queryParamMap.get("coordinates")) {
      this.term = this.route.snapshot.queryParamMap.get("coordinates");
      this.searchMode = 1;
      this.searchWithGeoPoint();
    }
    if (this.route.snapshot.queryParamMap.get("text")) {
      this.term = this.route.snapshot.queryParamMap.get("text");
      this.searchMode = 0;
      this.searchWithTextTerm();
    }
    if(this.mapService.searchMode > 0){
      this.searchMode = this.mapService.searchMode;
    }
    if(this.mapService.searchText){
      this.term = this.mapService.searchText;
      this.search();
    }
  }

  getText(prefix: string, shortName: string) {
    return (this.content = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  onClick() {
    if (!this.isDrawToolVisible) {
      this.activateDrawOnMapToolbox();
      this.isDrawToolVisible = true;
    } else {
      this.deactivateDrawOnMapToolbox();
      this.isDrawToolVisible = false;
    }
  }

  search = () => {
    if (this.searchMode != 1) {
      this.mapService.clearSelectedFeatures("ALL");
    }

    if (!this.term || this.term.trim().length === 0) {
      this.clear();
      return;
    }

    this.mapService.searchMode = this.searchMode;
    this.mapService.searchText = this.term;
    if (this.searchMode == 1) {
       if(this.searchTypeGeo == '1'){
        this.searchWithGeoLatLonPoint();
      }else if(this.searchTypeGeo == '2'){
        this.searchWithGeoPoint();
      }
    } else {
      this.searchWithTextTerm();
    }

    this.loadSearchResultList();
  };

  onSearchTypeChange = () => {
    this.clear();

    if (this.searchMode == 2) {
      this.activateDrawOnMapToolbox();
      this.isDrawToolVisible = true;
    } else {
      this.deactivateDrawOnMapToolbox();
      this.isDrawToolVisible = false;
    }
  };

  getHTML(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(
      this.currentLang + prefix,
      shortName
    );
  }

  clear = () => {
    this.term = "";
    this.results = {};
    this.isSearchResultVisible = false;
    this.mapService.searchText = null;
    this.mapService.clearSelectedFeatures("ALL");
  };

  isTermEmpty = () => {
    return !this.term;
  };

  onResultHover = (zone: any) => {
    if (zone) {
      this.mapService.showSelectedHearingGeometry(zone.geometry);
    } else {
      this.mapService.clearSelectedFeatures("MAPSEARCH");
    }
  };

  goToZoneDetails = (zone: any) => {
    this.mapService.clearSelectedFeatures("ALL");
    this.deactivateDrawOnMapToolbox();
    let timeLineIdx = this.planService.currentTimeLineIndex;
    let path =
      this.route.parent.routeConfig.path +
      "/zone/s/" +
      zone.planInfo.planversionId +
      "/" +
      zone.layerName +
      "/" +
      zone.refId +
      "?timeLineIdx=" +
      timeLineIdx;
    this.routeService.changeRoutePath(path);
  };

  private searchWithTextTerm() {
    this.results = this.filterPlanVersion(this.term.toLowerCase());
    this.isSearchResultVisible = true;
    this.mapService.setTerm(this.searchMode + "-" + this.term);
  }

  private searchWithGeoPoint() {
    this.mapService.clearSelectedFeatures("ALL");

    if (!this.term) {
      this.results = [];
      this.isSearchResultVisible = false;
      return;
    }

    let coordinates = this.extractGeoPointFrom(this.term.trim());
    if (coordinates && coordinates.length == 2) {
      this.mapService.addIconMarker(coordinates);
      let geoTerm = {
        type: "MultiPoint",
        coordinates: [[coordinates[0], coordinates[1]]],
      };
      this.geoFilterPlanVersion(geoTerm);
      this.mapService.setTerm(this.searchMode + "-" + this.term);
      this.loadSearchResultList();
    } else {
      this.results = [];
      this.isSearchResultVisible = true;
    }
  }

  private searchWithGeoLatLonPoint(){
    this.mapService.clearSelectedFeatures("ALL");
    if (!this.term) {
      this.results = [];
      this.isSearchResultVisible = false;
      return;
    }

    let coordinates = this.extractLatLongPointFrom(this.term.trim());

    if(!coordinates || (coordinates && coordinates.length < 2)){
      return;
    }
    var lon_lat = transform(coordinates,'EPSG:4326', 'EPSG:25832');
    coordinates = lon_lat;
    if (coordinates && coordinates.length == 2) {
      this.mapService.addIconMarker(coordinates);
      let geoTerm = {
        type: "MultiPoint",
        coordinates: [[coordinates[0], coordinates[1]]],
      };
      this.geoFilterPlanVersion(geoTerm);
      this.mapService.setTerm(this.searchMode + "-" + this.term);
    } else {
      this.results = [];
      this.isSearchResultVisible = true;
    }
  }

  private filterPlanVersion = (term: string) => {

    // zones from approved
    const approvedPlanVersions = this.planService.currentPublishedLayers
      ///.filter((layer) => Object.keys(DefaultLayerTypeEnum).indexOf(layer.name) >= 0)
      .filter(this.nonSearchableLayer)
      .map((layer) => {
        return {
          planversionId: layer.planversionId,
          status: layer.status,
          layerName: layer.name,
          consultationStart: layer.consultationStart,
          consultationEnd: layer.consultationEnd,
        };
      });


    // zones from approved
    const consultationPlanVersions = this.planService.currentConsultationLayers
      //.filter((layer) => Object.keys(DefaultLayerTypeEnum).indexOf(layer.name) >= 0)
      .filter(this.nonSearchableLayer)
      .map((layer) => {
        return {
          planversionId: layer.planversionId,
          status: layer.status,
          layerName: layer.name,
          consultationStart: layer.consultationStart,
          consultationEnd: layer.consultationEnd,
        };
      });


    let results = {
      approved: this.filterZoneFromPlanversions(
        approvedPlanVersions,
        term.toLowerCase()
      ),
      consultations: this.filterZoneFromPlanversions(
        consultationPlanVersions,
        term.toLowerCase()
      ),
    };

    return results;
  };

  private filterZoneFromPlanversions = (planVersions: any[], term: string) => {
    if (!planVersions) {
      return [];
    }

    let results = [];
    planVersions.forEach((planVersion) => {
      const planVersionId = planVersion.planversionId;
      let zones = this.mapService.getZoneFeaturesAllForByLayer(
        planVersion.layerName,
        planVersionId
      );
      if (!zones) {
        return;
      }

      let a = 0;
      zones
        .filter(this.nonSearchableLayer)
        .forEach((z) => {
        a++;
        if (this.filterZone(z, planVersionId, term)) {
          results.push({
            ...z,
            title: this.getZoneTitle(z),
            consultationStart: planVersion.consultationStart,
            consultationEnd: planVersion.consultationEnd,
          });
        }
      });
    });

    return results;
  };

  private filterZone = (zone: any, planVersionId: string, term: string) => {

    console.log("zone to filter: " + JSON.stringify(zone, null, 3));

    let zonesFoundByLawTextFilter = this.filterByLawText(zone, planVersionId, term);
    let zonesFoundByZoneTypeFilter = this.filterByZoneType(zone, term);
    let zonesFoundByZoneNameFilter = this.filterByZoneName(zone, term);
    let zonesFoundByZoneRefIdFilter = this.filterByZoneRefId(zone, term)
    return (
      zonesFoundByLawTextFilter ||
      zonesFoundByZoneTypeFilter ||
      zonesFoundByZoneNameFilter ||
      zonesFoundByZoneRefIdFilter
    );
  };

  private filterByLawText = (
    zone: any,
    planVersionId: string,
    term: string
  ) => {
    const zoneLawText = this.planService.getLawstextByZoneId(
      zone.refId,
      planVersionId,
      zone.planInfo.status,
      this.currentLang
    );
    if (this.containsTerm(zoneLawText, term)) {
      return true;
    }

    if (!zone.zoneType || zone.zoneType.length == 0) {
      return false;
    }

    for (let i = 0; i < zone.zoneType.length; i++) {
      let zoneTypeLawText = this.planService.getLawstextByZoneType(
        zone.zoneType[i],
        planVersionId,
        zone.planInfo.status,
        this.currentLang
      );
      if (this.containsTerm(zoneTypeLawText, term)) {
        return true;
      }
    }

    return false;
  };

  private filterByZoneType = (zone: any, term: string) => {
    if (!zone.zoneType) {
      return false;
    }

    for (let i = 0; i < zone.zoneType.length; i++) {
      if (this.containsTerm(zone.zoneType[i], term)) {
        return true;
      }
    }

    return false;
  };

  private filterByZoneName = (zone: any, term: string) => {
    const name = this.getZoneTitle(zone);
    return this.containsTerm(name, term);
  };

  private filterByZoneRefId = (zone: any, term: string) => {
    return this.containsTerm(zone.refId, term);
  };

  private containsTerm = (text0: any, term: string) => {
    let text = text0 instanceof String ? text0 : text0 + "";
    return text && text.toLowerCase().indexOf(term) > -1;
  };

  getZoneTitle(zone: any) {
    return [this.planService.getNameForZoneType(zone)];
  }

  private activateDrawOnMapToolbox() {
    this.mapService.clearSelectedFeatures("ALL");
    this.mapService.activeDrawingTool(["POINT", "POLYLINE", "POLYGON"]);
    const me = this;
    this.mapService.setGeometryDrawingCallback(function (drawnGeoJson) {
      me.geoFilterPlanVersion(drawnGeoJson);
      me.loadSearchResultList();
    });
  }

  private deactivateDrawOnMapToolbox() {
    this.mapService.deactivateDrawingTool(false);
    this.mapService.setGeometryDrawingCallback(() => {});
    this.term = "";
    this.isSearchResultVisible = false;
  }

  private geoFilterPlanVersion = (geojsonTerm) => {
    if (geojsonTerm === "{}") {
      //Do nothing
      return;
    }

    // zones from approved
    const approvedPlanVersions = this.planService.currentPublishedLayers
      //.filter((layer) => Object.keys(DefaultLayerTypeEnum).indexOf(layer.name) >= 0)
      .map((layer) => {
        return {
          planversionId: layer.planversionId,
          status: layer.status,
          layerName: layer.name,
          consultationStart: layer.consultationStart,
          consultationEnd: layer.consultationEnd,
        };
      });

    // zones from approved
    const consultationPlanVersions = this.planService.currentConsultationLayers
      //.filter((layer) => Object.keys(DefaultLayerTypeEnum).indexOf(layer.name) >= 0)
      .map((layer) => {
        return {
          planversionId: layer.planversionId,
          status: layer.status,
          layerName: layer.name,
          consultationStart: layer.consultationStart,
          consultationEnd: layer.consultationEnd,
        };
      });

    this.results = {
      approved: this.searchZonesInPlanVersionsThatIntersectWith(
        approvedPlanVersions,
        geojsonTerm
      ),
      consultations: this.searchZonesInPlanVersionsThatIntersectWith(
        consultationPlanVersions,
        geojsonTerm
      ),
    };

    this.isSearchResultVisible = true;
  };

  private searchZonesInPlanVersionsThatIntersectWith(
    planVersions: any[],
    geojsonTerm: string
  ): any[] {
    const termFeature: Feature = new GeoJSON().readFeature(geojsonTerm);
    const termGeo: Geometry = termFeature.getGeometry();
    let type = termGeo.getType();
    switch (type) {
      case "MultiPoint":
        var multiPoint = <MultiPoint>termGeo;
        if (multiPoint && multiPoint.getPoints().length > 0) {
          return this.searchByPoint(
            planVersions,
            multiPoint.getPoint(0).getCoordinates()
          );
        }
        break;
      case "MultiLineString":
        var multiLineString = <MultiLineString>termGeo;
        if (multiLineString && multiLineString.getLineStrings().length > 0) {
          return this.searchByLine(planVersions, multiLineString);
        }
        break;
      case "MultiPolygon":
        var multiPolygon = <MultiPolygon>termGeo;
        if (multiPolygon && multiPolygon.getPolygons().length > 0) {
          return this.searchByArea(planVersions, multiPolygon);
        }
        break;
      default:
        return [];
    }
  }

  private searchByPoint(planVersions: any, coordinats: any[]): any[] {
    let foundZones = [];
    planVersions
      .filter(this.nonSearchableLayer)
      .forEach((planVersion) => {
        console.log("analyzing planVersion: " + JSON.stringify(planVersion, null, 3));
      let planVersionZones: ZoneFeature[] = this.mapService.getZoneFeaturesAllForByLayer(
        planVersion.layerName,
        planVersion.planversionId
      );
      for (let i = 0; i < planVersionZones.length; i++) {
        let zoneFeature: Feature = new GeoJSON().readFeature(
          planVersionZones[i].geometry
        );
        if (
          zoneFeature.getGeometry().containsXY(coordinats[0], coordinats[1])
        ) {
          foundZones.push({
            ...planVersionZones[i],
            title: this.getZoneTitle(
              planVersionZones[i]
            ),
          });
        }
      }
    });
    return foundZones;
  }

  private searchByLine(planVersions: any, line: MultiLineString) {
    let foundZones = [];
    let compareGeom: Geometry = this.mapService.transformatToFromProjection(
      line,
      "EPSG:25832",
      "EPSG:4326"
    );
    let compareMultiLineString: MultiLineString = <MultiLineString>compareGeom;
    let fetureCompare1 = new Feature({
      geometry: compareMultiLineString.getLineString(0),
      name: "firstPolygon",
    });
    var geoJsonMultiLinestring1 = new GeoJSON().writeFeatureObject(
      fetureCompare1
    );
    planVersions
      .filter(this.nonSearchableLayer)
      .forEach((planVersion) => {
      let planVersionZones: ZoneFeature[] = this.mapService.getZoneFeaturesAllForByLayer(
        planVersion.layerName,
        planVersion.planversionId
      );
      for (let i = 0; i < planVersionZones.length; i++) {
        let zoneFeature: Feature = new GeoJSON().readFeature(
          planVersionZones[i].geometry
        );
        let comparePolygon2: MultiPolygon = <MultiPolygon>(
          this.mapService.transformatToFromProjection(
            zoneFeature.getGeometry(),
            "EPSG:25832",
            "EPSG:4326"
          )
        );

        let featureCompare2;
        if (comparePolygon2.getType() === GeometryType.MULTI_POLYGON) {
          featureCompare2 = new Feature({
            geometry: comparePolygon2.getPolygon(0),
            name: "secondPolygon",
          });
        } else if (comparePolygon2.getType() === GeometryType.POLYGON) {
          featureCompare2 = new Feature({
            geometry: comparePolygon2,
            name: "secondPolygon",
          });
        }

        var poly2 = new GeoJSON().writeFeatureObject(featureCompare2);
        var resultLines = lineintersect(geoJsonMultiLinestring1, poly2);
        if (resultLines.features.length > 0) {
          foundZones.push({
            ...planVersionZones[i],
            title: this.getZoneTitle(
              planVersionZones[i]
            ),
          });
        }
      }
    });
    return foundZones;
  }

  private searchByArea(planVersions: any, area: MultiPolygon): any[] {
    let foundZones = [];
    let compareGeom: Geometry = this.mapService.transformatToFromProjection(
      area,
      "EPSG:25832",
      "EPSG:4326"
    );
    let comparePolygon: MultiPolygon = <MultiPolygon>compareGeom;
    let fetureCompare1 = new Feature({
      geometry: comparePolygon.getPolygon(0),
      name: "firstPolygon",
    });
    var geoJsonPolygon1 = new GeoJSON().writeFeatureObject(fetureCompare1);
    planVersions
      .filter(this.nonSearchableLayer)
      .forEach((planVersion) => {
      let planVersionZones: ZoneFeature[] = this.mapService.getZoneFeaturesAllForByLayer(
        planVersion.layerName,
        planVersion.planversionId
      );
      for (let i = 0; i < planVersionZones.length; i++) {
        let zoneFeature: Feature = new GeoJSON().readFeature(
          planVersionZones[i].geometry
        );
        let comparePolygon2: MultiPolygon = <MultiPolygon>(
          this.mapService.transformatToFromProjection(
            zoneFeature.getGeometry(),
            "EPSG:25832",
            "EPSG:4326"
          )
        );

        let featureCompare2;
        if (comparePolygon2.getType() === GeometryType.MULTI_POLYGON) {
          featureCompare2 = new Feature({
            geometry: comparePolygon2.getPolygon(0),
            name: "secondPolygon",
          });
        } else if (comparePolygon2.getType() === GeometryType.POLYGON) {
          featureCompare2 = new Feature({
            geometry: comparePolygon2,
            name: "secondPolygon",
          });
        }

        var poly2 = new GeoJSON().writeFeatureObject(featureCompare2);
        try {
          if (
            intersect(geoJsonPolygon1, poly2) ||
            intersect(poly2, geoJsonPolygon1)
          ) {
            foundZones.push({
              ...planVersionZones[i],
              title: this.getZoneTitle(
                planVersionZones[i]
              ),
            });
          }
        } catch (e) {console.log(e); console.log("for zone ="+JSON.stringify( planVersionZones[i]));}
      }
    });
    return foundZones;
  }

  private extractGeoPointFrom(term: string) {
    let coordinates = regExpGeoPoint.exec(term);
    if (coordinates) {
      return [
        parseFloat(coordinates[1].replace(",", ".")),
        parseFloat(coordinates[3].replace(",", ".")),
      ];
    }
    return null;
  }

  private extractLatLongPointFrom(term: string) {
    let coordinates = regExpGeoPoint.exec(term) || regExpLatLongPoint.exec(term) || regExpLatLongPointDotComma.exec(term) || regExpLatLongPointCommaDot.exec(term);
    if (coordinates) {
      return [
        this.getFornatedLatLong(coordinates[3]),
        this.getFornatedLatLong(coordinates[1]),
      ];
    }
    return null;
  }

  getFornatedLatLong(value){
    if(value.indexOf('.') > 0){
      return parseFloat(value);
    }else{
      return parseFloat(value.replace(",", "."))
    }
  }

  // infinite scroll handling
  currentSearchResult: any = [];
  sum: number;
  throttle: number = 100;
  scrollDistance: number = 2;
  @ViewChild("searchResultContainer", {read: ElementRef, static: false}) searchResultContainerRef: ElementRef

  loadSearchResultList() {
    this.currentSearchResult= [];
    this.sum = 60;
    this.addItems(0, this.sum);
    if (this.searchResultContainerRef && this.searchResultContainerRef.nativeElement && this.searchResultContainerRef.nativeElement.scrollTop) {
      this.searchResultContainerRef.nativeElement.scrollTop = 0;
    }
  }

  onScrollDown() {
    const startIndex = this.sum;
    this.sum += 20;
    this.addItems(startIndex,  this.sum);
  }

  addItems(startIndex: number, endIndex: number) {
    if (this.results != null && this.results != null) {
      let i = startIndex;

      let idxA = 0;
      if (this.results.approved && (this.searchResultType === 0 || this.searchResultType === 1))
        idxA = this.results.approved.length
      let idxB = idxA;
      if (this.results.consultations && (this.searchResultType === 0 || this.searchResultType === 2))
        idxB += this.results.consultations.length;

      while (i < endIndex) {
        if (i < idxA) {
          let result = this.results.approved[i];
          this.currentSearchResult.push(result);
        }
        else if (i < idxB) {
          let j = i - idxA
          let result = this.results.consultations[j];
          this.currentSearchResult.push(result);
        }
        else {
          i = endIndex;
        }
        i++;
      }
    }
  }


  onSearchResultTypeChange = () => {
    this.loadSearchResultList();
  };

  nonSearchableLayer = (layer) => {
    return layer.layerName != "removed" && layer.layerName != "changed";
  }

}
