import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Mutex } from "async-mutex";
import * as jsPDF from "jspdf";
import View from "ol/View";
import Projection from "ol/proj/Projection";
import { ConsultationResponse } from "../models/consultationresponse";
import { ZoneInHoering } from "../models/zoneinhoering";
import { ZoneFeature } from "../plandataDefinition/zoneFeature";
import { EnvironmentService } from "./environment.service";
import { MapService } from "./map.service";

@Injectable()
export class PrintService {
  printedReportsPercentage: string;
  printFinished: boolean = false;
  currentHostName: string = window.location.origin;
  prepareHostName: string;

  constructor(
    private mapService: MapService,
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  async printMaps(timeLineData) {
    // localStorage.setItem("reportsToPrint",JSON.stringify(["8e967ff1-28f2-4399-b0c2-ab3ccee05552","3cccff12-efc5-4dcf-bf3c-6cf6761141e0", "b075b4ee-9c30-4267-9ad4-cb054cdd56bd", "e74c76e2-b653-4c1f-9e12-ea84cc5b59ab"]));

    // localStorage.setItem("reportsToPrint",JSON.stringify(["1b415306-c135-4e6e-b1fe-ea1cd88e9f15"]));

    this.printFinished = false;
    console.log("wait for map");
    if (!this.mapService.isMapLoaded())
      await this.mapService.onMapReady().toPromise();
    console.log("PRINTING!");
    let reportIds = JSON.parse(localStorage.getItem("reportsToPrint")) || [];
    let reportsValidFrom = JSON.parse(localStorage.getItem("reportsValidFrom"));
    let allPrintReportsNumber = reportIds.length;
    let printCounter = 0;
    this.printedReportsPercentage = "0";

    var format = "A4";
    var resolution = 100;
    var width = Math.round((297 * resolution) / 25.4);
    var height = Math.round((210 * resolution) / 25.4);
    let map = this.mapService.getMap();
    var size = map.getSize();
    var viewResolution = map.getView().getResolution();
    // Set print size
    var printSize = [width, height];
    map.setSize(printSize);
    var scaling = Math.min(width / size[0], height / size[1]);
    map.getView().setResolution(viewResolution / scaling);

    let me = this;
    const mutex = new Mutex();
    let i = 0;
    for (const reportId of reportIds) {
      const release = await mutex.acquire();
      i = i + 1;

      let consultationResponse = await this.http
        .get<ConsultationResponse>(
          this.environmentService.getEnvironment().serviceurl_portalcache +
            "consultation/response/get/" +
            reportId
        )
        .toPromise();
      console.log(
        "consultationResponse => " +
          JSON.stringify(consultationResponse, null, 3)
      );
      if (!consultationResponse || consultationResponse.name === null) {
        release();
        continue;
      }

      let timeLineIndex = 0;
      if (
        reportsValidFrom &&
        reportsValidFrom[i - 1] &&
        timeLineData &&
        timeLineData.length > 0
      ) {
        // timeLineData = timeLineData.reverse();
        let selectedDate = new Date(reportsValidFrom[i - 1]);
        timeLineIndex = this.getTimeIndex(selectedDate, timeLineData);
      }

      let ZonesInHearing = this.getZonesInHearing(
        consultationResponse.planversionID
      );
      let refLinkList = [];
      if (consultationResponse.refId && ZonesInHearing.length > 0) {
        let refList = consultationResponse.refId.split(",");
        for (let zoneObj of ZonesInHearing) {
          if (refList.indexOf(zoneObj.refId) >= 0) {
            let cordinates = this.getZonesFirstCordinates(
              zoneObj["geometry"]["coordinates"]
            );
            let partCordinateLink = cordinates
              ? cordinates[0] + "/" + cordinates[1]
              : "";
            let refidText = `-  ${zoneObj.refId}:  `;

            if (this.currentHostName === "https://admin.havplan.dk") {
              this.prepareHostName = this.currentHostName.replace(
                this.currentHostName,
                "https://havplan.dk"
              );
            } else if (
              this.currentHostName === "https://test-admin.havplan.dk"
            ) {
              this.prepareHostName = this.currentHostName.replace(
                this.currentHostName,
                "https://test.havplan.dk"
              );
            } else if (
              this.currentHostName === "https://havplan-admin.cloud.atkins.dk"
            ) {
              this.prepareHostName = this.currentHostName.replace(
                this.currentHostName,
                "https://havplan.cloud.atkins.dk"
              );
            } else {
              this.prepareHostName = this.currentHostName.replace(
                this.currentHostName,
                "http://localhost:4400"
              );
            }
            refLinkList.push(
              refidText +
                this.prepareHostName +
                "/da/page/zone/s/" +
                consultationResponse.planversionID +
                "/" +
                partCordinateLink +
                "/" +
                zoneObj.layerName +
                "/" +
                zoneObj.refId +
                "?timeLineIdx=" +
                timeLineIndex
            );
          }
        }
      }
      console.log("refLinkList:", refLinkList);

      this.mapService.clearSelectedFeatures("CONSULTATIONRESPONSEZONE");
      if (
        consultationResponse.geometry !== undefined &&
        Object.keys(consultationResponse.geometry).length !== 0
      ) {
        await this.mapService.showHearingAnswerGeometry(
          consultationResponse.geometry
        );
      } else {
        var projection = new Projection({
          code: "EPSG:25832",
          units: "m",
          extent: [120000, 5661139.2, 1378291.2, 6500000],
          getPointResolution: function (r) {
            return r;
          },
        });
        let zoomParam: number = 2.75;
        let xCordinate: number = 560500;
        let yCordinate: number = 6203000;

        map.setView(
          new View({
            center: [xCordinate, yCordinate],
            projection: projection,
            zoom: zoomParam,
            minResolution: 2.800005600011201,
          })
        );
      }

      map.once("rendercomplete", async function () {
        console.log("rendering map for " + consultationResponse.name);
        var mapCanvas = document.createElement("canvas");
        mapCanvas.width = width;
        mapCanvas.height = height;
        var mapContext = mapCanvas.getContext("2d");
        await Array.prototype.forEach.call(
          document.querySelectorAll(".ol-layer canvas"),
          function (canvas) {
            if (canvas.width > 0) {
              var opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
              var transform = canvas.style.transform;
              // Get the transform parameters from the style's transform matrix
              var matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(",")
                .map(Number);
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        var pdf = new jsPDF("landscape", "mm", format);

        pdf.addImage(
          mapCanvas.toDataURL("image/jpeg"),
          "JPEG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        );
        pdf.addPage("portrait", format);
        pdf.setFont("Times", "Roman").setFontSize(10);
        let pdfText = [];
        pdfText.push("UUID: " + reportId);
        pdfText.push(
          "Navn / Virksomhed / Organisation: " + consultationResponse.name
        );
        if (consultationResponse.address)
          pdfText.push("Adresse: " + consultationResponse.address);
        pdfText.push("Postnummer: " + consultationResponse.postalcode);
        pdfText.push("By: " + consultationResponse.city);
        if (consultationResponse.email)
          pdfText.push("E-mail: " + consultationResponse.email);
        if (consultationResponse.othercontact)
          pdfText.push(
            "Anden kontaktinformation: " + consultationResponse.othercontact
          );
        pdfText.push("PlanversionID: " + consultationResponse.planversionID);
        pdfText.push("refId: " + consultationResponse.refId);
        // pdfText.push("Sektorer: "+consultationResponse.zoneType);

        if (
          consultationResponse.zoneType &&
          consultationResponse.zoneType.length
        ) {
          if (consultationResponse.zoneType.split(",").length == 1) {
            pdfText.push("Zone type: " + consultationResponse.zoneType);
          } else {
            const addSpaceRegex = /(\,)/g;
            pdfText.push(
              "Zone type: " +
                consultationResponse.zoneType.replace(addSpaceRegex, ",  ")
            );
          }
        }

        if (consultationResponse.refId) {
          let label = refLinkList.length > 1 ? "zoner: " : "zone: ";
          for (let i = 0; i < refLinkList.length; i++) {
            if (i == 0) {
              pdfText.push(label);
            }
            pdfText.push(refLinkList[i]);
          }
        }

        // from https://stackoverflow.com/a/58953898
        const addWrappedText = ({
          propertyLines,
          text,
          textWidth,
          doc,
          fontSize = 10,
          fontType = "normal",
          lineSpacing = 7,
          xPosition = 10,
          initialYPosition = 10,
          pageWrapInitialYPosition = 10,
        }) => {
          let textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
          let pageHeight = doc.internal.pageSize.height - 20; // Get page height, well use this for auto-paging
          doc.setFontType(fontType);
          doc.setFontSize(fontSize);

          let cursorY = initialYPosition;

          let allLines = [...propertyLines, ...textLines];
          allLines.forEach((lineText) => {
            if (cursorY > pageHeight) {
              // Auto-paging
              doc.addPage();
              cursorY = pageWrapInitialYPosition;
            }
            doc.text(xPosition, cursorY, lineText);
            cursorY += lineSpacing;
          });
        };

        addWrappedText({
          propertyLines: pdfText,
          text: "Høringssvar: " + consultationResponse.consultation_description,
          textWidth: 250,
          doc: pdf,
          fontSize: 10,
          fontType: "normal",
          lineSpacing: 7, // Space between lines
          xPosition: 30, // Text offset from left of document
          initialYPosition: 20, // Initial offset from top of document; set based on prior objects in document
          pageWrapInitialYPosition: 20, // Initial offset from top of document when page-wrapping
        });

        // pdf.save('map.pdf');
        let pdfBlob = pdf.output("blob", { filename: reportId + "-cover.pdf" });

        const formData: FormData = new FormData();
        formData.append("report_id", reportId);
        formData.append("files", pdfBlob);
        await me.http
          .post(
            me.environmentService.getEnvironment().serviceurl_portalcache +
              "consultation/response/coverFile",
            formData
          )
          .toPromise();

        console.log("finish");
        document.body.style.cursor = "auto";
        release();
      });

      console.log("downloaded " + consultationResponse.name);
      printCounter++;
      this.printedReportsPercentage = (
        (printCounter / allPrintReportsNumber) *
        100
      ).toFixed(0);
    }
    await mutex.acquire();
    this.printFinished = true;
    localStorage.setItem("coversdone", "1");
  }

  private getZonesInHearing(planversionId) {
    // this.mapService.clearSelectedFeatures("SELECT");
    const zones: ZoneFeature[] = this.mapService.getZoneFeaturesForConsultation(
      planversionId,
      false
    );
    let zonesInHearings = new Array<ZoneInHoering>();
    zones.map((zone) => {
      let zoneInHearing = new ZoneInHoering();
      zoneInHearing.planversionId = zone.planInfo.planversionId;
      zoneInHearing.title = "";
      zoneInHearing.layerName = zone.layerName;
      zoneInHearing.refId = zone.refId;
      zoneInHearing.type = zone.zoneType;
      zoneInHearing.planInfo = zone.planInfo;
      zoneInHearing.geometry = zone.geometry;
      zonesInHearings.push(zoneInHearing);
    });
    return zonesInHearings;
  }
  private getZonesFirstCordinates(zone) {
    if (zone && zone.length > 0) {
      let zoneData = this.getRecZoneData(zone);
      return zoneData ? zoneData : null;
    } else {
      return null;
    }
  }
  getRecZoneData(zoneCordinate) {
    if (
      Array.isArray(zoneCordinate) &&
      (zoneCordinate.length == 2 || zoneCordinate.length == 3) &&
      !Array.isArray(zoneCordinate[0]) &&
      (zoneCordinate[0] >= 0 || zoneCordinate[0] <= 0) &&
      (zoneCordinate[1] >= 0 || zoneCordinate[1] <= 0)
    ) {
      return zoneCordinate;
    } else {
      for (let obj of zoneCordinate) {
        return this.getRecZoneData(obj);
      }
    }
  }

  getTimeIndex(newDate: Date, timeLineData: any) {
    for (let n = 0; n < timeLineData.length; n++) {
      if (
        new Date(new Date(timeLineData[n].start).setUTCHours(0, 0, 0, 0)) <=
        newDate
      ) {
        if (timeLineData.length == 1) {
          return n;
        }
        let nextIndex = n + 1;
        if (nextIndex < timeLineData.length) {
          if (
            new Date(
              new Date(timeLineData[nextIndex].start).setUTCHours(0, 0, 0, 0)
            ) >= newDate
          ) {
            return n;
          }
          if (n == timeLineData.length - 1) {
            return n;
          }
        }
      }
    }
    return timeLineData.length - 1;
  }
}
