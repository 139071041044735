import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Directive({
  selector: '[phoneNumberValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true}
  ]
})
export class PhoneNumberValidatorDirective implements Validator {

  phoneUtil = PhoneNumberUtil.getInstance();

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    let isValid = false;

    try {
      const phoneNumber = this.phoneUtil.parseAndKeepRawInput(control.value, null);
      isValid = this.phoneUtil.isValidNumber(phoneNumber);
    } catch (e) { }

    return isValid ? null : { phoneNumberValidator: true };
  }

}
