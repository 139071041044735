import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "./environment.service";
import { PreviewService } from "./preview.service";
import { Subject, Observable } from "rxjs";

@Injectable()
export class ContentService {

  private _content: any;
  private _prefixedElement: any;
  private _contentLoaded = new Subject<any>();

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private previewService: PreviewService
  ) {}
  
  public loadPublichedContent() {
    if(!this._content) {
      //console.log("call content service");
      this.getPublishedContent().subscribe(data => {
        this.currentContent = data; 
        //console.log("send content loadevent");
        this._contentLoaded.next();
      });
    }
  }

  onContentEventLoaded(): Observable<any> {
    return this._contentLoaded.asObservable();
  }

  public getPublishedContent(): Observable<any> { 
      let contentServiceEndpoint = "";
      let contentPreviewID = this.previewService.getContentPreviewID();
      if (contentPreviewID != null) {
        contentServiceEndpoint = this.environmentService.getEnvironment().serviceurl_protectedportalcache + "preview_content/" + contentPreviewID;
      }else {
        contentServiceEndpoint = this.environmentService.getEnvironment().serviceurl_portalcache + "published_content";
      }
      return this.http.get<any>(contentServiceEndpoint, { params: {} });
  }

  set currentContent(data: any) {
    this._content = data;
  }

  public getContent() {
    return this._content;
  }

  public getContentByPrefix(prefix: string) {
    if(this._content) {
      let contentItems = this._content.prefixes;
      for (let i = 0; contentItems && i < contentItems.length; i++) {
        if (contentItems[i].prefix === prefix) {
          return contentItems[i].elements;
        }
      }
    }
    return [];
  }

  public getTextByShortName(prefix: string, shortName: string) {
    if(this._content) {
      let contentItems = this._content.prefixes;
      for (let i = 0; contentItems && i < contentItems.length; i++) {
        if (contentItems[i].prefix === prefix) {
          let itemsChildren = contentItems[i].elements;
          for (let c = 0; itemsChildren && c < itemsChildren.length; c++) {
            if (itemsChildren[c].shortName === shortName) {
              return itemsChildren[c].text1;
            }
          }
        }
      }
      //console.log("NO_CMS: "+shortName+ " : "+prefix);
    }   
    return "";
  }

  public getHTMLByShortName(prefix: string, shortName: string) {
    if(this._content) {
      let contentItems = this._content.prefixes;
      for (let i = 0; contentItems && i < contentItems.length; i++) {
        if (contentItems[i].prefix === prefix) {
          let itemsChildren = contentItems[i].elements;
          for (let c = 0; itemsChildren && c < itemsChildren.length; c++) {
            if (itemsChildren[c].shortName === shortName) {
              return itemsChildren[c].text2;
            }
          }
        }
      }
    }   
    return "";
  }

  public getAltTextByShortName(prefix: string, shortName: string) {
    if(this._content) {
      let contentItems = this._content.prefixes;
      for (let i = 0; contentItems && i < contentItems.length; i++) {
        if (contentItems[i].prefix === prefix) {
          let itemsChildren = contentItems[i].elements;
          for (let c = 0; itemsChildren && c < itemsChildren.length; c++) {
            if (itemsChildren[c].shortName === shortName) {
              return itemsChildren[c].link2;
            }
          }
        }
      }
    }   
    return "";
  }

  public getCmsElementByShortName(prefix: string, shortName: string): object {
    if(this._content) {
      let contentItems = this._content.prefixes;
      for (let i = 0; contentItems && i < contentItems.length; i++) {
        if (contentItems[i].prefix === prefix) {
          let itemsChildren = contentItems[i].elements;
          for (let c = 0; itemsChildren && c < itemsChildren.length; c++) {
            if (itemsChildren[c].shortName === shortName) {
              return itemsChildren[c];
            }
          }
        }
      }
      //console.log("NO_CMS: "+shortName+ " : "+prefix);
    }
   
    return {};
  }
/* -----SUBSCRIPTION FROM SURVICE CALLS--------------- */
  subscribe(subscriberObj:any){
    return this.http.post<any>(this.environmentService.getEnvironment().serviceurl_portalcache +"newsletter/subscribe", subscriberObj);
  }

  unSubscribe(subscriberObj:any){
    return this.http.post<any>(this.environmentService.getEnvironment().serviceurl_portalcache +"newsletter/unsubscribe/"+subscriberObj.email, null);
  }

}
