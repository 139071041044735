import { Component, OnInit,OnDestroy, HostListener, Output, EventEmitter } from "@angular/core";
import { Subscription  } from 'rxjs';
import { PlandataService } from "../services/plandata.service";
import { ContentService } from '../services/content.service';
import { TimePlanversionEvent } from "../models/timeplanversionevent";
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import {PreviewService} from "../services/preview.service";
import { MapService } from '../services/map.service';
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = new Array();
  @Output() notifyPageChange: EventEmitter<String> = new EventEmitter();
  innerWidth: any;
  currentPage: string = "info";
  currDiv: string = "info";
  currentLang: string = null;


  tabPrefix:string = '/plan/tabs';
  isSubmitted = false;
  model: any = {};
  showHearingAnswerSingle: boolean;
  hoveredFeatureZones: [];
  consultationContent: any;
  contentString: any;
  infoContent: any;
  tabsContent: any;
  historicClass: string;
  isHistoric = false;


  constructor(
    private planService: PlandataService,
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private mapService: MapService,
    private router: Router,
    private previewService: PreviewService,
    private _planDataService: PlandataService,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.getCurrentPageFromURL();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 767) {
      this.currentPage = 'showMap';
    }

    const me = this;
    this.subscriptions.push(this.planService.onTimePlanversionChanged().subscribe((event: TimePlanversionEvent) => {
      if (event.historicPlan) {
        if (this.currentPage == 'hist') {
          this.isHistoric = true;
        } else {
          let page: string = this.getPageName(this.activatedRoute.snapshot.firstChild.url.toString());
          if(page != 'consultation'){
            //me.historicClass = "historic-"+this.currentLang;
          }
          this.isHistoric = true;
        }
      } else {
        me.historicClass = "";
        this.isHistoric = false;
      }

      console.log("isHistoric: " + this.isHistoric + "historicClass:" + me.historicClass)
    }));
  }


  getCurrentPageFromURL() {
    this.subscriptions.push(this.activatedRoute.url.subscribe(val => {
      this.setActiveTab(this.activatedRoute.snapshot.firstChild.url.toString());
      } )
    );
  }

  getPageName(pageUrlParts){
    let page: string = "";
    if(pageUrlParts.indexOf(",")){
      let parts = pageUrlParts.split(",");
      if(parts.length > 0) {
        page = parts[0];
      }
    }else {
      page = pageUrlParts;
    }
    return page;
  }

//------set active tab based on selected zone--------
  setActiveTab(pageUrlParts: string) : string {

    let page: string = this.getPageName(pageUrlParts);
    switch (page) {
      case "info":
        if (this.isHistoric) {
          this.historicClass = "";
        }
        this.currentPage = page;
        break;
      case "zone":
        if (this.isHistoric) {
           this.historicClass = "historic-"+this.currentLang;
        }
        this.currentPage = page;
        break;
      case "consultation":
        if (this.isHistoric) {
          this.historicClass = "historic-"+this.currentLang;
        }
        this.currentPage = page;
        break;
      case "hist":
        if (this.isHistoric) {
          this.historicClass = "";
        }
        this.currentPage = page;
        page += "/" + this._planDataService.currentTimeLineIndex;
        break;
      case "search":
        if (this.isHistoric) {
          this.historicClass = "";
        }
        this.currentPage = page;
        break;
      case "service-layers":
        if (this.isHistoric) {
          this.historicClass = "";
        }
        this.currentPage = page;
        break;
      case "showMap":
        if (this.isHistoric) {
          this.historicClass = "historic-"+this.currentLang;
        }
        this.currentPage = page;
        break;
      default:
        break;
    }
    if (this.innerWidth <= 767) {
      this.notifyPageChange.emit(this.currentPage);
    }
    return page;
  }

  changePage(page: string) {
    let newPage =this.setActiveTab(page);
    let parentpath = this.route.routeConfig.path;
    if(newPage == "zone") {
      let zoneFeatures = this.mapService.getSelectedFeature();
      let coordinates = this.mapService.clickedCoordinates;
      let timeLineIdx = this.planService.currentTimeLineIndex;
      if(zoneFeatures.length == 1) {
        let zone = zoneFeatures[0];
        if(coordinates && coordinates.length > 1) {
          newPage = newPage+"/s/"+zone.planInfo.planversionId+"/"+coordinates[0] +"/"+coordinates[1]+"/"+zone.layerName+"/"+zone.refId+"?timeLineIdx="+timeLineIdx;
        }else {
          newPage = newPage+"/s/"+zone.planInfo.planversionId+"/"+zone.layerName+"/"+zone.refId+"?timeLineIdx="+timeLineIdx;
        }

      }else if (zoneFeatures.length > 1) {
        if(coordinates && coordinates.length > 1) {
          newPage = newPage+"/m/"+coordinates[0] +"/"+coordinates[1]+"?timeLineIdx="+timeLineIdx;
        }
      }
    }

    if(newPage == "consultation") {
      if(this._planDataService.isHistoryMap()) {
        newPage +="/hist";
      }

    }

    let path = parentpath+"/" + newPage;
    this.router.navigateByUrl(path);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 767) {
      this.currentPage = 'showMap';
    }
    else {
      this.currentPage = 'info'
    }
  }


  getText(prefix, shortName) {
    this.infoContent = this.contentService.getContentByPrefix(this.currentLang+"/plan/info");
    return (this.contentString = this.contentService.getTextByShortName(
      prefix,
      shortName
    ));
  }

  getAltText(prefix,shortName){
    return this.contentService.getAltTextByShortName(this.currentLang+prefix,shortName);
  }

  isPrintPreview(): boolean {
    return this.previewService.isPrintPreview();
  }
}
