import { Component, OnInit, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ContentService } from "../services/content.service";
import { LanguageService } from "../services/language.service";
import { last, timeout } from "rxjs/operators";
import { Subject } from "rxjs";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-article",
  templateUrl: "./article.component.html",
  styleUrls: ["./article.component.scss"],
})
export class ArticleComponent implements OnInit {

  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    // If no anchor tag, then don't do anything.
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    // Prevent page from reloading, stay on the current page
    event.preventDefault();
    let target = <HTMLAnchorElement>event.target;
    // Navigate to the path in the link
    if(target.pathname.indexOf('/da/') == 0 || target.pathname.indexOf('/en/') == 0){
      if(target.target == '_blank'){
        window.open(target.pathname, "_blank");
      }else{
        // let params = null;
        // if(target.pathname.indexOf('/'+this.currentLang+'/') == -1){
        //   params = {queryParams: {isLoaded:false}};
        //   console.log(target.pathname)
        //   this.router.navigate([target.pathname +"/"],params);
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 500);
        // }else{
          this.router.navigate([target.pathname]);
        // }
      }
    }else{
      let newPath = target.pathname;
        if(newPath.startsWith("/")){
          newPath = target.href;
        }
        window.open(newPath, "_blank");
    }
  }
  
  path: string;
  content: any;
  article: any;
  elements: any;
  articles: any;
  backButton: any;
  currentArticle: any;
  routeParams: any;
  isNews: boolean = false;
  currentLang: string = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contentService: ContentService,
    private languageService: LanguageService,
    private sanitizer: DomSanitizer
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnInit() {
    this.route.params.subscribe((routeParams) => {
      this.routeParams = routeParams.shortName;
    });
    this.path = this.router.url.substring(1);
    if (!this.contentService.getContent()) {
      this.contentService.onContentEventLoaded().subscribe((any) => {
        this.setup();
      });
      this.contentService.loadPublichedContent();
    } else {
      this.setup();
    }
  }

  private setup() {
    this.content = this.contentService.getContent();
    this.content.prefixes.forEach((element) => {
      // Setting articles
      if (
        this.path.includes(element.prefix) &&
        element.prefix !== this.currentLang
      ) {
        this.articles = element.articles;
        // Setting current article
        this.articles.forEach((article) => {
          if (this.path.endsWith(article.shortName)) {
            this.currentArticle = article;
          }
        });
      }

      // Setting leftmenu
      let pathStart = this.path.split("/");
      let prefixStart = element.prefix.split("/");
      if (
        pathStart[1] === prefixStart[1] &&
        prefixStart[0] === this.currentLang &&
        element.prefix !== this.currentLang + "/news"
      ) {
        //console.log("DATA::" + JSON.stringify(element.elements));
        this.elements = element.elements;
      }

      // Checking if article is a newsarticle
      if (element.prefix === this.currentLang + "/news") {
        element.articles.forEach((element) => {
          if (element === this.currentArticle) {
            this.isNews = true;
          }
        });
        element.elements.forEach((element) => {
          if (element.shortName == "newslist_backlink")
            this.backButton = element;
        });
      }
    });
  }

  displayArticle(element) {
    // Navigate to new article with new route
    this.router.navigateByUrl(element.link1);
    // Timeout to catch the new routerParams
    setTimeout(() => {
      let path = this.router.url.substring(1);
      this.articles.forEach((article) => {
        if (article.shortName === this.routeParams) {
          //console.log(JSON.stringify(article));
          this.currentArticle = article; //Setting currentArticle acording to new route
        }
      });
    }, 0);
  }

  sanitize(str: string) {
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }

  goBack() {
    this.router.navigateByUrl("/" + this.currentLang + "/news");
  }
}
