import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule, Router } from "@angular/router";
import { MapComponent } from "./map/map.component";
import { NewsComponent } from "./news/news.component";
import { ContentService } from "./services/content.service";
import { ArticleComponent } from "./article/article.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { InfoComponent } from "./info/info.component";
import { HistoryComponent } from "./history/history.component";
import { SearchComponent } from "./search/search.component";
import { HearingComponent } from "./hearing/hearing.component";
import { ZonesComponent } from "./zones/zones.component";
import { LanguageService } from "./services/language.service";
import {ServiceLayersComponent} from "./service-layers/service-layers.component";

let routes: Routes = [
  { path: "da", redirectTo: "da/page/info", pathMatch: "full" },
  { path: "en", redirectTo: "en/page/info", pathMatch: "full" },
  {
    path: "da/page",
    data: { reuse: true },
    component: MapComponent,
    children: [
      { path: "info", component: InfoComponent },
      { path: "zone", component: ZonesComponent },
      { path: "zone/:id", component: ZonesComponent },
      { path: "zone/s/:planversionid/:name/:id", component: ZonesComponent },
      {
        path: "zone/s/:planversionid/:x/:y/:name/:id",
        component: ZonesComponent,
      },
      // { path: "zone/m/:planversionid/:x/:y", component: ZonesComponent },
      { path: "zone/m/:x/:y", component: ZonesComponent },
      { path: "zone/n/:x/:y/:type", component: ZonesComponent },
      { path: "zone/n/:type", component: ZonesComponent },
      { path: "consultation", component: HearingComponent }, // Show all current hearings
      { path: "consultation/hist", component: HearingComponent }, // Show all hearings
      { path: "consultation/print", component: HearingComponent }, // Show all hearings
      { path: "consultation/:planversionid", component: HearingComponent }, // Show a hearing

      { path: "consultation/:planversionid/hist", component: HearingComponent }, // Show all hearings
      {
        path: "consultation/answer/:planversionid/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/create/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/create",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/create/:zoneid",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/show",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/page/:pageNum",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/page/:pageNum/search/:searchTerm",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/page/:pageNum/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/page/:pageNum/search/:searchTerm/hist",
        component: HearingComponent,
      },
      { path: "hist", component: HistoryComponent },
      { path: "hist/:id", component: HistoryComponent },
      { path: "search", component: SearchComponent },
      { path: "search/:tekst", component: SearchComponent },
      { path: "service-layers", component: ServiceLayersComponent},
    ],
  },
  { path: "da/news", component: NewsComponent },
  { path: "da/news/:shortName", component: ArticleComponent },

  {
    path: "en/page",
    data: { reuse: true },
    component: MapComponent,
    children: [
      { path: "info", component: InfoComponent },
      { path: "zone", component: ZonesComponent },
      { path: "zone/:id", component: ZonesComponent },
      { path: "zone/s/:planversionid/:name/:id", component: ZonesComponent },
      {
        path: "zone/s/:planversionid/:x/:y/:name/:id",
        component: ZonesComponent,
      },
      // { path: "zone/m/:planversionid/:x/:y", component: ZonesComponent },
      { path: "zone/m/:x/:y", component: ZonesComponent },
      { path: "zone/n/:x/:y/:type", component: ZonesComponent },
      { path: "zone/n/:type", component: ZonesComponent },
      { path: "consultation", component: HearingComponent }, // Show all current hearings
      { path: "consultation/hist", component: HearingComponent }, // Show all hearings
      { path: "consultation/print", component: HearingComponent }, // Show all hearings
      { path: "consultation/:planversionid", component: HearingComponent }, // Show a hearing

      { path: "consultation/:planversionid/hist", component: HearingComponent }, // Show all hearings
      {
        path: "consultation/answer/:planversionid/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/create/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/hist",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/create",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/create/:zoneid",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/show",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid",
        component: HearingComponent,
      },
      {
        path: "consultation/answer/:planversionid/:answerid/page/:pageNum",
        component: HearingComponent,
      },
      { path: "hist", component: HistoryComponent },
      { path: "hist/:id", component: HistoryComponent },
      { path: "search", component: SearchComponent },
      { path: "search/:tekst", component: SearchComponent },
      { path: "service-layers", component: ServiceLayersComponent},
    ],
  },
  { path: "en/news", component: NewsComponent },
  { path: "en/news/:shortName", component: ArticleComponent },
  { path: "", redirectTo: "da/page/info", pathMatch: "full" },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  content: any;
  menuItems: any;
  prefixedDa: any;
  currentLanguage: string = null;
  private _errorUrlPath: string = "";
  dynamicArray = new Array();
  contentString: void;

  constructor(
    private contentService: ContentService,
    private languageService: LanguageService,
    private router: Router
  ) {
    // Route not loaded yet
    this.router.errorHandler = (error: any) => {
      let first = error.message.indexOf("'");
      if (first > -1) {
        let firstpartUrl = error.message.substring(first + 1);
        let lastindex = firstpartUrl.indexOf("'");
        if (lastindex > -1) {
          this._errorUrlPath = firstpartUrl.substring(0, lastindex);
        }
      }
    };

    if (!this.contentService.getContent()) {
      this.contentService.onContentEventLoaded().subscribe((any) => {
        this.setupRoute();
      });
      this.contentService.loadPublichedContent();
    } else {
      this.setupRoute();
    }

  }

  setupRoute() {
    this.currentLanguage = this.languageService.prepareLanguage();
    this.content = this.contentService.getContent();
    this.content.prefixes.forEach((element) => {
      // Create header menu dynamic (News are special)
      if (element.prefix === this.currentLanguage + "/topmenu") {
        element.elements.forEach((item) => {
          if (
            item.link1 !== "/" + this.currentLanguage + "/" &&
            item.link1 !== "/" + this.currentLanguage + "/news"
          ) {
            //console.log("topmenuPath: "+item.link1.substring(1));//.slice(0, -1));
            routes.push({
              path: item.link1.substring(1),

              component: ArticleComponent,
            });
          }
        });
      }

      // Create cms pages dynamically
      if (
        element.prefix !== this.currentLanguage + "/topmenu" &&
        element.prefix !== this.currentLanguage &&
        element.prefix !== this.currentLanguage + "/news"
      ) {

        element.articles.forEach((article) => {
          routes.push({
            path: element.prefix + "/:shortName",

            component: ArticleComponent,
          });
        });
      }
    });

    this.router.resetConfig(routes);
    if (this._errorUrlPath != "") {
      this.router.navigate([this._errorUrlPath]);
      this._errorUrlPath = "";
    }
  }
}
