import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import {
  defaults as DefaultControls,
  MousePosition,
  ScaleLine,
} from "ol/control";
import { format } from "ol/coordinate";
import { Image as ImageLayer, Tile as TileLayer } from "ol/layer";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";
import { transform } from 'ol/proj';
import Projection from "ol/proj/Projection";
import { TileWMS, Vector as VectorSource, WMTS as WMTSSource } from "ol/source";
import ImageWMS from "ol/source/ImageWMS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import proj4 from "proj4";
import { Subscription } from "rxjs";
import { MapSettings } from "../models/mapsettings";
import { TimePlanversionEvent } from "../models/timeplanversionevent";
import { EnvironmentService } from "../services/environment.service";
import { MapService } from "../services/map.service";
import { PlandataService } from "../services/plandata.service";

import { PreviewService } from "../services/preview.service";
//import {METERS_PER_UNIT} from 'ol/proj/units';
import GMLFormat2 from 'ol/format/GML2';
import GMLFormat3 from 'ol/format/GML3';
import WFSFormat from 'ol/format/WFS';
import { Stroke, Style } from 'ol/style';
import { ContentService } from "../services/content.service";
import { LanguageService } from "../services/language.service";

import moment from "moment";
import * as OlCoordinate from 'ol/coordinate';
import * as OlExtent from 'ol/extent';
import { Subject } from 'rxjs';
import { MapHelper } from './util/mapHelper';


@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy {
  vectorLayer: VectorLayer;
  mapContent: any;
  // mapPrefix = "da/plan/map";
  contentString: void;
  mapDefinitionPrefix = "/mapdefinition";
  private map: Map;
  private view: View;
  mapDisplay = "block";
  private _baseLayers = new Array();
  private _zoneLayers = new Array();
  private backgroundLayer: TileLayer;
  private _sfsOverlayers: TileLayer[] = new Array();
  private _modelOverlayers: any = {};
  private _zoneLayersLoaded = 0;
  //private kftoken: String = "d12107f70a3ee93153f313c7c502169a";
  private historicLayerBackground;
  private _layersLoed = false;
  private _mapsettings: MapSettings;
  private _tileGrid: WMTSTileGrid[] = new Array();
  private _defaultTileGrid: WMTSTileGrid;

  modalRef: BsModalRef;

  private subscriptions: Subscription[] = new Array();
  currentLang: string = null;
  _mapDefinition: any = null;
  displayItem: any = null;
  checkedMapDefinition:number = 0;
  templates:any = null;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  isServiceLayersOn: boolean = false;

  leftAreaWidth = 35;
  rightAreaWidth = 65;
  isLeftAreaCollapsed = false;

  isMobileView = window.matchMedia("only screen and (max-width: 767px)").matches;
  isMapPositionInitialized = false;

  //Prepare internal 'WMS layers' url based on "layer" and "style" parameter configuration in admin ui
  urlHost: string = window.location.origin;
  urlPart1: string = this.urlHost+"/geoserver/servicelayer/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&LAYER=servicelayer%3A";
  /* urlPart1: string = "http://localhost:8080/geoserver/servicelayer/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&TRANSPARENT=true&LAYER=servicelayer%3A"; */
  urlPart2: string = "&STYLE=servicelayer%3A";
  urlPart3: string = "&exceptions=application%2Fvnd.ogc.se_inimage&SRS=EPSG%3A7791&WIDTH=18&HEIGHT=18";

  constructor(
    private _modalService: BsModalService,
    private planService: PlandataService,
    private mapService: MapService,
    private environmentService: EnvironmentService,
    private route: ActivatedRoute,
    private router:Router,
    private previewService: PreviewService,
    private languageService: LanguageService,
    private contentService: ContentService
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    this.subscriptions.push(
      this.planService.getCurrentDateTime().subscribe((currentDateTime) => {
        this.planService.currentDateTime = new Date(currentDateTime.toString());
        this.subscriptions.push(
          this.planService.getPlandata().subscribe((data) => {

            // filter out 'cancellation event' that happened at the same time the other consultation started
            // @ts-ignore
            data.timeline = this.filterOutUnnecessaryCancellationEvent(data.timeline);
            this.planService.currentSeaPlandata = data;

            if (this.route.snapshot.queryParams.timeLineIdx) {
              this.planService.setPlanVersionFromHistorik(
                this.route.snapshot.queryParams.timeLineIdx
              );
            } else {
              this.planService.setPlanVersionFromCurrentDateTime();
            }

            // Set version before
            this._mapsettings = this.planService.getMapBaseSettings();
            this.createTileGrids();
            this.initMap();

            // Get eventfor change layers
            this.subscriptions.push(
              this.planService.onLayersEventChanged().subscribe((any) => {
                this._mapsettings = this.planService.getMapBaseSettings();
                this.createTileGrids();
                this.reloadBaseLayers();
                this.reloadLayers();
                this._zoneLayersLoaded = this._zoneLayers.length;
                this._layersLoed = false;
                for (let i = 0; i < this._zoneLayers.length; i++) {
                  this._zoneLayers[i]
                    .getSource()
                    .on("change", this.layerLoaded.bind(this));
                }
                //  console.log("start");
              })
            );
          })
        );
      })
    );
    if(this.mapService.isMapLoaded()){
      this.displayItem = this.planService.mapDefinition
    }else{
      this.subscriptions.push(
        this.planService.getMapDefinition().subscribe((mapDefinition) => {
          let mapDefinitionTemp = mapDefinition;
          //Getting specific data within mapdefination api call
          if (
            mapDefinitionTemp &&
            mapDefinitionTemp.mapDefinition &&
            mapDefinitionTemp.mapDefinition.layerGroups &&
            mapDefinitionTemp.mapDefinition.layerGroups.length > 0
          ) {
            this._mapDefinition = mapDefinitionTemp.mapDefinition.layerGroups;

            let mapDefinitionLength = this._mapDefinition.length;

            this.displayItem = {};
            for (let i = 0; i < mapDefinitionLength; i++) {
              if(!this.displayItem[this._mapDefinition[i].orderColumn]){
                this.displayItem[this._mapDefinition[i].orderColumn] = [];
              }
              this.displayItem[this._mapDefinition[i].orderColumn].push(this._mapDefinition[i]);
            }

            this.planService.mapDefinition = this.displayItem;
          }
        })
      );
    }

    this.subscriptions.push(
      this.mapService.serviceLayersModeSubject.subscribe((isServiceLayerOn) => {
        this.isServiceLayersOn = isServiceLayerOn;
      })
    );

    this.subscriptions.push(
      this.mapService.onFeatureSeleted().subscribe((selectedZoneFeature) => {
        if (this.isLeftAreaCollapsed && selectedZoneFeature && selectedZoneFeature.length > 0) {
          this.expandLeftPanel();
        }
      })
    );

    if (!this.isMapPositionInitialized) {

      if (this.isMobileView) {

        const mapNode = document.getElementById("map");
        const mapContainerMobile = document.getElementById("map-maincontainer-mobile");
        const mapContainerDesktop = document.getElementById("map-maincontainer-desktop");
        mapContainerDesktop.removeChild(mapNode);
        mapContainerMobile.appendChild(mapNode);


        const sidebarNode = document.getElementById("sidebar");
        const leftColumnDesktop = document.getElementById("left-column");
        const mapContainerMobileWrapper = document.getElementById("map-maincontainer-mobile-wrapper");
        leftColumnDesktop.removeChild(sidebarNode);
        mapContainerMobileWrapper.insertBefore(sidebarNode, mapContainerMobileWrapper.firstChild);

        this.isMobileView = true;
        // this.mapDisplay = "none";
      }
      else {
        // this.mapDisplay = "block";
        this.isMobileView = false;
      }

      this.isMapPositionInitialized = true;
    }

  }

  keys() {
    return this.displayItem ? Object.keys(this.displayItem) : [];
  }


  getText(prefix: string, shortName: string) {
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  getHTML(prefix: string, shortName: string) {
    return this.contentService.getHTMLByShortName(
      this.currentLang + prefix,
      shortName
    );
  }

  leavingMap(): void {
    this.mapService.clearHoveredFeatures();
  }

  onPageChange(currentPage: string) {
    if (currentPage != "showMap" && !this.previewService.isPrintPreview()) {
      this.mapDisplay = "none";
    } else if (currentPage == "showMap" || this.previewService.isPrintPreview()) {
      this.mapDisplay = "block";
    }
  }

  onResize(event) {


    if (this.isMobileView && event.target.innerWidth > 767) {

      const mapNode = document.getElementById("map");
      const mapContainerMobile = document.getElementById("map-maincontainer-mobile");
      const mapContainerDesktop = document.getElementById("map-maincontainer-desktop");

      mapContainerMobile.removeChild(mapNode);
      mapContainerDesktop.appendChild(mapNode);

      const sidebarNode = document.getElementById("sidebar");
      const leftColumnDesktop = document.getElementById("left-column");
      const mapContainerMobileWrapper = document.getElementById("map-maincontainer-mobile-wrapper");
      mapContainerMobileWrapper.removeChild(sidebarNode);
      leftColumnDesktop.appendChild(sidebarNode);

      this.mapDisplay = "block";

      this.isMobileView = false;
      return;
    }

    if (!this.isMobileView && event.target.innerWidth <= 767) {

      const mapNode = document.getElementById("map");
      const mapContainerMobile = document.getElementById("map-maincontainer-mobile");
      const mapContainerDesktop = document.getElementById("map-maincontainer-desktop");

      mapContainerDesktop.removeChild(mapNode);
      mapContainerMobile.appendChild(mapNode);

      const sidebarNode = document.getElementById("sidebar");
      const leftColumnDesktop = document.getElementById("left-column");
      const mapContainerMobileWrapper = document.getElementById("map-maincontainer-mobile-wrapper");
      leftColumnDesktop.removeChild(sidebarNode);
      mapContainerMobileWrapper.insertBefore(sidebarNode, mapContainerMobileWrapper.firstChild);

      // this.mapDisplay = "none";

      this.isMobileView = true;
      return;
    }

  }

  private reloadBaseLayers(): void {
    for (let i = 0; i < this._sfsOverlayers.length; i++) {
      this.map.removeLayer(this._sfsOverlayers[i]);
    }
    let oldBaseLayer = this.backgroundLayer;
    let newBaseLayer =  this.createBackGroundLayer();
    console.log(this.backgroundLayer.getProperties().source.layer_ !== oldBaseLayer.getProperties().source.layer_);
    if(newBaseLayer.getProperties().source.layer_ !== oldBaseLayer.getProperties().source.layer_){
      this.backgroundLayer = newBaseLayer;
      this.map.removeLayer(oldBaseLayer);
      this.map.addLayer(this.backgroundLayer);
    }

    this.createOverLayers();
  }

  private reloadLayers(): void {
    // TODO load reload backmaps if new
    for (let i = 0; i < this._zoneLayers.length; i++) {
      this.map.removeLayer(this._zoneLayers[i]);
    }

    this.createZoneLayers();
    for (let i = 0; i < this._zoneLayers.length; i++) {
      this.map.addLayer(this._zoneLayers[i]);
    }

    this.mapService.setChangeMarkingLayersVisibility("removed", true);
    this.mapService.setChangeMarkingLayersVisibility("changed", true);
  }

  private getToken(tokenValueOrKey: string): string {
    if (this._mapsettings.settings[tokenValueOrKey]) {
      return this._mapsettings.settings[tokenValueOrKey];
    }
    return tokenValueOrKey;
  }

  private createTileLayerWMS(config: any): TileLayer {
    // console.log(config.name);
    let tileLayer = new TileLayer({
      opacity: config.opacity ? config.opacity : 1.0,
      visible: true,
      zIndex: config.zindex != undefined ? config.zindex : 0,
      source: new TileWMS({
        url: config.url,
        params: config.params,
        crossOrigin: "aonymous",
      }),
    });
    return tileLayer;
  }

  private createTileLayerWMTS(config: any): TileLayer {
    let token = "";
    let url = config.url;
    let delim = config.url.indexOf("?") == -1 ? "?" : "&";
    if (config.token && config.token.value) {
      token = this.getToken(config.token.value);
      url += delim + config.token.key + "=" + token;
      delim = "&";
    }
    if (config.servicename) {
      url += delim + "servicename=" + config.servicename;
      delim = "&";
    }
    let tileLayer = new TileLayer({
      opacity: config.opacity ? config.opacity : 1.0,
      visible: true,
      zIndex: config.zindex != undefined ? config.zindex : 0,
      source: new WMTSSource({
        url: url,
        layer: config.layer,
        matrixSet: config.matrixSet,
        format: config.format,
        tileGrid: this.getTileGrid(config.tileGrid),
        style: config.styles,
        crossOrigin: "aonymous",
      }),
    });
    return tileLayer;
  }

  private createBackGroundLayer(): TileLayer {
    for (let i = 0; i < this._mapsettings.backgroundlayers.length; i++) {
      if (this._mapsettings.backgroundlayers[i].visible) {
        switch (this._mapsettings.backgroundlayers[i].type) {
          case "wms":
            return this.createTileLayerWMS(
              this._mapsettings.backgroundlayers[i]
            );
          case "wmts":
            return this.createTileLayerWMTS(
              this._mapsettings.backgroundlayers[i]
            );
          default:
            break;
        }
      }
    }
    return null;
  }

  private createOverLayers(): void {
    for (let i = 0; i < this._mapsettings.overlayers.length; i++) {
      // console.log(this._mapsettings.overlayers[i].visible);
      if (this._mapsettings.overlayers[i].visible) {
        let tileLayer = null;
        switch (this._mapsettings.overlayers[i].type) {
          case "wms":
            tileLayer = this.createTileLayerWMS(
              this._mapsettings.overlayers[i]
            );
            break;
          case "wmts":
            tileLayer = this.createTileLayerWMTS(
              this._mapsettings.overlayers[i]
            );
            break;
          default:
            break;
        }
        if (tileLayer != null) {
          this._sfsOverlayers.push(tileLayer);
        }
      }
    }
  }

  private createZoneLayers() {
    let publishedSeePlanLayers = this.planService.currentPublishedLayers;
    let consultationSeePlanLayers = this.planService.currentConsultationLayers;
    this._zoneLayers = new Array();
    // add publiched layers
    for (let i = 0; i < publishedSeePlanLayers.length; i++) {
      let url =
        this.environmentService.getEnvironment().serviceurl_portalcache +
        "geodata?fileId=" +
        publishedSeePlanLayers[i].uuid;
      let zIndex = this.planService.findLayerZIndex(
        publishedSeePlanLayers[i].name
      );
      console.log(publishedSeePlanLayers[i].name +" : "+zIndex+" : "+url);
      this._zoneLayers.push(
        this.mapService.createGeoJSONLayer(
          url,
          publishedSeePlanLayers[i],
          this.mapService.styleChanges.bind(
            this.mapService,
            publishedSeePlanLayers[i].styleType
          ),
          zIndex
        )
      );
    }
    // add consultation
    for (let i = 0; i < consultationSeePlanLayers.length; i++) {
      let url =
        this.environmentService.getEnvironment().serviceurl_portalcache +
        "geodata?fileId=" +
        consultationSeePlanLayers[i].uuid;
      let zIndex = this.planService.findLayerZIndex(
        consultationSeePlanLayers[i].name
      );

      this._zoneLayers.push(
        this.mapService.createGeoJSONLayer(
          url,
          consultationSeePlanLayers[i],
          this.mapService.styleChanges.bind(
            this.mapService,
            consultationSeePlanLayers[i].styleType
          ),
          zIndex
        )
      );
    }
  }

  private getTileGrid(index: number) {
    if (this._tileGrid[index]) {
      return this._tileGrid[index];
    }
    return this._defaultTileGrid;
  }

  private createTileGrids() {
    this._tileGrid = new Array();
    for (let i = 0; i < this._mapsettings.settings.tilegrids.length; i++) {
      this._tileGrid.push(
        new WMTSTileGrid({
          extent: this._mapsettings.settings.tilegrids[i].extent,
          resolutions: this._mapsettings.settings.tilegrids[i].resolutions,
          matrixIds: this._mapsettings.settings.tilegrids[i].matrixIds,
        })
      );
    }
  }

  private initMap() {

    console.log("INIT MAP");

    proj4.defs(
      "EPSG:25832",
      "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs"
    );
    var projection = new Projection({
      code: "EPSG:25832",
      units: "m",
      extent: [120000, 5661139.2, 1378291.2, 6500000],
      getPointResolution: function (r) {
        return r;
      },
    });

    this._defaultTileGrid = new WMTSTileGrid({
      extent: [120000, 5661139.2, 1378291.2, 6500000],
      resolutions: [
        1638.4,
        819.2,
        409.6,
        204.8,
        102.4,
        51.2,
        25.6,
        12.8,
        6.4,
        3.2,
        1.6,
        0.8,
        0.4,
        0.2,
      ],
      matrixIds: [
        "L00",
        "L01",
        "L02",
        "L03",
        "L04",
        "L05",
        "L06",
        "L07",
        "L08",
        "L09",
        "L10",
        "L11",
        "L12",
        "L13",
      ],
    });

    let zoomParam: number = this._mapsettings.settings.zoom;
    let xCordinate: number = this._mapsettings.settings.center[0];
    let yCordinate: number = this._mapsettings.settings.center[1];

    if (this.route.snapshot.queryParams) {
      if (this.route.snapshot.queryParams.zoom != null) {
        zoomParam = Number(this.route.snapshot.queryParams.zoom);
      }
      if (this.route.snapshot.queryParams.x != null) {
        xCordinate = Number(this.route.snapshot.queryParams.x);
      }
      if (this.route.snapshot.queryParams.y != null) {
        yCordinate = Number(this.route.snapshot.queryParams.y);
      }
    }

    this.view = new View({
      center: [xCordinate, yCordinate],
      projection: projection,
      zoom: zoomParam,
      extent: [119500, 5961139.2, 998291.2, 6500000],
      minResolution: this._mapsettings.settings.minResolution, // 2.800005600011201
    });

    this.backgroundLayer = this.createBackGroundLayer();
    this.createOverLayers();

    var imageLoadFunctionDa = function (image, src) {
      var isIE = /msie\s|trident\/|old\//i.test(window.navigator.userAgent);
      //image.getImage().src = "/assets/historisk-4096-2048-da" + (isIE ? '.png': '.svg');
      image.getImage().src =
        "/assets/historic_watermark_da" + (isIE ? ".png" : ".svg");
    };

    var imageLoadFunctionEn = function (image, src) {
      var isIE = /msie\s|trident\/|old\//i.test(window.navigator.userAgent);
      //image.getImage().src = "/assets/historisk-4096-2048-en" + (isIE ? '.png': '.svg');
      image.getImage().src =
        "/assets/historic_watermark_en" + (isIE ? ".png" : ".svg");
    };

    this.historicLayerBackground = new ImageLayer({
      visible: this.planService.isHistoryMap(),
      //visible: false,
      zIndex: 10000,
      source: new ImageWMS({
        url: "",
        imageLoadFunction:
          this.currentLang == "en" ? imageLoadFunctionEn : imageLoadFunctionDa,
        params: { LAYERS: "watermark" },
        projection: projection,
      }),
    });

    this._baseLayers.push(this.backgroundLayer);

    this.createZoneLayers();

    this.map = new Map({
      layers: this._baseLayers,
      target: "map",
      view: this.view,

      controls: DefaultControls().extend([
        new ScaleLine({
          minWidth: 100,
        }),
        new MousePosition({
          coordinateFormat: function (coord) {
            var coordX = Number(coord[0]).toFixed(0);
            var coordY = Number(coord[1]).toFixed(0);

            var lon_lat = transform(coord, 'EPSG:25832', 'EPSG:4326');
            var lat = Number(lon_lat[1]).toFixed(5);
            var lon = Number(lon_lat[0]).toFixed(5);

            lat = lat.toString().replace('.', ',');
            lon = lon.toString().replace('.', ',');

            if(me.currentLang == "da"){
             me.templates = coordX + "\xa0\xa0" + coordY + "\xa0" + me.getText(me.mapDefinitionPrefix, "coord_map_hover_da") + "\xa0\xa0\xa0\xa0" +lat + "\xa0\xa0" +lon +
             "\xa0\xa0" + me.getText(me.mapDefinitionPrefix, "lat_long_map_hover_da");
            }

            if(me.currentLang == "en"){
              me.templates = coordX + "\xa0\xa0" + coordY + "\xa0" + me.getText(me.mapDefinitionPrefix, "coord_map_hover_en")+ "\xa0\xa0\xa0\xa0" +lat + "\xa0\xa0" +lon +
              "\xa0\xa0" + me.getText(me.mapDefinitionPrefix, "lat_long_map_hover_en");
             }

            return format(coord, me.templates, 2);
          },
          projection: "EPSG:25832",
        }),
      ]),
    });

    console.log("@@ MAP CREATED");

    this.map.addLayer(this.historicLayerBackground);

    for (let i = 0; i < this._sfsOverlayers.length; i++) {
      this.map.addLayer(this._sfsOverlayers[i]);
    }

    this.addModelLayers();

    for (let i = 0; i < this._zoneLayers.length; i++) {
      this.map.addLayer(this._zoneLayers[i]);
    }
    console.log("@@ LAYERS ADDED");
    this.mapService.setMap(this.map);
    console.log("@@ setMap()");

    this.mapService.setChangeMarkingLayersVisibility("changed",false);
    this.mapService.setChangeMarkingLayersVisibility("removed",false);



    let me = this;
    this.subscriptions.push(
      this.planService
        .onTimePlanversionChanged()
        .subscribe((event: TimePlanversionEvent) => {
          if (event.historicPlan) {
            me.historicLayerBackground.setVisible(true);
          } else {
            me.historicLayerBackground.setVisible(false);
          }
        })
    );

    this.mapService.mainRoutePath = this.route.routeConfig.path;
    this.mapService.createInternalVectorLayers();
    this.mapService.createInteractions();
    this.mapService.clearSelectedFeatures("SELECT");

    /*
    this.subscriptions.push(
      this.mapService.onSelectFeaturesSelected().subscribe(() => {
        //this.mapService.clearMarkerFeature();
      })
    );*/

    this.mapService.setNewHearingAnswerCallback(function (feature: Feature) {
      // me.mapService.hearingAnswerDisplayLayer.getSource().clear();

      me.mapService.interActions["select"].getFeatures().clear();
      /*if(!feature) {
        me.mapService.clearMarkerFeature();
      }*/

      if (feature) {
        me.mapService.hearingAnswerDisplayLayer.getSource().addFeature(feature);
      }
    });

    // map click and hover events
    this.map.on("click", this.mapService.clickMapHandler.bind(this.mapService));
    this.map.on("pointermove", this.mapService.onHover.bind(this.mapService));

    // Event for zoneLayers loaded
    this._zoneLayersLoaded = this._zoneLayers.length;
    for (let i = 0; i < this._zoneLayers.length; i++) {
      this._zoneLayers[i].getSource().on("change", this.layerLoaded.bind(this));
    }

    console.log("@@@@ zoneLayersLoaded = " + this._zoneLayersLoaded);

    if (this._zoneLayersLoaded == 0 || this._zoneLayersLoaded <= 2) {
      this.mapService.setMapIsReady();
    }

    if (this.previewService.isPrintPreview()) {
      this.mapDisplay = "block";
    }

    // @ts-ignore
    const resizeObserver = new ResizeObserver((entries) => {
     const splitHandler = document.getElementsByClassName("as-split-gutter-icon")[0];
     if (!splitHandler) return;
     const splitHandlerRect = splitHandler.getBoundingClientRect();
     if (this.isLeftAreaCollapsed) {
       document.getElementById("toggle-area-button").style.left = "calc(" + (splitHandlerRect.right + "px") + " + 3.2em)";
       document.getElementById("sidebar-tabs-group").style.left = "calc(" + (splitHandlerRect.left + "px") + " + 11px)";
     } else {
       document.getElementById("toggle-area-button").style.left = splitHandlerRect.right + "px";
       document.getElementById("sidebar-tabs-group").style.left = "calc(" + (splitHandlerRect.left + "px") + " + 11px)";
     }
     this.map.updateSize();
    });

    resizeObserver.observe(document.querySelector("#map"));
  }
 //-----Add WMS/WFS layer in a MAP----------------
  addModelLayers(){
    for(let item of this.keys()){
      for(let mainLayer of this.displayItem[item]){
        for(let modelOverlayer of mainLayer.overlayLayers){
          if(modelOverlayer && modelOverlayer['checked'] && modelOverlayer['defaultType'] === 'WMS' && ((modelOverlayer['internalLayerName'] && modelOverlayer['stilartName'] && modelOverlayer['layerSource'] === 'INTERNAL')
              || (modelOverlayer['externalWmsUrl'] && modelOverlayer['layerSource'] === 'EXTERNAL'))){
            if(modelOverlayer['checked']){
              this.checkedMapDefinition = this.checkedMapDefinition + 1;
            }
            let includeWmsLayerInMap = this.getWmsModelOverlay(modelOverlayer,modelOverlayer['layerSource']);
            this._modelOverlayers[modelOverlayer.name] = includeWmsLayerInMap
            this.map.addLayer(includeWmsLayerInMap);
          }else if(modelOverlayer && modelOverlayer['checked'] && modelOverlayer['defaultType'] === 'WFS' && modelOverlayer['externalWfsUrl']){
            if(modelOverlayer['checked']){
              this.checkedMapDefinition = this.checkedMapDefinition + 1;
            }
            //Add WFS layer code
            this.createWFSOverLayer(modelOverlayer)
          }
        }
      }
    }
  }

  removeModelLayer(modelOverlayerName){
    let isFoundRecord:boolean  = false;
      if(this._modelOverlayers && this._modelOverlayers[modelOverlayerName]){
       // console.log("Found And Remove::")
        this.map.removeLayer(this._modelOverlayers[modelOverlayerName]);
        delete this._modelOverlayers[modelOverlayerName];
      }
  }

  checkUncheckModelOverlay(event,modelOverlayer){
    event.preventDefault();
 /*    console.log(JSON.stringify(modelOverlayer)) */
    modelOverlayer['checked'] = !modelOverlayer['checked']

    if(modelOverlayer['checked']){
      this.checkedMapDefinition = this.checkedMapDefinition + 1;
    }else{
      this.checkedMapDefinition = this.checkedMapDefinition - 1;
    }

    if(modelOverlayer && modelOverlayer['defaultType'] === 'WMS'){
      if(modelOverlayer['checked'] && ((modelOverlayer['internalLayerName'] && modelOverlayer['stilartName'] && modelOverlayer['layerSource'] === 'INTERNAL')
            || (modelOverlayer['externalWmsUrl'] && modelOverlayer['layerSource'] === 'EXTERNAL'))){
        let includeWmsLayerInMap = this.getWmsModelOverlay(modelOverlayer,modelOverlayer['layerSource']);
        this._modelOverlayers[modelOverlayer.name] = includeWmsLayerInMap
        this.map.addLayer(includeWmsLayerInMap);
      }else{
        this.removeModelLayer(modelOverlayer['name']);
      }
    }else if(modelOverlayer && modelOverlayer['defaultType'] === 'WFS'){
      if(modelOverlayer['checked'] && modelOverlayer['externalWfsUrl']){
        //Add WFS layer code
        this.createWFSOverLayer(modelOverlayer)
      }else{
        //Remove WFS layer code
        this.removeModelLayer(modelOverlayer['name'])
      }
    }
    // this.createWFSOverLayer({'externalWfsUrl':"https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WFSServer?request=GetFeature&service=WFS&outputFormat=GML3&version=1.1.0&typeName=dmp:HABITAT_OMR&title=HABITAT_OMR"});
    //console.log("CHECKED/ UNCHECKED/")
  }

  getWmsModelOverlay(wmsModelOverlayer,layerSource){
    let url = null;
    let params = {};
    let opacity = 0.75;

    if(layerSource === 'INTERNAL'){
         url = this.urlHost+'/geoserver/servicelayer/wms';
  /*      url = 'http://localhost:8080/geoserver/servicelayer/wms';  */
      params = {
        'LAYERS':'servicelayer:' + wmsModelOverlayer['internalLayerName'], //layer
        'STYLES':'servicelayer:' + wmsModelOverlayer['stilartName'], //Style
        'FORMAT': "image/png"
      }
    }else if(layerSource === 'EXTERNAL'){
      url = wmsModelOverlayer['externalWmsUrl'];
      params = {
        'FORMAT': "image/png",
        'crossOrigin': "anonymous"
      }
    }else{
      return null;
    }

    //Overwrite the params by doing the configurion within options field
    if(wmsModelOverlayer['layerProps'] && typeof wmsModelOverlayer['layerProps'] === 'object' && wmsModelOverlayer['layerProps'] !== null){
      opacity = wmsModelOverlayer['layerProps']['opacity'] ? wmsModelOverlayer['layerProps']['opacity'] : opacity;
      if(wmsModelOverlayer['layerProps']['params']){
        this.overWriteParams(params,wmsModelOverlayer['layerProps']['params'])
        console.log(JSON.stringify(params))
      }
    }

    let includeWmsLayerInMap = new TileLayer({
        'opacity': opacity,
        'source': new TileWMS({
          'url': url,
          'params':params
        })

      })
    return includeWmsLayerInMap;
  }


  // getWfsModelOverlay(wfsModelOverlayer,layerSource){
  // let layer = new VectorLayer({
  //     source: new VectorSource({
  //       // format: new GeoJSON(),
  //       format: new WFSFormat({ gmlFormat: new GMLFormat3() }),
  //       url: function (extent) {
  //         return (
  //           /* 'https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WFSServer?request=GetFeature&service=WFS&version=1.1.0&typeName=dmp:NATUR_VILDT_RESERVAT&outputFormat=GML3' */
  //           'https://arealinformation.miljoeportal.dk/gis/services/DAIdb/MapServer/WFSServer?request=GetFeature&service=WFS&outputFormat=GML3&version=1.1.0&typeName=dmp:HABITAT_OMR&title=dmp:INDSATSPLANNER'
  //         );
  //       },
  //       strategy: bboxStrategy,
  //     })
  //   });

  //     this.map.addLayer(layer);
  // this.createWFSOverLayer(wfsModelOverlayer)

  // }

  createWFSOverLayer(overlay): void {
   /*  console.log("Crete WFS overlay") */
    const props = overlay.layerProps as any;
    let layerParams: any = {};
    layerParams = MapHelper.getCustomProps(props, layerParams);
    //console.log(JSON.stringify(layerParams))
    layerParams = MapHelper.getParams(overlay.externalWfsUrl, layerParams);
    //console.log(JSON.stringify(layerParams))
    const urlparts = overlay.externalWfsUrl.split('?');

      if (urlparts && urlparts.length > 1) {
        overlay.externalWfsUrl = urlparts[0] + '?';
      }
      for (const key in layerParams) {
        if (key) {
          overlay.externalWfsUrl += key + '=' + layerParams[key] + '&';
        }
      }

    const loadingStrategy = function(extent, resolution) {
      if(this.resolution && this.resolution != resolution){
          this.loadedExtentsRtree_.clear();
      }
      return [extent];
    };

    const thisme = this;
    const vectorSource = new VectorSource({
      format: new WFSFormat({ gmlFormat: new GMLFormat2() }),
      loader: function (extent, resolution, projection) {
        // this.resolution = resolution;
        /* console.log(overlay.externalWfsUrl) */
        // console.log(thisme.mapService.currentMapEPSG.toLowerCase())
        let url = new URL(overlay.externalWfsUrl + '&srsName=epsg:25832');
       /*  console.log(url) */
        let params =url.searchParams;

        if(params && params.get("filter") && extent && extent.length ==4 ) {
          const oParser = new DOMParser();
          let oDOM = oParser.parseFromString(decodeURIComponent(params.get("filter")), "application/xml");
          let bbox = oDOM.getElementsByTagName("BBOX");
          if(bbox && bbox.length>0) {
            let lowerCorner = bbox[0].getElementsByTagName("lowerCorner");
            if(lowerCorner && lowerCorner.length>0) {
              lowerCorner[0].innerHTML = OlCoordinate.format(OlExtent.getBottomLeft(extent), '{x} {y}', 3);
            }
            let upperCorner = bbox[0].getElementsByTagName("upperCorner");
            if(upperCorner && upperCorner.length>0) {
              upperCorner[0].innerHTML = OlCoordinate.format(OlExtent.getTopRight(extent), '{x} {y}', 3);
            }
            bbox[0].setAttribute("srsName",'epsg:25832');
          }
          var oSerializer = new XMLSerializer();
          var sXML = oSerializer.serializeToString(oDOM);

          params.delete("filter");

          url = new URL(url.toString() + '&filter=' + encodeURIComponent(sXML));

        } else if(extent && extent.length ==4) {
          url = new URL(url.toString() + '&bbox=' + extent.join(','));
        }

          /* console.log("==>"+url.href) */
          thisme.mapService.callByProxy(url.href).subscribe(response => {
              if(params.get("version") && params.get("version").indexOf("1.0.0") > -1) {
                vectorSource.addFeatures(new WFSFormat({gmlFormat: new GMLFormat2() }).readFeatures(response.body));
              }else {
                vectorSource.addFeatures(new WFSFormat({gmlFormat: new GMLFormat3() }).readFeatures(response.body));
              }
          });
      },
      strategy: loadingStrategy,
      // projection: OlProj.get("EPSG:25832")
    });

    let stroke = {};
    if(overlay.layerProps && overlay.layerProps.stroke ){
      stroke = overlay.layerProps.stroke;
    }
    const vector = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke(stroke),
  })
    });

    this._modelOverlayers[overlay.name] = vector;
    this.map.addLayer(vector);
  }

  overWriteParams(params,source){
    for(let key of Object.keys(source)){
      /* console.log(key) */
      if(typeof source[key] === 'object' && source[key] !== null){
        if(!params[key.toUpperCase()]){
          params[key.toUpperCase()] = {};
        }
        this.overWriteParams(params[key.toUpperCase()],source[key])
      }else{
        params[key.toUpperCase()] = source[key];
      }
    }
  }

  private layerLoaded(evt: any) {
    if (evt.target.loading) {
      this._zoneLayersLoaded -= 1;
    }
    if ((this._zoneLayersLoaded == 0 ||
        (this._zoneLayersLoaded == 2 && !this.mapService.showChangeMarkingLayers["removed"] && !this.mapService.showChangeMarkingLayers["changed"])) &&
        !this._layersLoed) {
      this._layersLoed = true;
      this.mapService.setMapIsReady();
    }
  }

  openDialogForMappingData(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, {
      class: "modal-xl modal-xlc",
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

 // openUrl(data: any) {
    //console.log("Redirect to:"+('/'+this.currentLang+'/'+this.getText(this.mapDefinitionPrefix, "service_layer_deep_link")+'/#'+data.name));
    //this.modalRef.hide();
    // this.router.navigate(['/'+this.currentLang+'/deeplink/'+this.getText(this.mapDefinitionPrefix, "service_layer_deep_link")],{fragment: data.name});
   // let url = '/'+this.currentLang+'/servicelayer/'+this.getText(this.mapDefinitionPrefix, "service_layer_deep_link") + "#"+data.name;
   // window.open(url,'_blank')
 //}
 openLinkField(url){
   window.open(url,'_blank')
 }

  confirm(): void {
    this.modalRef.hide();
  }

  private filterOutUnnecessaryCancellationEvent(timeLine: any[]) {
    const startedEventList = [];

    for (let i = 0; i < timeLine.length; i++) {
      const event = timeLine[i];
      if (event.timelineType == "SUPPLEMENT_CONSULTATION_START" || event.timelineType == "PLAN_CONSULTATION_START") {
        const startDate = moment(event.start);
        startDate.set({second:0,millisecond:0});
        startedEventList.push(startDate);
      }
    }

    return timeLine.filter(event => {
      if (event.timelineType == "CANCELLATION") {
        const cancelEventDate = moment(event.start);
        cancelEventDate.set({second:0,millisecond:0});

        const found = startedEventList.find((startEvent) => {
          return startEvent.isSame(cancelEventDate);
        });

        if (!found) {
          return true;
        }
        return false;
      }
      return true;
    })
  }

  collapseLeftPanel() {
    this.leftAreaWidth = 0;
    this.rightAreaWidth = 100;
    this.isLeftAreaCollapsed = true;
  }

  expandLeftPanel() {
    this.leftAreaWidth = 35.00;
    this.rightAreaWidth = 65.00;
    this.isLeftAreaCollapsed = false;
  }

}


