import { PlanInfo } from "../plandataDefinition/planInfo";
export class ZoneInHoering {
  planversionId: string;
  refId: string;
  layerName: string;
  planInfo: PlanInfo;
  type: string;
  title: any;
  geometry: JSON;
}
