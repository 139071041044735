import { Injectable } from "@angular/core";

@Injectable()
export class PreviewService {

  private plandataPreviewUUID: string = null;
  private plandataConsultationPreviewUUID: string = null;
  private contentPreviewID: number = null;
  private printPreview: number = null;

  private plandataPreviewUUIDParam: string = "plandata_preview";
  private plandataConsultationPreviewUUIDParam: string = "plandata_consultation_preview";
  private contentPreviewIDParam: string = "content_preview";
  private printPreviewParam: string = "print_preview";

  constructor() {
    this.plandataPreviewUUID = this.getUUIDParameterByName(this.plandataPreviewUUIDParam);
    this.plandataConsultationPreviewUUID = this.getUUIDParameterByName(this.plandataConsultationPreviewUUIDParam);
    this.contentPreviewID = this.getIntParameterByName(this.contentPreviewIDParam);
    this.printPreview = this.getIntParameterByName(this.printPreviewParam);

    if (this.isPreviewMode()) {
      console.log(this.getPreviewModeText());
    }
  }

  isPreviewMode() : boolean {
    return (this.plandataPreviewUUID != null || this.plandataConsultationPreviewUUID != null || this.contentPreviewID != null);
  }

  getPreviewModeText() : string {
    // preview mode texts are and must be hardcoded
    if (this.plandataPreviewUUID != null)
      return "PREVIEW (Havplan endelig version)";
    else if (this.plandataConsultationPreviewUUID != null)
      return "PREVIEW (Havplan høringsforslag )";
    else if (this.contentPreviewID != null)
      return "PREVIEW (CMS indhold)";
    else
      return "";
  }

  getPlandataPreviewUUID() : string {
    return this.plandataPreviewUUID;
  }

  getPlandataConsultationPreviewUUID() : string {
    return this.plandataConsultationPreviewUUID;
  }

  getContentPreviewID() : number {
    return this.contentPreviewID;
  }

  isPrintPreview() : boolean {
    return this.printPreview != null;
  }

  getQuerystringParamName() : string {
    if (this.plandataPreviewUUID != null)
      return this.plandataPreviewUUIDParam;
    else if (this.plandataConsultationPreviewUUID != null)
      return this.plandataConsultationPreviewUUIDParam;
    else if (this.contentPreviewID != null)
      return "" + this.contentPreviewIDParam;
    else
      return "";
  }

  getQuerystringParamValue() : string {
    if (this.plandataPreviewUUID != null)
      return this.plandataPreviewUUID;
    else if (this.plandataConsultationPreviewUUID != null)
      return this.plandataConsultationPreviewUUID;
    else if (this.contentPreviewID != null)
      return "" + this.contentPreviewID;
    else
      return "";
  }

  // TODO: Eg use angular route/snapshot approach - be aware of timing issue
  private getIntParameterByName(name: string) : number {
    let url = window.location.href;
    name = name.replace(/[[]]/g, "\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
    if (results)
      return Number(results[2]);
    else
      return null;
  }

  // TODO: Eg use angular route/snapshot approach - be aware of timing issue
  private getUUIDParameterByName(name: string) : string {
    let url = window.location.href;
    name = name.replace(/[[]]/g, "\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
    if (results) {
      let result = results[2].match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
      if (result != null) {
        return result.toString();
      }
    }
    return null;
  }
}
