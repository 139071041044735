import { Injectable } from "@angular/core";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import Feature from "ol/Feature";
import LayerGroup from "ol/layer/Group";
import { Extent } from "ol/extent";
import { containsExtent } from "ol/extent";
import { Style } from "ol/style";
import MultiPoint from "ol/geom/MultiPoint";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";
import Polygon from "ol/geom/Polygon";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { Observable, Subject } from "rxjs";
import Icon from "ol/style/Icon";
import IconAnchorUnits from "ol/style/IconAnchorUnits";
import GeometryCollection from "ol/geom/GeometryCollection";
import { buffer } from "ol/extent";
import { Collection } from "ol";
import Select from "ol/interaction/Select";
import DrawInteraction from "ol/interaction/Draw";
import GeometryType from "ol/geom/GeometryType";
import { PlandataService } from "./plandata.service";
import { RoutingService } from "../services/routing.service";
import DrawingTool from "../map/drawingtools/drawingtool";
import { VectorStyle } from "../map/vectorstyle/vector-style";
import { ZoneFeature } from "../plandataDefinition/zoneFeature";
import { PlanInfo } from "../plandataDefinition/planInfo";
import { Mutex } from "async-mutex";
import { register } from "ol/proj/proj4";
import { asArray } from "ol/color";
import proj4 from "proj4";
import Geometry from "ol/geom/Geometry";
import { ContentService } from './content.service';
import { LanguageService } from "./language.service";

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from './environment.service';

@Injectable()
export class MapService {
  private _map: any = null;
  private _selectedfeatures: ZoneFeature[] = new Array();
  private _hoveredfeatures: ZoneFeature[] = new Array();
  private _vectorStyle: VectorStyle;
  private _zonesActive = new Array();
  private _drawingTool: DrawingTool;
  private _selectedEditFeatures = new Subject<Feature[]>();
  private _selectedSelectFeatures = new Subject<Feature[]>();
  private _selectedSelectZoneFeatures = new Subject<ZoneFeature[]>();
  private _selectedHoverZoneFeatures = new Subject<ZoneFeature[]>();
  private _geometryCallback: any;
  private _newHearingAnswerCallback: any;
  private _layerConfig: any;
  private _selectEnabled: boolean = true;
  private _mapReady = new Subject<string>();
  private _clickedCoordinates = new Array();
  private _interActions = null;
  private _zoneLayersActive: String[] = new Array();
  private _markerVectorLayer: VectorLayer = null;
  private _editVectorLayer: VectorLayer = null;
  private _hearingAnswerDisplayLayer: VectorLayer = null;
  private _editfeatures: Collection<Feature> = null;
  private _editSource: VectorSource = null;
  private _featureOverlay: VectorLayer = null;
  private _mapIsLoad: boolean = false;
  private _mapSearchTerm: string = "";

  private _customFeatureClickCallback: any;
  private _activeConsultationPlanversionId: string = null;

  private _flyTofitOptions: object = { duration: 1000 };
  private _mainRoutePath: string = "";
  private _searchText: string;
  private _searchMode: number;

  private serviceLayerIsOn = false;

  public changeMarkingsSubject = new Subject<boolean>();
  public showChangeMarkingLayers = {
    "changed" : false,
    "removed" : false,
  }

  public serviceLayersModeSubject = new Subject<boolean>();

  constructor(
    private planDataService: PlandataService,
    private contentService: ContentService,
    private languageService: LanguageService,
    private routeService: RoutingService,
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    this._vectorStyle = new VectorStyle();
    this.planDataService.onLayerConfigEventChanged().subscribe((any) => {
      this._layerConfig = this.planDataService.currentLayerConfig;
      if (this._layerConfig && this._layerConfig.styles) {
        this._vectorStyle.setStyleConfig = this.planDataService.currentLayerConfig.styles;
      }
    });
    proj4.defs(
      "EPSG:25832",
      "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs"
    );
    register(proj4);
  }

  public createInternalVectorLayers() {
    let vectorSource = new VectorSource({
      format: new GeoJSON(),
      features: new Collection(),
    });
    this._markerVectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 1054,
    });

    this._editfeatures = new Collection();
    this._editfeatures.on("add", this.addDrawFeature.bind(this));
    this._editfeatures.on("remove", this.removeDrawFeature.bind(this));
    this._editSource = new VectorSource({
      format: new GeoJSON(),
      features: this._editfeatures,
    });
    this._editVectorLayer = new VectorLayer({
      visible: false,
      source: this._editSource,
      style: this.styleChanges.bind(this, "EDIT"),
      zIndex: 1003,
    });

    this._hearingAnswerDisplayLayer = new VectorLayer({
      source: new VectorSource({
        features: new Collection(),
        format: new GeoJSON(),
      }),
      style: this.styleChanges.bind(this, "selected"),
      zIndex: 1002,
    });

    this._map.addLayer(this._editVectorLayer);
    this._map.addLayer(this._hearingAnswerDisplayLayer);
    this._map.addLayer(this._markerVectorLayer);
  }

  public createInteractions() {
    this._interActions = {};
    this._interActions["select"] = new Select({
      multi: true,
      style: this.styleChanges.bind(this, "selected"),
    });
    this._interActions["select"].setActive(true);

    this._interActions["drawLine"] = new DrawInteraction({
      features: this._editfeatures,
      type: GeometryType.LINE_STRING,
    });
    this._interActions["drawPoint"] = new DrawInteraction({
      features: this._editfeatures,
      type: GeometryType.POINT,
    });
    this._interActions["drawPolygon"] = new DrawInteraction({
      features: this._editfeatures,
      type: GeometryType.POLYGON,
    });

    this._interActions["drawPoint"].setActive(false);
    this._interActions["drawLine"].setActive(false);
    this._interActions["drawPolygon"].setActive(false);
    this._interActions["drawPoint"].on(
      "drawstart",
      this.clearEditFeatures.bind(this)
    );
    this._interActions["drawLine"].on(
      "drawstart",
      this.clearEditFeatures.bind(this)
    );
    this._interActions["drawPolygon"].on(
      "drawstart",
      this.clearEditFeatures.bind(this)
    );
    for (const key in this._interActions) {
      if (key) {
        this._map.addInteraction(this._interActions[key]);
      }
    }

    this._drawingTool = new DrawingTool({
      interActions: this._interActions,
      editVectorLayer: this._editVectorLayer,
      mapComponent: this,
    },this.contentService,this.languageService);
    this._drawingTool.setVisble(true);
    this._drawingTool.setVisble(false);
    this._map.addControl(this._drawingTool);
  }

  set mainRoutePath(path: string) {
    this._mainRoutePath = path;
  }

  onMapReady(): Observable<string> {
    return this._mapReady.asObservable();
  }

  onFeatureSeleted(): Observable<ZoneFeature[]> {
    return this._selectedSelectZoneFeatures.asObservable();
  }

  onFeatureHover(): Observable<ZoneFeature[]> {
    return this._selectedHoverZoneFeatures.asObservable();
  }

  public isMapLoaded(): boolean {
    return this._mapIsLoad;
  }

  public setMapLoaded(mapIsLoad: boolean) {
    this._mapIsLoad = mapIsLoad;
  }

  public setMapIsReady() {
    console.log("map is ready!");
    this._featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: this._map,
      zIndex: 1005,
      style: this.styleChanges.bind(this, "highlight"),
    });
    this._mapIsLoad = true;
    this._mapReady.next();
  }

  set zoneLayersActive(list: String[]) {
    this._zoneLayersActive = list;
  }

  get hearingAnswerDisplayLayer(): VectorLayer {
    return this._hearingAnswerDisplayLayer;
  }

  get zoneLayersActive(): String[] {
    return this._zoneLayersActive;
  }

  set selectEnabled(enable: boolean) {
    this._selectEnabled = enable;
  }

  get selectEnabled(): boolean {
    return this._selectEnabled;
  }

  set interActions(nterActions: any) {
    if (this._interActions == null) {
      this._interActions = nterActions;
    }
  }

  get interActions(): any {
    return this._interActions;
  }

  onHover(event: any) {
    if (!this.selectEnabled || this._featureOverlay == null) {
      return;
    }
    this._hoveredfeatures = [];
    this._selectedHoverZoneFeatures.next(this._hoveredfeatures);
    this._featureOverlay.getSource().clear();
    let me = this;
    let found = false;
    this._map.forEachFeatureAtPixel(event.pixel, function (
      feature: any,
      layer: any
    ) {
      if (layer !== null) {
        let layerProps = layer.getProperties();
        if (layerProps.enableFeatureInfo) {
          if (me.isCreateAnswerMode()) {
            if (
              me._activeConsultationPlanversionId !=
                layerProps.layerInfo.planversionId ||
              layerProps.layerInfo.status !== "consultation"
            )
              return;
          }
          found = true;
          me._featureOverlay.getSource().addFeature(feature);
          let zonefeature = new ZoneFeature();
          zonefeature.refId = feature.get("refID");
          zonefeature.zoneType = feature.get("type").split(",");

          me._hoveredfeatures.push(zonefeature);
          me._selectedHoverZoneFeatures.next(me._hoveredfeatures);
          return;
        }
      }
    });
    this._map.getTargetElement().style.cursor = found ? "pointer" : "";
  }

  private isCreateAnswerMode(): boolean {
    if (
      this._customFeatureClickCallback &&
      this.canCustomFeatureClickBeUsed()
    ) {
      return true;
    }
    return false;
  }

  clickMapHandler(evt: any) {
    if (!this.selectEnabled) {
      return;
    }

    let listFeatures: ZoneFeature[] = new Array();
    let features: any[] = new Array();
    this.interActions["select"].getFeatures().clear();
    this.clearMarkerFeature();
    let me = this;
    this._map.forEachFeatureAtPixel(evt.pixel, function (
      feature: any,
      layer: any
    ) {
      if (layer !== null) {
        let layerProps = layer.getProperties();
        if (layerProps.enableFeatureInfo) {
          let zonefeature = new ZoneFeature();
          zonefeature.refId = feature.get("refID");
          zonefeature.zoneType = feature.get("type").split(",");
          zonefeature.layerName = layerProps.layerInfo.name;
          zonefeature.planInfo = layerProps.layerInfo;
          zonefeature.geometry = JSON.parse(
            new GeoJSON().writeGeometry(feature.getGeometry())
          );
          if (me.isCreateAnswerMode()) {
            if (
              me._activeConsultationPlanversionId !=
                layerProps.layerInfo.planversionId ||
              layerProps.layerInfo.status !== "consultation"
            )
              return;
          }
          listFeatures.push(zonefeature);
          features.push(feature);
        }
      }
    });

    //this._activeConsultationPlanversionId
    for (let i = 0; features && i < features.length; i++) {
      this.interActions["select"].getFeatures().push(features[i]);
    }

    this.clickedCoordinates = evt.coordinate;
    let coordinates = this.clickedCoordinates;

    this.featuresSelected(listFeatures);

    let timeLineIdx = this.planDataService.currentTimeLineIndex;

    if (this.isCreateAnswerMode()) {
      this._customFeatureClickCallback(listFeatures);
      return;
    }

    if (listFeatures.length == 1) {
      this.routeService.changeRoutePath(
        this._mainRoutePath +
          "/zone/s/" +
          listFeatures[0].planInfo.planversionId +
          "/" +
          coordinates[0] +
          "/" +
          coordinates[1] +
          "/" +
          listFeatures[0].layerName +
          "/" +
          listFeatures[0].refId +
          "?timeLineIdx=" +
          timeLineIdx
      );
    } else {
      this.routeService.changeRoutePath(
        this._mainRoutePath +
          "/zone/m/" +
          coordinates[0] +
          "/" +
          coordinates[1] +
          "?timeLineIdx=" +
          timeLineIdx
      );
    }

    this._hearingAnswerDisplayLayer.getSource().clear();
  }

  set clickedCoordinates(coordinates: any[]) {
    if (coordinates && coordinates.length == 2) {
      coordinates[0] = this.roundNumber(coordinates[0]);
      coordinates[1] = this.roundNumber(coordinates[1]);
    }
    this._clickedCoordinates = coordinates;
  }

  get clickedCoordinates(): any[] {
    return this._clickedCoordinates;
  }

  private roundNumber(value: number) {
    return Math.round(value * 100) / 100;
  }

  public setMap(map: any) {
    this._map = map;
  }

  set setDrawingTool(drawingTool: DrawingTool) {
    this._drawingTool = drawingTool;
  }

  get getDrawingTool(): DrawingTool {
    return this._drawingTool;
  }

  get isDrawingToolActive(): boolean {
    return this._drawingTool.isDrawActive();
  }

  set searchMode(num){
    this._searchMode = num;
  }

  get searchMode(){
    return this._searchMode;
  }

  set searchText(text){
    this._searchText = text;
  }

  get searchText(){
    return this._searchText;
  }

  public createGeoJSONLayer(
    layerUrl: string,
    layerInfo: any,
    style: any,
    zIndex: number
  ) {
    let layer = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: layerUrl,
      }),
      style: style,
      zIndex: zIndex,
    });
    layer.set("layerInfo", layerInfo);
    layer.set("enableFeatureInfo", true);
    return layer;
  }

  public setFeatureStyle(
    layerName: string,
    feature_id: string,
    styleType: string,
    featureStyle: string
  ) {
    let layer = this.getLayerByName(layerName, styleType);
    if (layer) {
      layer
        .getSource()
        .forEachFeature(
          this.setStyleForFeatureById.bind(this, feature_id, featureStyle)
        );
    } else {
      console.log("layer not found" + layerName);
    }
  }

  public resetFeatureStyle(
    layerName: string,
    feature_id: string,
    styleType: string
  ) {
    let layer = this.getLayerByName(layerName, styleType);
    if (layer) {
      layer.getSource().forEachFeature(function (feature: any) {
        if (feature.get("refID") === feature_id) {
          feature.setStyle(null);
        }
      });
    } else {
      console.log("layer not found" + layerName);
    }
  }

  public getMapCenter(): any {
    let coordinates = this._map.getView().getCenter();
    if (coordinates && coordinates.length == 2) {
      coordinates[0] = this.roundNumber(coordinates[0]);
      coordinates[1] = this.roundNumber(coordinates[1]);
    }
    return coordinates;
  }

  public getMapZoomLevel(): any {
    let zoomLevel = this._map.getView().getZoom();
    return this.roundNumber(zoomLevel);
  }

  public highlightZoneType(zoneType: string, enableHighlight: boolean) {
    // Find all layers withlayername and with style
    let me = this;
    this._map.getLayers().forEach(function (layer: any) {
      if (layer.get("layerInfo")) {
        if (zoneType == "zones" || zoneType == "changesSuggestions" || zoneType == "changeMarking") {
          let zones = [];
          for (let n = 0; n < me._layerConfig.layers.length; n++) {
            for (
              let i = 0;
              (me._layerConfig.layers[n].type == zoneType) &&
              me._layerConfig.layers[n].subtypes &&
              i < me._layerConfig.layers[n].subtypes.length;
              i++
            ) {
              zones.push(me._layerConfig.layers[n].subtypes[i].type);
            }
          }

          if (enableHighlight) {
            for (let i = 0; i < zones.length; i++) {
              if (me._zonesActive.indexOf(zones[i]) == -1) {
                me._zonesActive.push(zones[i]);
              }
            }
          } else {
            for (let i = 0; i < zones.length; i++) {
              if (me._zonesActive.indexOf(zones[i]) != -1) {
                me._zonesActive.splice(me._zonesActive.indexOf(zones[i]), 1);
              }
            }
          }
        } else {
          if (enableHighlight) {
            if (me._zonesActive.indexOf(zoneType) == -1) {
              me._zonesActive.push(zoneType);
            }
          } else {
            if (me._zonesActive.indexOf(zoneType) != -1) {
              me._zonesActive.splice(me._zonesActive.indexOf(zoneType), 1);
            }
          }
        }
        layer
          .getSource()
          .forEachFeature(
            me.setStyleForLayerhighlightFeatures.bind(
              me,
              layer,
              zoneType,
              layer.get("layerInfo")
            )
          );
      }
    });
  }

  private setStyleForLayerhighlightFeatures(
    layer: any,
    zoneType: string,
    layerInfo: any,
    feature: Feature
  ) {
    let styleType = "";
    let featyre_zoneTyoe = new Array();

    if (feature.get("type")) {
      let list = feature.get("type").split(",");
      for (var i = 0; i < list.length; i++) {
        featyre_zoneTyoe.push(list[i].trim());
      }
    }

    let isActive = false;
    for (var i = 0; i < featyre_zoneTyoe.length; i++) {
      if (this._zonesActive.indexOf(featyre_zoneTyoe[i]) !== -1) {
        isActive = true;
        break;
      }
    }

    if (
      layerInfo.styleType == "consultation" ||
      layerInfo.styleType == "consultation_hide"
    ) {
      if (this._zonesActive.indexOf("H") > -1) {
        isActive = true;
      } else {
        isActive = false;
      }
    }

    if (
      layerInfo.styleType == "consultationend" ||
      layerInfo.styleType == "consultationend_hide"
    ) {
      if (this._zonesActive.indexOf("He") > -1) {
        isActive = true;
      } else {
        isActive = false;
      }
    }

    if (layerInfo.styleType == "changed") {
      if (this._zonesActive.indexOf("changed") > -1) {
        isActive = true;
      } else {
        isActive = false;
      }
    }

    if (layerInfo.styleType == "removed") {
      if (this._zonesActive.indexOf("removed") > -1) {
        isActive = true;
      } else {
        isActive = false;
      }
    }

    if (isActive) {
      styleType = layer.get("layerInfo").styleType;
    } else {
      styleType = layer.get("layerInfo").styleType + "_hide";
    }
    if (this.isFeatureSelected(feature)) {
      styleType = "selected";
    }

    feature.setStyle(this.styleChanges.bind(this, styleType));
  }

  public resetAllHighlightZoneTyoe() {
    // Find all layers withlayername and with style
    this._zoneLayersActive = new Array();

    this._zonesActive = new Array();
    let me = this;
    this._map.getLayers().forEach(function (layer: any) {
      if (layer.get("layerInfo")) {
        let styleType = layer.get("layerInfo").styleType;
        layer
          .getSource()
          .forEachFeature(me.setStyleForLayerFeatures.bind(me, styleType));
      }
    });
  }

  public styleChanges(
    styleType: string,
    feature: Feature,
    resolution: any
  ): any {
    let style = this._vectorStyle.getStyleByType(styleType, feature, resolution);

    if (this.serviceLayerIsOn) {
      let newFillColor = asArray(style.getFill().getColor().toString()).slice();
      newFillColor[3] *= 0.75;
      style.getFill().setColor(newFillColor);

      let newStrokeColor = asArray(style.getStroke().getColor().toString()).slice();
      newStrokeColor[3] *= 0.45;
      style.getStroke().setColor(newStrokeColor);
    }

    return style;
  }

  public getFeaturesByCooordinat(coordinates: number[]): ZoneFeature[] {
    let listFeatures: ZoneFeature[] = new Array();
    this._map.getLayers().forEach(function (layer: any) {
      if (layer.get("layerInfo")) {
        layer.getSource().forEachFeature(function (feature: any) {
          let polygonGeometry = feature.getGeometry();
          if (polygonGeometry.intersectsCoordinate(coordinates)) {
            let zonefeature = new ZoneFeature();
            zonefeature.refId = feature.get("refID");
            zonefeature.zoneType = feature.get("type").split(",");
            let layerProps = layer.getProperties();
            zonefeature.layerName = layerProps.layerInfo.name;
            zonefeature.planInfo = layerProps.layerInfo;
            listFeatures.push(zonefeature);
          }
        });
      }
    });
    return listFeatures;
  }

  public setFeature(coordinates: number[]) {
    this.interActions["select"].getFeatures().clear();
    let listFeatures: ZoneFeature[] = new Array();
    let features: any[] = new Array();
    this._map.getLayers().forEach(function (layer: any) {
      if (layer.get("layerInfo") && layer.getProperties().layerInfo.name != "changed") {
        layer.getSource().forEachFeature(function (feature: any) {
          let polygonGeometry = feature.getGeometry();
          if (polygonGeometry.intersectsCoordinate(coordinates)) {
            let zonefeature = new ZoneFeature();
            zonefeature.refId = feature.get("refID");
            zonefeature.zoneType = feature.get("type").split(",");
            let layerProps = layer.getProperties();
            zonefeature.layerName = layerProps.layerInfo.name;
            zonefeature.planInfo = layerProps.layerInfo;
            listFeatures.push(zonefeature);
            features.push(feature);
          }
        });
      }
    });
    for (let n = 0; n < features.length; n++) {
      this.addToSelected(features[n]);
    }

    this.featuresSelected(listFeatures);
  }

  private isFeatureSelected(feature: Feature) {
    let features = this._selectedfeatures;
    for (let i = 0; features && i < features.length; i++) {
      if (
        feature.get("refID") &&
        features[i].refId &&
        feature.get("refID") == features[i].refId
      ) {
        return true;
      }
    }
    return false;
  }

  public getSelectedFeature(): ZoneFeature[] {
    return this._selectedfeatures;
  }

  public removeSelectedFeatures(zoneFeature: ZoneFeature): void {
    this.interActions["select"].getFeatures().clear();
    let me = this;
    this._map.getLayers().forEach(function (layer: any) {
      if (
        layer.get("layerInfo") &&
        layer.get("layerInfo").planversionId ==
          zoneFeature.planInfo.planversionId &&
        layer.get("layerInfo").name == zoneFeature.layerName
      ) {
        layer.getSource().forEachFeature(function (feature: any) {
          if (feature.get("refID") == zoneFeature.refId) {
            let zonefeature = new ZoneFeature();
            zonefeature.refId = feature.get("refID");
            if (feature.get("type")) {
              zonefeature.zoneType = feature.get("type").split(",");
            } else {
              zonefeature.zoneType = "";
            }
            let layerProps = layer.getProperties();
            zonefeature.layerName = layerProps.layerInfo.name;
            zonefeature.planInfo = layerProps.layerInfo;
            me.interActions["select"].getFeatures().push(feature);
            me.featuresSelected([zonefeature]);
            return;
          }
        });
      }
    });
  }

  public featuresSelected(selectedFeatures: ZoneFeature[]) {
    this._selectedSelectZoneFeatures.next(selectedFeatures);
    this._selectedfeatures = selectedFeatures;
  }

  public getZoneFeaturesForConsultation(
    planversionId: string,
    zoomTo: boolean
  ): ZoneFeature[] {
    let zones = new Array<ZoneFeature>();
    let features = new Array<Feature>();
    this._map.getLayers().forEach(function (layer: any) {
      if (
        layer.get("layerInfo") &&
        layer.get("layerInfo").status == "consultation" &&
        layer.get("layerInfo").planversionId == planversionId
      ) {
        let layerProps = layer.getProperties();
        layer.getSource().forEachFeature(function (feature: any) {
          let zonefeature = new ZoneFeature();
          zonefeature.planInfo = new PlanInfo();
          zonefeature.planInfo.planversionId = planversionId;
          zonefeature.planInfo.status = layerProps.layerInfo.status;
          zonefeature.refId = feature.get("refID");
          if (feature.get("type")) {
            zonefeature.zoneType = feature.get("type").split(",");
          }
          zonefeature.planInfo.styleType = layerProps.layerInfo.styleType;
          zonefeature.layerName = layerProps.layerInfo.name;
          zonefeature.geometry = JSON.parse(
            new GeoJSON().writeGeometry(feature.getGeometry())
          );
          zones.push(zonefeature);
          features.push(feature);
        });
      }
    });
    if (zoomTo) {
      this.flyToFeatures(features, 10000);
    }
    return zones;
  }

  public getZoneFeaturesAllForPlanversion(
    planversionId: string
  ): ZoneFeature[] {
    let zones = new Array<ZoneFeature>();
    this._map.getLayers().forEach(function (layer: any) {
      if (
        layer.get("layerInfo") &&
        layer.get("layerInfo").planversionId == planversionId &&
        layer.get("layerInfo").name == "zones"
      ) {
        let layerProps = layer.getProperties();
        layer.getSource().forEachFeature(function (feature: any) {
          let zonefeature = new ZoneFeature();
          zonefeature.planInfo = new PlanInfo();
          zonefeature.planInfo.planversionId = planversionId;
          zonefeature.planInfo.status = layerProps.layerInfo.status;
          zonefeature.refId = feature.get("refID");
          if (feature.get("type")) {
            zonefeature.zoneType = feature.get("type").split(",");
          } else {
            zonefeature.zoneType = "";
          }
          zonefeature.layerName = layerProps.layerInfo.name;
          zonefeature.geometry = JSON.parse(
            new GeoJSON().writeGeometry(feature.getGeometry())
          );
          zones.push(zonefeature);
        });
      }
    });
    return zones;
  }

  public getZoneFeaturesAllForByLayer(
    layerName: string,
    planversionId: string
  ): ZoneFeature[] {
    let zones = new Array<ZoneFeature>();
    this._map.getLayers().forEach(function (layer: any) {
      if (
        layer.get("layerInfo") &&
        layer.get("layerInfo").planversionId == planversionId &&
        layer.get("layerInfo").name == layerName
      ) {
        let layerProps = layer.getProperties();
        layer.getSource().forEachFeature(function (feature: any) {
          let zonefeature = new ZoneFeature();
          zonefeature.planInfo = new PlanInfo();
          zonefeature.planInfo.planversionId = planversionId;
          zonefeature.planInfo.status = layerProps.layerInfo.status;
          zonefeature.refId = feature.get("refID");
          zonefeature.zoneType = feature.get("type").split(",");
          zonefeature.layerName = layerProps.layerInfo.name;
          zonefeature.geometry = JSON.parse(
            new GeoJSON().writeGeometry(feature.getGeometry())
          );
          zones.push(zonefeature);
        });
      }
    });
    return zones;
  }

  public addIconMarker(coordinate: number[]) {
    if (coordinate.length > 0) {
      this.clearMarkerFeature();
      let iconFeature = new Feature({
        geometry: new Point(coordinate),
      });
      let iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 35],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.PIXELS,
          src: "./assets/marker_ikon.png",
        }),
      });
      iconFeature.setStyle(iconStyle);
      this._markerVectorLayer.getSource().addFeature(iconFeature);
    }
  }

  clearMarkerFeature(): void {
    if (this._markerVectorLayer.getSource().getFeatures().length > 0) {
      this._markerVectorLayer.getSource().clear();
    }
  }

  private addToSelected(feature: any) {
    let features = this.interActions["select"].getFeatures();
    let found = false;
    for (let i = 0; features && i < features.getLength(); i++) {
      if (
        feature &&
        feature.get("refID") == features.getArray()[i].get("refID")
      ) {
        found = true;
        break;
      }
    }
    if (!found) {
      this.featuresSelected(this._interActions["select"].getFeatures());
      this._interActions["select"].getFeatures().push(feature);
    }
  }

  public findZoneFeatures(layerName: string, refID: string): ZoneFeature[] {
    let zonefeature: ZoneFeature = null;
    let zones = new Array<ZoneFeature>();
    this._map.getLayers().forEach(function (layer: any) {
      if (layer.get("layerInfo") && layer.get("layerInfo").name.toLowerCase() == layerName.toLowerCase()) {
        let layerProps = layer.getProperties();
        layer.getSource().forEachFeature(function (feature: any) {
          if (feature && feature.get("refID") == refID) {
            zonefeature = new ZoneFeature();
            zonefeature.planInfo = new PlanInfo();
            zonefeature.planInfo.planversionId = layer.get(
              "layerInfo"
            ).planversionId;
            zonefeature.planInfo.status = layerProps.layerInfo.status;
            zonefeature.refId = feature.get("refID");
            zonefeature.zoneType = feature.get("type").split(",");
            zonefeature.layerName = layerProps.layerInfo.name;
            zonefeature.planInfo = layerProps.layerInfo;
            zonefeature.geometry = JSON.parse(
              new GeoJSON().writeGeometry(feature.getGeometry())
            );
            zones.push(zonefeature);
          }
        });
      }
    });
    return zones;
  }

  public findZoneFeature(
    planversionId: string,
    layerName: string,
    refID: string
  ): ZoneFeature {
    let zonefeature: ZoneFeature = null;
    let foundFeature: any = null;
    this._map.getLayers().forEach(function (layer: any) {
      if (
        layer.get("layerInfo") &&
        layer.get("layerInfo").planversionId == planversionId &&
        layer.get("layerInfo").name.toLowerCase() == layerName.toLowerCase()
      ) {
        let layerProps = layer.getProperties();
        layer.getSource().forEachFeature(function (feature: any) {
          if (feature && feature.get("refID") == refID) {
            foundFeature = feature;
            zonefeature = new ZoneFeature();
            zonefeature.planInfo = new PlanInfo();
            zonefeature.planInfo.planversionId = planversionId;
            zonefeature.planInfo.status = layerProps.layerInfo.status;
            zonefeature.refId = feature.get("refID");
            zonefeature.zoneType = feature.get("type").split(",");
            zonefeature.layerName = layerProps.layerInfo.name;
            zonefeature.planInfo = layerProps.layerInfo;
            zonefeature.geometry = JSON.parse(
              new GeoJSON().writeGeometry(feature.getGeometry())
            );
            return zonefeature;
          }
        });
      }
    });
    if (foundFeature) {
      this.addToSelected(foundFeature);
    }

    return zonefeature;
  }

  private setStyleForFeatureById(
    feature_id: number,
    featureStyle: string,
    feature: Feature
  ) {
    if (feature.get("refID") === feature_id) {
      feature.setStyle(this.styleChanges.bind(this, featureStyle));
    }
  }

  private setStyleForLayerFeatures(styleName: string, feature: Feature) {
    feature.setStyle(this.styleChanges.bind(this, styleName));
  }

  private getLayerByName(layerName: string, styleType: string) {
    let chosenLayer;
    this._map.getLayers().forEach(function (layer: any) {
      if (
        layer.get("layerInfo") &&
        layer.get("layerInfo").name === layerName &&
        layer.get("layerInfo").styleType === styleType
      ) {
        chosenLayer = layer;
      }
    });
    return chosenLayer;
  }

  setCustomFeatureClickCallback(cb: any, planversionId: string) {
    this._activeConsultationPlanversionId = planversionId;
    this._customFeatureClickCallback = cb;
  }

  canCustomFeatureClickBeUsed() {
    const allowedPage: RegExp = /^\/da\/page\/consultation\/answer\/[a-z0-9\-]+\/create/g;
    let checkedCurrent = allowedPage.test(this.routeService.currentPath);
    return checkedCurrent;
  }

  setGeometryDrawingCallback(cb: any): any {
    this._geometryCallback = cb;
  }

  onEditFeaturesSelected(): Observable<Feature[]> {
    return this._selectedEditFeatures.asObservable();
  }

  onSelectFeaturesSelected(): Observable<Feature[]> {
    return this._selectedSelectFeatures.asObservable();
  }

  clearHoveredFeatures(): void {
    if (this._featureOverlay != null) {
      this._featureOverlay.getSource().clear();
    }
  }

  clearSelectedFeatures(type: string) {
    switch (type.toUpperCase()) {
      case "EDIT":
        this.clearEditFeatures();
        break;
      case "SELECT":
        this.interActions["select"].getFeatures().clear();
        this._selectedSelectFeatures.next();
        break;
      case "CONSULTATIONRESPONSEZONE":
        this.hearingAnswerDisplayLayer.getSource().clear();
        this._newHearingAnswerCallback(null);
        break;
      case "MAPSEARCH":
        this.hearingAnswerDisplayLayer.getSource().clear();
        this.interActions["select"].getFeatures().clear();
        this._selectedSelectFeatures.next();
        this._newHearingAnswerCallback(null);
        this._mapSearchTerm = "";
        break;
      case "ALL":
        this.hearingAnswerDisplayLayer.getSource().clear();
        this.interActions["select"].getFeatures().clear();
        this.clearEditFeatures();
        this._selectedEditFeatures.next();
        this._selectedSelectFeatures.next();
        this._newHearingAnswerCallback(null);
        this.clearMarkerFeature();
        this._mapSearchTerm = "";
        break;
      default:
        console.log("UNKNOWN clearSelectedFeatures type");
        break;
    }
  }

  activeDrawingTool(geometriTypes: string[]): void {
    this.clearSelectedFeatures("SELECT");
    this._drawingTool.setTypes(geometriTypes);
    this._drawingTool.changeDrawingType([]);
    this._drawingTool.setVisble(true);
  }

  deactivateDrawingTool(clearSelectedFeature: boolean = true): void {
    if (clearSelectedFeature) {
      this.clearSelectedFeatures("EDIT");
    }
    this._drawingTool.setVisble(false);
  }

  setDrawingToolsVisible(isVisible: boolean) {
    this._drawingTool.setVisble(isVisible);
  }

  updateEditedFeatures(features: Feature[]): void {
    const type =
      features && features.length > 0
        ? (features[0].getGeometry().getType() as string)
        : "UNDEFINDED";
    switch (type.toUpperCase()) {
      case "POINT":
      case "MULTIPOINT":
        this.createMultiPointGeojson(features);
        break;
      case "LINESTRING":
      case "MULTILINESTRING":
        this.createMultiLineStringGeojson(features);
        break;
      case "POLYGON":
      case "MULTIPOLYGON":
        this.createMultiPolygonGeojson(features);
        break;
      default:
        this._geometryCallback("{}");
        break;
    }
  }

  private createMultiPointGeojson(features: Feature[]): void {
    const mPoint = new MultiPoint([]);
    for (const index in features) {
      if (index) {
        const type = features[index].getGeometry().getType() as string;
        if (type.toUpperCase().indexOf("POINT") > -1) {
          if (type.toUpperCase().indexOf("MULTIPOINT") > -1) {
            const points = (<MultiPoint>(
              features[index].getGeometry()
            )).getPoints();
            for (const indexPoint in points) {
              if (indexPoint) {
                mPoint.appendPoint(points[indexPoint]);
              }
            }
          } else {
            mPoint.appendPoint(<Point>features[index].getGeometry());
          }
        }
      }
    }
    const geojson =
      mPoint.getPoints().length > 0
        ? new GeoJSON().writeGeometry(mPoint)
        : "{}";
    this._geometryCallback(geojson);
  }

  private createMultiLineStringGeojson(features: Feature[]): void {
    const mLine = new MultiLineString([]);
    for (const index in features) {
      if (index) {
        const type = features[index].getGeometry().getType() as string;
        if (type.toUpperCase().indexOf("LINE") > -1) {
          if (type.toUpperCase().indexOf("MULTILINE") > -1) {
            const lines = (<MultiLineString>(
              features[index].getGeometry()
            )).getLineStrings();
            for (const indexLine in lines) {
              if (indexLine) {
                mLine.appendLineString(lines[indexLine]);
              }
            }
          } else {
            mLine.appendLineString(<LineString>features[index].getGeometry());
          }
        }
      }
    }
    const geojson =
      mLine.getLineStrings().length > 0
        ? new GeoJSON().writeGeometry(mLine)
        : "{}";
    this._geometryCallback(geojson);
  }

  private createMultiPolygonGeojson(features: Feature[]): void {
    const mPolygon = new MultiPolygon([]);
    for (const index in features) {
      if (index) {
        const type = features[index].getGeometry().getType() as string;
        if (type.toUpperCase().indexOf("POLYGON") > -1) {
          if (type.toUpperCase().indexOf("MULTIPOLYGON") > -1) {
            const polygons = (<MultiPolygon>(
              features[index].getGeometry()
            )).getPolygons();
            for (const indexPolygon in polygons) {
              if (index) {
                mPolygon.appendPolygon(polygons[index]);
              }
            }
          } else {
            mPolygon.appendPolygon(<Polygon>features[index].getGeometry());
          }
        }
      }
    }
    const geojson =
      mPolygon.getPolygons().length > 0
        ? new GeoJSON().writeGeometry(mPolygon)
        : "{}";
    this._geometryCallback(geojson);
  }

  async showHearingAnswerGeometry(geojson: any) {
    if(!geojson || JSON.stringify(geojson) == "{}" || JSON.stringify(geojson) == "[]"){
      return;
    }
    if(geojson.length > 0){
      for(let geo of geojson){
        const feature = new Feature({
          geometry: new GeoJSON().readGeometry(geo),
        });
        this.hearingAnswerDisplayLayer.getSource().clear();
        let extent = feature.getGeometry().getExtent();
        let bufferExtented = buffer(extent, 2000); // Buffer 1500 meter
        const mutex = new Mutex();
        const release = await mutex.acquire();
        this._map
          .getView()
          .fit(bufferExtented, { size: this._map.getSize(), callback: release() });
        this._newHearingAnswerCallback(feature);
      }
    }else{
      const feature = new Feature({
        geometry: new GeoJSON().readGeometry(geojson),
      });
      this.hearingAnswerDisplayLayer.getSource().clear();
      let extent = feature.getGeometry().getExtent();
      let bufferExtented = buffer(extent, 2000); // Buffer 1500 meter
      const mutex = new Mutex();
      const release = await mutex.acquire();
      this._map
        .getView()
        .fit(bufferExtented, { size: this._map.getSize(), callback: release() });
      this._newHearingAnswerCallback(feature);
    }

  }

  showSelectedHearingGeometry(geojson: JSON) {
    const feature = new Feature({
      geometry: new GeoJSON().readGeometry(geojson),
    });
    this._newHearingAnswerCallback(feature);
  }

  private addDrawFeature(event: any): void {
    if (this.isDrawingToolActive) {
      const features = this._editSource.getFeatures() as Feature[];
      this.updateEditedFeatures(features);
    }
  }

  private removeDrawFeature(event: any): void {
    if (this.isDrawingToolActive) {
      const features = this._editSource.getFeatures() as Feature[];
      this.updateEditedFeatures(features);
    }
  }

  public clearEditFeatures(): void {
    this._hearingAnswerDisplayLayer.getSource().clear();
    if (this._editVectorLayer.getSource().getFeatures().length > 0) {
      this._editVectorLayer.getSource().clear();
    }
  }

  private mergeGeometries(features: Feature[]): Feature {
    if (features.length == 1) {
      return features[0];
    }
    let listGemetries = [];
    for (let i = 0; i < features.length; i++) {
      listGemetries.push(features[i].getGeometry());
    }
    return new Feature({
      geometry: new GeometryCollection(listGemetries),
      name: "zoom area",
    });
  }

  private findFeature(refID: string, zoneType: string): Feature {
    let featureFound: Feature = null;

    this._map.getLayers().forEach(function (layer: any) {
      if (layer instanceof LayerGroup) {
      } else {
        if (layer.get("layerInfo")) {
          layer.getSource().forEachFeature(function (feature: any) {
            if (
              feature.get("refID") == refID &&
              feature.get("type") == zoneType
            ) {
              featureFound = feature;
              return;
            }
          });
        }
      }
      if (featureFound) {
        return;
      }
    });

    return featureFound;
  }

  private findFeatureByZoneTypes(refID: string, zoneTypes: string[]): Feature {
    let featureFound: Feature = null;

    this._map.getLayers().forEach(function (layer: any) {
      if (layer instanceof LayerGroup) {
      } else {
        if (layer.get("layerInfo")) {
          layer.getSource().forEachFeature(function (feature: any) {
            if (
              feature.get("refID") == refID &&
              zoneTypes.indexOf(feature.get("type")) >= 0
            ) {
              featureFound = feature;
              return;
            }
          });
        }
      }
      if (featureFound) {
        return;
      }
    });

    return featureFound;
  }

  public flyToFeaturesByZoneTypes(zoneFeatures: ZoneFeature[]) {
    let features = [];
    for (let i = 0; i < zoneFeatures.length; i++) {
      let feature = this.findFeature(
        zoneFeatures[i].refId,
        zoneFeatures[i].zoneType
      );
      if (feature) {
        features.push(feature);
      }
    }
    // console.log(JSON.stringify(features));
    this.flyToFeatures(features);
  }

  public flyToFeaturesByZoneTypesArr(zone:any[]) {
    let features = [];
    for (let i = 0; i < zone.length; i++) {
      let feature = this.findFeatureByZoneTypes(
        zone[i].refId,
        zone[i].type
      );
      if (feature) {
        features.push(feature);
      }
    }
    // console.log(JSON.stringify(features));
    this.flyToFeatures(features);
  }

  public flyTofeatureByZoneType(refID: string, zoneType: string) {
    let feature = this.findFeature(refID, zoneType);
    if (feature) {
      this.flyToFeature(feature);
    } else {
      console.log("Feature not found by: " + refID + " : " + zoneType);
    }
  }

  private flytoCallback(bufferExtented: Extent) {
    this._map.getView().fit(bufferExtented, this._flyTofitOptions);
  }

  public flyToFeatures(features: Feature[], bufferSize: number = 2000) {
    //console.log("Number: "+features.length);
    if (features.length > 0) {
      let mergedFeature = this.mergeGeometries(features);
      let extent = mergedFeature.getGeometry().getExtent();
      let bufferExtented = buffer(extent, bufferSize);
      if (containsExtent(this._map.getView().calculateExtent(), extent)) {
        this.flytoCallback(bufferExtented);
      } else {
        this._map
          .getView()
          .animate(
            { zoom: this._map.getView().getZoom() - 1, duration: 500 },
            this.flytoCallback.bind(this, bufferExtented)
          );
      }
    }
  }

  public flyToFeature(feature: Feature) {
    if (feature) {
      let extent = feature.getGeometry().getExtent();
      let bufferExtented = buffer(extent, 2000);
      if (containsExtent(this._map.getView().calculateExtent(), extent)) {
        this.flytoCallback(bufferExtented);
      } else {
        this._map
          .getView()
          .animate(
            { zoom: this._map.getView().getZoom() - 1, duration: 500 },
            this.flytoCallback.bind(this, bufferExtented)
          );
      }
    }
  }

  setNewHearingAnswerCallback(cb: any): any {
    this._newHearingAnswerCallback = cb;
  }

  public getMap(): any {
    return this._map;
  }

  public transformatToFromProjection(
    geometry: Geometry,
    projSoruce: string,
    projDestination: string
  ): Geometry {
    return geometry.transform("EPSG:25832", "EPSG:4326");
  }

  public setTerm(term: string) {
    this._mapSearchTerm = term;
  }

  public getTerm() {
    return this._mapSearchTerm;
  }

  callByProxy(url: string): Observable<any> {
    const parameters = {};
    parameters['url'] = url;
    const params = new HttpParams({
      // fromObject: parameters
    });
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'text/plain');
    headers.append('Accept', 'text/plain');
    return this.http.get(url , {params: params, headers: headers, responseType: 'text', observe: 'response'  });
  }

  public setChangeMarkingLayersVisibility(name: string, isVisible : boolean) {

    this.showChangeMarkingLayers[name] = isVisible;

    if (this._map) {
      this._map.getLayers().forEach(function (layer: any) {
        const layerInfo = layer.get("layerInfo");
        if (layerInfo && layerInfo.name == name) {
          layer.setVisible(isVisible);
        }
      });

      this.changeMarkingsSubject.next(isVisible);
    }
  }

  public setServiceLayerIsOn(isOn: boolean) {
    if (this.serviceLayerIsOn != isOn) {
      this.serviceLayersModeSubject.next(isOn);
    }
    this.serviceLayerIsOn = isOn;
  }

}
