import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {EnvironmentService} from "./environment.service";
import {Subject, Observable, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import { RouteInfo }  from '../models/routeinfo';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class RoutingService {
  
  private _routeChanged = new Subject<RouteInfo>();
  private _routeInfo: RouteInfo;
  constructor(private router: Router) {

  }

  onRouteEventChanged(): Observable<RouteInfo> {
    return this._routeChanged.asObservable();
  }

  changeRoute(routeelement: string, path: string, routedata: any) {
    this._routeInfo = new RouteInfo();
    this._routeInfo.routeelement = routeelement
    this._routeInfo.routepath = path;
    this._routeInfo.routedata = routedata;  
    this._routeChanged.next(this.routeInfo);
  }

  get routeInfo() : RouteInfo{
    return this._routeInfo;
  }

  changeRoutePath(path: string) {
    //console.log(path);
    this.router.navigateByUrl(path);
  }

  get currentPath() : string {
    return this.router.url;
  }



}
