import { Component, OnInit } from "@angular/core";
import { ModalService } from "./_modal";
import { CookieService } from "ngx-cookie-service";
import { ContentService } from "./services/content.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PreviewService } from "./services/preview.service";
import { PrintService } from "./services/print.service";
import { LanguageService } from "./services/language.service";
import { transformWithOptions } from "ol/format/Feature";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "public-ui";
  cookiebarIsShown: boolean = true;
  cookieConsent: string;
  acceptCookiesValue: string;
  content: any;
  prefixedDa: any;
  popUpText: any;
  popUpLogo: any;
  popUpButton: any;
  path: string;
  collapsed: boolean = false;
  activePath: string;
  currentLocationHost: any;
  currentLang: string = null;
foods:any = [
    { value: 'steak-0', viewValue: 'Steak', img: 'https://www.akberiqbal.com/favicon-32x32.png' },
    { value: 'pizza-1', viewValue: 'Pizza', img: 'https://www.akberiqbal.com/favicon-16x16.png' },
    { value: 'tacos-2', viewValue: 'Tacos', img: 'https://www.akberiqbal.com/favicon-96x96.png' }
  ];
  constructor(
    private languageService: LanguageService,
    private modalService: ModalService,
    private cookieService: CookieService,
    private contentService: ContentService,
    private previewService: PreviewService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnInit() {
    /*if (this.cookieService.get("consentConditions") !== "Consent given") {
      setTimeout(() => {
        this.openModal("custom-modal-1");
      }, 1000);
    }
    if (this.cookieService.get("acceptCookies") === "Cookies is accepted") {
      this.cookiebarIsShown = true;*/
    this.router.events.subscribe((url: any) => {
      this.path = this.router.url.substring(1);
      this.setActivetab();
    });

    if (!this.contentService.currentContent) {
      this.contentService.onContentEventLoaded().subscribe((any) => {
        this.setup();
      });

      //this.contentService.loadPublichedContent();
    } else {
      this.setup();
    }

    if (!this.previewService.isPrintPreview()) {
      setTimeout(()=>{
        let isLoaded = this.activatedRoute.snapshot.queryParams.isLoaded;
        if(isLoaded == 'true'){
          this.router.navigate([], {
            queryParams: {
              'isLoaded': null,
            },
            queryParamsHandling: 'merge'
          })
        }else if(isLoaded == 'false'){
          this.router.navigate([], {
            queryParams: {
              'isLoaded': null,
            },
            queryParamsHandling: 'merge'
          })
          setTimeout(() => {
            this.openModal("custom-modal-1");
          }, 1000);
        }else{
          setTimeout(() => {
            let currentUrlParts = document.URL.split('/');
            let referrerUrlParts = document.referrer.split('/');
            let skipPopup = false;
            if(currentUrlParts.length >= 3 && referrerUrlParts.length >= 3){
              let isSameHost = (currentUrlParts[2] == referrerUrlParts[2]);
              let isSameLanguage = (currentUrlParts[3] == referrerUrlParts[3]);
              skipPopup = (isSameHost && isSameLanguage);
            }
            if(!skipPopup){
              setTimeout(() => {
                this.openModal("custom-modal-1");
              }, 1000);
            }
          },500)
        }
      })
    }

    /*
        this.contentService.getPublishedContent().subscribe(data => {
          this.contentService.currentContent = data;
          this.content = this.contentService.getContent();
          //console.log(this.content);
          this.content.prefixes.forEach(element => {
            if (element.prefix === "da/topmenu") {
              this.prefixedDa = element.elements;
            }
          });
        });*/
  }

  setup() {
    this.content = this.contentService.getContent();
    this.content.prefixes.forEach((element) => {
      /* console.log(JSON.stringify(element.prefix)) */ if (
        element.prefix ===
        this.currentLang + "/topmenu"
      ) {
        this.prefixedDa = element.elements;
      }
      if (element.prefix == this.currentLang) {
        element.elements.forEach((elementItem) => {
          if (elementItem.shortName == "load_popup_text") {
            this.popUpText = elementItem;
          } else if (elementItem.shortName == "load_popup_logo") {
            this.popUpLogo = elementItem;
          } else if (elementItem.shortName == "load_popup_button") {
            this.popUpButton = elementItem;
          }
        });
      }
    });
    if (this.previewService.isPrintPreview()) {
      this.navigate({
        link1: "/" + this.currentLang + "/page/consultation/print",
      });
    }
    this.setActivetab();
  }

  setActivetab() {
    let pathDetails: any = window.location.pathname.split("/");
    if (this.path.includes("page")) {
      this.activePath = "/" + this.currentLang + "/";
    } else {
      this.activePath = "/" + this.currentLang + "/" + pathDetails[2];
    }
  }

  navigate(item) {
    this.router.navigate([item.link1]);
    setTimeout(() => {
      this.path = this.router.url.substring(1);
      this.setActivetab();
    }, 0);
  }

  consentModal() {
    //this.cookieService.set("consentConditions", "Consent given", 30, "/");
    //this.cookieConsent = this.cookieService.get("Test");
    this.closeModal("custom-modal-1");
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  acceptCookies() {
    this.cookieService.set("acceptCookies", "Cookies is accepted", 30, "/");
    this.acceptCookiesValue = this.cookieService.get("acceptCookies");
    this.cookiebarIsShown = false;
  }

  isPreviewMode(): boolean {
    return this.previewService.isPreviewMode();
  }

  isPrintPreview(): boolean {
    return this.previewService.isPrintPreview();
  }

  getPreviewModeText(): string {
    return this.previewService.getPreviewModeText();
  }

  getAltText(prefix, shortName) {
    return this.contentService.getAltTextByShortName(prefix, shortName);
  }

  getcurrentRoutePath() {
    //  this.currentLocationHost = window.location.host;
    if (this.router.url.includes("/" + this.currentLang + "/page/")) {
      this.router.navigate(["/" + this.currentLang],{queryParams: {isLoaded:true}});
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      this.router.navigate(["/" + this.currentLang]);
    }
  }
}
