import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ModalModule as SecondModal } from "./_modal";
import { ModalModule } from 'ngx-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgbDateParserFormatter, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { RouteReuseStrategy } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { AppComponent } from "./app.component";
import { MapComponent } from "./map/map.component";
import { SearchComponent } from "./search/search.component";
import { AppRoutingModule } from "./app-routing.module";
import { NewsComponent } from "./news/news.component";

import { EnvironmentService } from "./services/environment.service";
import { PlandataService } from "./services/plandata.service";
import { PreviewService } from "./services/preview.service";
import { MapService } from "./services/map.service";
import { RoutingService } from "../app/services/routing.service";
import { ConsultationdataService } from "../app/services/consultationdata.service";
import { CookieService } from "ngx-cookie-service";
import { DateService} from "./services/date.service";
import { ShareLinkComponent } from './share-link/share-link.component';
import { LayerSwitcherComponent } from './layer-switcher/layer-switcher.component';
import { ChangMarkingSwitcherComponent } from "./change-marking-switcher/change-marking-switcher.component";
import { SidebarComponent } from './sidebar/sidebar.component';
import { HistoryComponent } from './history/history.component';
import { registerLocaleData } from "@angular/common";
import localeDa from "@angular/common/locales/da";
import localeDaExtra from "@angular/common/locales/extra/da";
import { HearingComponent } from './hearing/hearing.component';
import { ContentService } from './services/content.service';
import { PrintService } from './services/print.service';
import { ArticleComponent } from './article/article.component';
import { PhoneNumberValidatorDirective } from './validators/phone-number-validator.directive';
import { InfoComponent } from './info/info.component';
import { ZonesComponent } from './zones/zones.component';
import { RouteReuseService } from './services/routereuse.service';
import {NgbDateCustomParserFormatter} from "./date/NgbDateCustomParserFormatter";
import {LanguageModule} from './language/language.module';
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {ServiceLayersComponent} from "./service-layers/service-layers.component";
import { AngularSplitModule } from 'angular-split';
registerLocaleData(localeDa, "da", localeDaExtra);

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    SearchComponent,
    NewsComponent,
    ServiceLayersComponent,
    ShareLinkComponent,
    LayerSwitcherComponent,
    SidebarComponent,
    HistoryComponent,
    HearingComponent,
    ArticleComponent,
    InfoComponent,
    ZonesComponent,
    PhoneNumberValidatorDirective,
    ChangMarkingSwitcherComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SecondModal,
    ModalModule.forRoot(),
    ClipboardModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    LanguageModule,
    TooltipModule.forRoot(),
    NgSelectModule,
    CKEditorModule,
    AngularSplitModule
  ],
  entryComponents: [ArticleComponent],
  providers: [
    EnvironmentService,
    PlandataService,
    ConsultationdataService,
    MapService,
    CookieService,
    ContentService,
    PrintService,
    PreviewService,
    RoutingService,
    DateService,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService
    },
    { provide: NgbDateParserFormatter,
      useFactory: () => { return new NgbDateCustomParserFormatter("DD/MM-YYYY") }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
