import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewChecked,
} from "@angular/core";
import {
  NgbDate,
  NgbCarousel,
  NgbDatepickerI18n,
} from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { PlandataService } from "../services/plandata.service";
import { ContentService } from "../services/content.service";
import { RoutingService } from "../services/routing.service";
import { MapService } from "../services/map.service";
import { PlandataTimeLineInfo } from "../models/plandatatimelineinfo";
import { ActivatedRoute } from "@angular/router";
import { DateService } from "../services/date.service";
import { I18n, CustomDatepickerI18n } from "./i18Date.service";
import { LanguageService } from "../services/language.service";
import moment from "moment/moment";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class HistoryComponent implements OnInit, AfterViewChecked, OnDestroy {
  timeLine: any;
  selected_date: NgbDate = new NgbDate(1789, 7, 14);
  historyPrefix = "/plan/history";
  contentString: any;
  firstSlideValue: string;
  lastSlideValue: string;
  endValue: string;
  startValue: string;
  _chosenDate = -1;
  private subscriptions: Subscription[] = new Array();
  private _mapIndex = new Array();
  plandataTimeLineInfo: PlandataTimeLineInfo = new PlandataTimeLineInfo();
  //for history line time slider
  nextIndx: number = 0;
  prevIndx: number = 8;
  currentLang: string = null;

  @ViewChild("mycarousel", { static: true }) carousel: NgbCarousel;
  constructor(
    private planService: PlandataService,
    private languageService: LanguageService,
    private mapService: MapService,
    private cdr: ChangeDetectorRef,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public dateService: DateService
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    let me = this;
    if (!this.mapService.isMapLoaded()) {
      this.mapService.onMapReady().subscribe((status) => {
        me.mapService.deactivateDrawingTool(true);
        me.mapService.selectEnabled = true;
        me.setupHistList();
      });
    } else {
      me.mapService.deactivateDrawingTool(true);
      me.mapService.selectEnabled = true;
      this.setupHistList();
    }
  }

  private setupHistList() {

    this.timeLine = this.planService.getTimeline().reverse();

    this._mapIndex = new Array();
    for (let i = 0; i < this.timeLine.length; i++) {
      this._mapIndex.push(i);
    }
    this._mapIndex = this._mapIndex.reverse();

    this.startValue = "0";
    const first = Math.ceil(this.timeLine.length / 8);
    const second = first - 1;
    const third = second * 8;
    this.endValue = third.toString();

    let current = this.dateService.currentDate();
    this.selected_date = new NgbDate(
      current.year(),
      current.month() + 1,
      current.date()
    );

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (params["id"] > -1) {
          let timeIndex = Number(params["id"]);
          let index = this.findIndex(timeIndex);
          if (this._chosenDate != index) {
            this.loadPlanversionData(index);
            this.plandataTimeLineInfo = this.planService.getPlandataTimeLineInfo(
              timeIndex
            );
          }
        } else {
          this.changePath(
            this.findIndex(this.planService.currentTimeLineIndex)
          );
        }
      })
    );
  }

  select(selectedDate: any) {
    let newDate = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day
    );
    let timeLineIndex = this.getTimeIndex(newDate);
    this.changePath(timeLineIndex);
  }

  getText(prefix: string, shortName: string) {
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  private getTimeIndex(newDate: Date) {
    let tempTime = this.timeLine.slice().reverse();
    for (let n = 0; n < tempTime.length; n++) {
      if (
        new Date(new Date(tempTime[n].start).setUTCHours(0, 0, 0, 0)) <= newDate
      ) {
        if (tempTime.length == 1) {
          return n;
        }
        let nextIndex = n + 1;
        if (nextIndex < tempTime.length) {
          if (
            new Date(
              new Date(tempTime[nextIndex].start).setUTCHours(0, 0, 0, 0)
            ) >= newDate
          ) {
            return n;
          }
          if (n == tempTime.length - 1) {
            return n;
          }
        }
      }
    }
    return tempTime.length - 1;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  showDate(index: number) {
    let timeLineIndex = this.findIndex(index);
    this.changePath(timeLineIndex);
  }

  private showPlanversionData(timeLineIndex: number) {
    // planversion title
    // planversion type
    // høring
    // ikraft trådt
  }

  private loadPlanversionData(sliderindex: number) {
    this._chosenDate = sliderindex;
    let index = this.findIndex(sliderindex);
    this.mapService.setMapLoaded(false);
    this.planService.setPlanVersionFromHistorik(index);
  }

  private findIndex(index: number) {
    return this._mapIndex[index];
  }

  private changePath(timeIndex: number) {
    let urlPath = this.route.parent.routeConfig.path + "/hist/" + timeIndex;
    this.routingService.changeRoutePath(urlPath);
  }
  //slider
  public prevItem() {
    this.prevIndx = this.prevIndx + 1;
    this.nextIndx = this.nextIndx + 1;
  }

  public nextItem() {
    this.prevIndx = this.prevIndx - 1;
    this.nextIndx = this.nextIndx - 1;
  }
}
