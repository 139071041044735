import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostListener,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { MapService } from "../services/map.service";
import { ZoneFeature } from "../plandataDefinition/zoneFeature";
import { PlandataService } from "../services/plandata.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ContentService } from "../services/content.service";
import { LanguageService } from "../services/language.service";

@Component({
  selector: "app-layer-switcher",
  templateUrl: "./layer-switcher.component.html",
  styleUrls: ["./layer-switcher.component.scss"],
})
export class LayerSwitcherComponent implements OnInit, OnDestroy {
  @Input() _zoneLayers: any[];
  arrayDiffer: any;
  selectedZones: ZoneFeature[];
  status: boolean = false;
  depthLayersIsOpen: boolean = false;
  legendIsOpen: boolean = true;
  zonesIsReset: boolean = false;
  mapContent: any;
  mapPrefix = "/plan/map";
  contentString: any;
  innerWidth: number;
  currentLang: string = null;
  isChangeMarkingsEnabled: boolean = false;

  private subscriptions: Subscription[] = new Array();
  private _zoneLayersActive: String[] = new Array();
  constructor(
    private mapService: MapService,
    private languageService: LanguageService,
    private planDataService: PlandataService,
    private sanitizer: DomSanitizer,
    private contentService: ContentService,
    private route: ActivatedRoute
  ) {
    this.currentLang = this.languageService.prepareLanguage() || "de";
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private initSetup() {
    this.initLayerSwithcer();

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        if (this.route.snapshot.queryParams) {
          if (this.route.snapshot.queryParams.activeTypes != null) {
            let listTypes = this.route.snapshot.queryParams.activeTypes.split(
              ","
            );
            this.setLayersActive(listTypes);
          }
        }
      })
    );

    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.legendIsOpen = false;
    }
  }

  ngOnInit() {
    let me = this;
    if (!this.mapService.isMapLoaded()) {
      this.subscriptions.push(
        this.mapService.onMapReady().subscribe((status) => {
          me.initSetup();
        })
      );
    } else {
      me.initSetup();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.legendIsOpen = false;
    } else {
      this.legendIsOpen = true;
    }
  }

  initLayerSwithcer() {

    this.subscriptions.push(
      this.mapService.changeMarkingsSubject.subscribe((isEnabled) => {
        this.isChangeMarkingsEnabled = isEnabled;
      })
    );

    this.subscriptions.push(
      this.planDataService.onLayerConfigEventChanged().subscribe((any) => {
        this.setLayers(any);
      })
    );
   // console.log("??GETTING THE LAYER DATA?? "+JSON.stringify(this.planDataService.currentLayerConfig, null, 3));
    this.setLayers(this.planDataService.currentLayerConfig);
  }

  private setLayers(layerConfig: any) {

    let showLegendConsultation = this.planDataService.showLegendForConsultation();
    let showLegendPostConsultation = this.planDataService.showLegendForPostConsultation();
    this._zoneLayers = [];
    // console.log(JSON.stringify(layerConfig))
    if (layerConfig && layerConfig.layers) {
      let removeLayerList = ["Udviklingszone til kommercielle tanganlæg (At)","Development zone for commercial seaweed farms (At)"]
      for (let i = 0; i < layerConfig.layers.length; i++) {
        //console.log(layerConfig.layers[i])
        if (
          (!showLegendConsultation && layerConfig.layers[i].name == "consultation") ||
          (!showLegendPostConsultation && layerConfig.layers[i].name == "consultationEnd")
        ) {

          continue;
        }
        if(layerConfig.layers[i] && layerConfig.layers[i].subtypes && layerConfig.layers[i].subtypes.length > 0){
          let list = [];
          for(let obj of layerConfig.layers[i].subtypes){
            if(obj.displayName && ((removeLayerList.indexOf(obj.displayName.da) == -1) || (removeLayerList.indexOf(obj.displayName.en) == -1))){
             // console.log("Remove")
              list.push(obj);
            }
          }
          layerConfig.layers[i].subtypes = list;
        }
        this._zoneLayers.push(layerConfig.layers[i]);
      }
    }
  }

  getText(prefix: string, shortName: string) {
    this.mapContent = this.contentService.getContentByPrefix(
      this.currentLang + "/plan/map"
    );
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private handlingActiveLayerType(layer: any) {
    if (layer.active) {
      if (this._zoneLayersActive.indexOf(layer.type) == -1) {
        this._zoneLayersActive.push(layer.type);
        this.mapService.zoneLayersActive = this._zoneLayersActive;
      }
    } else {
      let index = this._zoneLayersActive.indexOf(layer.type);
      if (index > -1) {
        this._zoneLayersActive.splice(index, 1);
        this.mapService.zoneLayersActive = this._zoneLayersActive;
      }
    }
  }

  private setLayersActive(list: String[]) {
    this.zonesIsReset = true;
    for (let n = 0; n < list.length; n++) {
      for (let i = 0; i < this._zoneLayers.length; i++) {
        if (this._zoneLayers[i].type == "zones") {
          for (let j = 0; j < this._zoneLayers[i].subtypes.length; j++) {
            if (
              list[n] == "zones" ||
              list[n] == this._zoneLayers[i].subtypes[j].type
            ) {
              this._zoneLayers[i].subtypes[j].active = true;
              this.handlingActiveLayerType(this._zoneLayers[i].subtypes[j]);
              this.mapService.highlightZoneType(
                this._zoneLayers[i].subtypes[j].type,
                true
              );
            }
          }
        }
        if (this._zoneLayers[i].type == list[n]) {
          this._zoneLayers[i].active = true;
          this.handlingActiveLayerType(this._zoneLayers[i]);
          this.mapService.highlightZoneType(this._zoneLayers[i].type, true);
        }
      }
    }
  }

  toggleLayer(layer: any) {
    layer.active = !layer.active;
    this.handlingActiveLayerType(layer);

    for (let i = 0; i < this._zoneLayers.length; i++) {
      if (this._zoneLayers[i].type  == "changesSuggestions" || this._zoneLayers[i].type  == "changeMarking") {
        this._zoneLayers[i].active = true;
        this.handlingActiveLayerType(this._zoneLayers[i]);
        this.mapService.highlightZoneType(this._zoneLayers[i].type, true);

        for (let j = 0; j < this._zoneLayers[i].subtypes.length; j++) {
          this._zoneLayers[i].subtypes[j].active = true;
          this.handlingActiveLayerType(this._zoneLayers[i].subtypes[j]);
        }
      }
    }

    if (layer.type == "zones" || layer.type == "changesSuggestions" || layer.type == "changeMarking") {
      for (let i = 0; i < this._zoneLayers.length; i++) {
        if (this._zoneLayers[i].type == layer.type || this._zoneLayers[i].type  == "changesSuggestions" || this._zoneLayers[i].type  == "changeMarking") {
          for (let j = 0; j < this._zoneLayers[i].subtypes.length; j++) {
            this._zoneLayers[i].subtypes[j].active = layer.active;
            this.handlingActiveLayerType(this._zoneLayers[i].subtypes[j]);
          }
        }
      }
    }

    this.mapService.highlightZoneType(layer.type, layer.active);

    for (var i = 0; i < this._zoneLayers.length; i++) {
      if (this._zoneLayers[i].active) {
        this.zonesIsReset = true;
        return;
      }
      if (this._zoneLayers[i].subtypes) {
        for (var n = 0; n < this._zoneLayers[i].subtypes.length; n++) {
          if (this._zoneLayers[i].subtypes[n].active) {
            this.zonesIsReset = true;
            return;
          }
        }
      }
    }
    this._zoneLayersActive = new Array();
    this.mapService.resetAllHighlightZoneTyoe();
    this.zonesIsReset = false;
  }

  resetZones() {
    this.zonesIsReset = false;

    if (this._zoneLayers) {
      for (var i = 0; i < this._zoneLayers.length; i++) {
        this._zoneLayers[i].active = false;
        if (this._zoneLayers[i] && this._zoneLayers[i].subtypes) {
          for (var n = 0; n < this._zoneLayers[i].subtypes.length; n++) {
            this._zoneLayers[i].subtypes[n].active = false;
          }
        }
      }
      this._zoneLayers = this._zoneLayers.slice(0);
    }

    this._zoneLayersActive = new Array();
    this.mapService.resetAllHighlightZoneTyoe();

    this.selectedZones = this.mapService.getSelectedFeature();
    if (this.selectedZones) {
      this.selectedZones.forEach((zone) => {
        this.selectedZoneStyle(
          zone.layerName,
          zone.planInfo.styleType,
          zone.refId
        );
      });
    }
  }

  selectedZoneStyle(layerName: string, styleType: string, refId: string) {
    this.mapService.setFeatureStyle(layerName, refId, styleType, "selected");
  }
}
