import { Style, Fill, Text, Stroke } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Feature from "ol/Feature";

var canvas = document.createElement('canvas');
var context = canvas.getContext('2d');

export class VectorStyle {
  private _styleConfig: any = null;
  constructor() {
  }

  set setStyleConfig(styleConfig: any) {
    this._styleConfig = styleConfig;
  }

  private createStyleFromConfig(styleType: string, layerName: string, feature: any, resolution: any) : Style {

    if(this._styleConfig) {
      let styles = this._styleConfig;
      //console.log("Brefore: "+layerName +" : "+styleType);
      for (const key in styles) {
        if (key && styles[key].styleType.toLowerCase() === styleType.toLowerCase() &&
        styles[key].layerName.toLowerCase() == layerName.toLowerCase()) {
        
          const newStyle = new Style({});
          if (styles[key].fillProps) {
            newStyle.setFill(new Fill(styles[key].fillProps));
          }
          if (styles[key].strokeProps) {
            newStyle.setStroke(new Stroke(styles[key].strokeProps));
          }
          if (styles[key].cirkelProps) {
            const cirkelProps = JSON.parse(JSON.stringify(styles[key].cirkelProps)) as any;
            if (cirkelProps['fill']) {
              cirkelProps['fill'] = new Fill(cirkelProps['fill'].fillProps);
            }          
            const circleTempStyle = new CircleStyle(cirkelProps);
            newStyle.setImage(circleTempStyle);
          }
          if(styles[key].text) {
            newStyle.setText(this.createTextStyle(feature, resolution, styles[key].text));
          }
          return newStyle;
        }
      }
    }
    return null;
  }


  public getStyleByType(
    styleStrType: string,
    feature: Feature,
    resolution: any
  ): Style {
    
    let elements = styleStrType.split("_");
    let layerName = "";
    let styleType = "";
    if(elements.length == 1) {
      switch (elements[0].toLowerCase()) {
        case "highlight":
        case "show":
        case "edit":
        case "selected":
          styleType = elements[0];
         
          break;
        default: 
          styleType = "active";
          layerName  = elements[0];
          break;
      }
    }else if (elements.length == 2) {
      styleType = "hide";
      layerName  = elements[0];
    }else if (elements.length == 3) {
      styleType = "hide";
      layerName  = elements[0]+"_"+elements[1];
    }

    let featureStyle: Style;
  
    featureStyle = this.createStyleFromConfig(styleType, layerName, feature, resolution);
    return featureStyle;
  }

  private stringDivider(str: any, width: any, spaceReplacer: any) {
    if (str.length > width) {
      var p = width;
      while (p > 0 && str[p] != " " && str[p] != "-") {
        p--;
      }
      if (p > 0) {
        var left;
        if (str.substring(p, p + 1) == "-") {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        var right = str.substring(p + 1);
        return (
          left + spaceReplacer + this.stringDivider(right, width, spaceReplacer)
        );
      }
    }
    return str;
  }

  private getTextFromFeature(feature: Feature, resolution: any, options: any) {
    var type = options.text;
    var maxResolution = 400;
    var text = "";
    if (feature.get("forkortels")) {
      text = feature.get("forkortels");
    } else if (feature.get("kode_dsl")) {
      text = feature.get("kode_dsl");
    }else if (feature.get("type")) {
      text = feature.get("type");
    }
/*
    if (resolution > maxResolution) {
      text = "";
    } else */if (type == "hide") {
      text = "";
    } else if (type == "shorten") {
      //text = text.trunc(12);
    } else if (
      type == "wrap" &&
      (!options.placement || options.placement != "line")
    ) {
      text = this.stringDivider(text, 16, "\n");
    }
    return text;
  }

  private createTextStyle(feature: any, resolution: any, options: any) {
    var align = options.align;
    var baseline = options.baseline;
    var size = options.size;
    var offsetX = parseInt(options.offsetX, 10);
    var offsetY = parseInt(options.offsetY, 10);
    var weight = options.weight;
    var font = weight + " " + size + " " + options.font;
    var fillColor = options.color;
    var outlineColor = options.outline;
    var outlineWidth = parseInt(options.outlineWidth, 10);

    return new Text({
      textAlign: align == "" ? undefined : align,
      textBaseline: baseline,
      font: font,
      text: this.getTextFromFeature(feature, resolution, options),
      fill: new Fill({ color: fillColor }),
      stroke: new Stroke({ color: outlineColor, width: outlineWidth }),
      offsetX: offsetX,
      offsetY: offsetY,
      placement: "point",
      maxAngle: 0.7853981633974483,
      overflow: false,
      rotation: 0
    });
  }
}
