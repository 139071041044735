import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Consultation } from "../models/consultation";
import { MapSettings } from "../models/mapsettings";
import { PlandataTimeLineInfo } from "../models/plandatatimelineinfo";
import { StatusInfo } from "../models/statusinfo";
import { Timeline } from "../models/timeline";
import { TimePlanversionEvent } from "../models/timeplanversionevent";
import { DescriptionTextPdf } from "../plandataDefinition/descriptionTextPdf";
import { ContentService } from "./content.service";
import { DateService } from "./date.service";
import { EnvironmentService } from "./environment.service";
import { LanguageService } from "./language.service";
import { PreviewService } from "./preview.service";
// import moment from "moment";
//import { Console } from "console";
//import { ConsoleReporter } from 'jasmine';

@Injectable()
export class PlandataService {
  private _seaplandata: any;
  private _currentDateTime: Date;
  private _mapDefinition: any;
  private _currentConfigLayers: [];
  private _currentPublishedLayers = new Array();
  private _currentConsultationLayers = new Array();
  private _currentConsultationVersionIdxList = new Array();
  private _currentPostConsultationVersionIdxList = new Array();
  private _currentPublishedVersionIdx;
  private _workingVersion: number;
  private _currentTimeLineIndex: number;
  private _layerConfigChanged = new Subject<any>();
  private _layersChanged = new Subject<any>();
  private _timePlanversionChanged = new Subject<TimePlanversionEvent>();
  currentLang: string = null;
  contentString: any;
  historyPrefix = "/plan/history";

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private previewService: PreviewService,
    private dateService: DateService,
    private languageService: LanguageService,
    private contentService: ContentService
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  onLayerConfigEventChanged(): Observable<any> {
    return this._layerConfigChanged.asObservable();
  }

  onLayersEventChanged(): Observable<any> {
    return this._layersChanged.asObservable();
  }

  onTimePlanversionChanged(): Observable<TimePlanversionEvent> {
    return this._timePlanversionChanged.asObservable();
  }

  set currentLayerConfig(configLayers: any) {
    this._currentConfigLayers = configLayers;
  }

  set currentSeaPlandata(plandataversion: any) {
    this.currentSeaPlandata$.next(plandataversion);
    this._seaplandata = plandataversion;
  }

  currentSeaPlandata$ = new Subject<any>();

  set currentDateTime(currentDateTime: Date) {
    this._currentDateTime = currentDateTime;
  }

  set mapDefinition(mapDefination: any) {
    this._mapDefinition = mapDefination;
  }

  set currentTimeLineIndex(timeIndex: number) {
    this._currentTimeLineIndex = timeIndex;
  }

  getText(prefix: string, shortName: string) {
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  get currentDateTime(): Date {
    return this._currentDateTime;
  }

  get mapDefinition() {
    return this._mapDefinition;
  }

  get currentPlandata(): any {
    return this._seaplandata;
  }

  get currentLayerConfig(): any {
    return this._currentConfigLayers;
  }

  get currentPublishedLayers(): any {
    return this._currentPublishedLayers;
  }

  get currentConsultationLayers(): any {
    return this._currentConsultationLayers;
  }

  get currentTimeLineIndex(): number {
    return this._currentTimeLineIndex;
  }

  private setLayerConfig(index: number): void {
    if (
      index != null &&
      index > -1 &&
      this._seaplandata.configurations[index] &&
      this._seaplandata.configurations[index].layers &&
      this._seaplandata.configurations[index].styles
    ) {
      this.currentLayerConfig = this._seaplandata.configurations[index];
      this._layerConfigChanged.next(this._seaplandata.configurations[index]);
    } else {
      this.currentLayerConfig = this._seaplandata.configurations[0];
      this._layerConfigChanged.next(this._seaplandata.configurations[0]);
    }
  }

  public getPlandata(): Observable<Blob> {
    let previewDateIso: string = this._currentDateTime.toISOString();

    // Preview
    let plandataServiceEndpoint = "";
    if (this.previewService.getPlandataPreviewUUID() != null)
      plandataServiceEndpoint =
        this.environmentService.getEnvironment()
          .serviceurl_protectedportalcache +
        "plandata/" +
        this.previewService.getPlandataPreviewUUID() +
        "/" +
        previewDateIso +
        "/preview";
    else if (this.previewService.getPlandataConsultationPreviewUUID() != null)
      plandataServiceEndpoint =
        this.environmentService.getEnvironment()
          .serviceurl_protectedportalcache +
        "plandata/" +
        this.previewService.getPlandataConsultationPreviewUUID() +
        "/" +
        previewDateIso +
        "/consultationpreview";
    else
      plandataServiceEndpoint =
        this.environmentService.getEnvironment().serviceurl_portalcache +
        "plandata";

    return this.http.get<any>(plandataServiceEndpoint, { params: {} });
  }

  public getCurrentDateTime(): Observable<Blob> {
    return this.http.get<any>(
      this.environmentService.getEnvironment().serviceurl_portalcache +
        "currentDateTime",
      { params: {} }
    );
  }

  public getMapDefinition(): Observable<any> {
    return this.http.get<any>(
      this.environmentService.getEnvironment().serviceurl_portalcache +
        "mapdefinition",
      { params: {} }
    );
  }

  public setPlanVersionFromCurrentDateTime() {
    let index = this.findTimeLineIndex();
    if (index > -1) {
      let configurationIdx = this.findIndexOfConfigurationToApply(index);
      this.setLayerConfig(configurationIdx);
      this.currentTimeLineIndex = index;
      this._workingVersion = this.findLastApprovedOrCancelled();
      let event = new TimePlanversionEvent();
      event.timeLineIndex = index;
      event.historicPlan = this._workingVersion <= index ? false : true;
      this._timePlanversionChanged.next(event);

      this._currentConsultationVersionIdxList =
        this._seaplandata.timeline[index].consultationVersionIdxList;
      this._currentPublishedVersionIdx =
        this._seaplandata.timeline[index].publishedVersionIdx;
      this._currentPostConsultationVersionIdxList =
        this._seaplandata.timeline[index].postConsultationVersionIdxList;
      this.createPlandataLayers(index);
    } else {
      console.log("No data for seaplan timeline");
    }
  }

  public setRegularPlanVersionTimeLine() {
    let event = new TimePlanversionEvent();
    event.timeLineIndex = this.currentTimeLineIndex;
    event.historicPlan =
      this._workingVersion <= this.currentTimeLineIndex ? false : true;
    this._timePlanversionChanged.next(event);
  }

  public setConsultationTimeLine(consultation: any, timeLineData: any[]) {
    let consultationTimeIndex = this.getTimeIndex(
      new Date(consultation.currentDateTime),
      timeLineData
    );
    let event = new TimePlanversionEvent();
    event.timeLineIndex = consultationTimeIndex;
    event.historicPlan =
      this._workingVersion <= this.currentTimeLineIndex ? false : true;
    this._timePlanversionChanged.next(event);
  }

  getTimeIndex(newDate: Date, timeLineData: any[]) {
    let timeIndex: number = 0;

    for (let n = 0; n < timeLineData.length; n++) {
      if (newDate >= new Date(timeLineData[n].start)) {
        timeIndex = n;
      }
    }
    return timeIndex;
  }

  public isHistoryMap(): boolean {
    //return this._workingVersion != this._currentTimeLineIndex;
    console.log(
      "isHistoryMap: | _workingVersion=" +
        this._workingVersion +
        " _currentTimeLineIndex=" +
        this._currentTimeLineIndex
    );
    return this._workingVersion > this._currentTimeLineIndex;
  }

  private findIndexOfConfigurationToApply(timeIndex: number): number {
    // let index = -1;
    // if (currentVersionIdx > -1) {
    //   index =
    //     this._seaplandata.consultationVersions[currentVersionIdx]
    //       .configurationIdx;
    // }

    if (
      !this._seaplandata.timeline ||
      timeIndex >= this._seaplandata.timeline.length
    ) {
      return this._seaplandata.consultationVersions[0].configurationIdx;
    }

    const pointInTime = this._seaplandata.timeline[timeIndex];

    if (
      pointInTime.publishedVersionIdx > -1 &&
      this._seaplandata.publishedVersions &&
      pointInTime.publishedVersionIdx <
        this._seaplandata.publishedVersions.length
    ) {
      return this._seaplandata.publishedVersions[
        pointInTime.publishedVersionIdx
      ].configurationIdx;
    }

    let consultationVersionIdxList = [];
    if (
      pointInTime.consultationVersionIdxList != null &&
      pointInTime.consultationVersionIdxList.length > 0
    ) {
      consultationVersionIdxList = pointInTime.consultationVersionIdxList;
    } else if (
      pointInTime.postConsultationVersionIdxList != null &&
      pointInTime.postConsultationVersionIdxList.length > 0
    ) {
      consultationVersionIdxList = pointInTime.postConsultationVersionIdxList;
    }

    for (let i = consultationVersionIdxList.length - 1; i >= 0; i--) {
      const planVersionBeforeIdx = consultationVersionIdxList[i];
      if (
        this._seaplandata.consultationVersions[planVersionBeforeIdx]
          .configurationIdx > -1
      ) {
        return this._seaplandata.consultationVersions[planVersionBeforeIdx]
          .configurationIdx;
      }
    }

    return -1;
  }

  public isCurrentTimeLineHistoric() {
    return this._workingVersion <= this.currentTimeLineIndex ? false : true;
  }

  public setPlanVersionFromHistorik(timeIndex: number) {
    if (0 <= timeIndex < this._seaplandata.timeline.length) {
      let configurationIdx = this.findIndexOfConfigurationToApply(timeIndex);
      this.setLayerConfig(configurationIdx);

      this.currentTimeLineIndex = timeIndex;
      let event = new TimePlanversionEvent();
      event.timeLineIndex = timeIndex;
      // Get working version
      let index = this.findLastApprovedOrCancelled();
      if (index != -1) {
        this._workingVersion = index;
      }
      event.historicPlan = this._workingVersion <= timeIndex ? false : true;

      this._timePlanversionChanged.next(event);

      let timeLineHistorie = this._seaplandata.timeline[timeIndex];
      if (timeLineHistorie) {
        this._currentConsultationVersionIdxList =
          timeLineHistorie.consultationVersionIdxList;
        this._currentPublishedVersionIdx = timeLineHistorie.publishedVersionIdx;
        this._currentPostConsultationVersionIdxList =
          timeLineHistorie.postConsultationVersionIdxList;
        this.createPlandataLayers(timeIndex);
        this._layersChanged.next();
      }
    } else {
      console.log("timeIndex does not exists");
    }
  }

  public showLegendForConsultation(): boolean {
    if (this._currentPublishedVersionIdx == -1) {
      return false;
    }
    if (this._currentConsultationVersionIdxList.length > 0) {
      return true;
    }
    return false;
  }

  public showLegendForPostConsultation(): boolean {
    if (this._currentPublishedVersionIdx == -1) {
      return false;
    }
    if (this._currentPostConsultationVersionIdxList.length > 0) {
      return true;
    }
    return false;
  }

  private getGeometryFileKeys(): Set<string> {
    let result: Set<string> = new Set<string>();

    if (this._currentPublishedVersionIdx != -1) {
      for (let k in this._seaplandata.publishedVersions[
        this._currentPublishedVersionIdx
      ].geometryFiles) {
        result.add(k);
      }
    }
    for (let i = 0; i < this._currentConsultationVersionIdxList.length; i++) {
      for (let k in this._seaplandata.consultationVersions[
        this._currentConsultationVersionIdxList[i]
      ].geometryFiles) {
        result.add(k);
      }
    }
    for (
      let i = 0;
      i < this._currentPostConsultationVersionIdxList.length;
      i++
    ) {
      for (let k in this._seaplandata.consultationVersions[
        this._currentPostConsultationVersionIdxList[i]
      ].geometryFiles) {
        result.add(k);
      }
    }
    return result;
  }

  private createPlandataLayers(timeIndex: number) {
    let publishedlayers = [];
    let consultatationlayers = [];

    let geometryFileKeys = this.getGeometryFileKeys();
    //@ts-ignore
    for (let value of geometryFileKeys) {
      // -1 FIRST special case for seaplan
      if (this._currentPublishedVersionIdx == -1) {
        for (
          let i = 0;
          i < this._currentConsultationVersionIdxList.length;
          i++
        ) {
          if (
            this._seaplandata.consultationVersions[
              this._currentConsultationVersionIdxList[i]
            ].geometryFiles[value]
          ) {
            let isChangeType = value == "changed" || value == "removed";

            publishedlayers.push({
              type: "consultation",
              name: value,
              styleType: value,
              layerType: "approved",
              uuid: this._seaplandata.consultationVersions[
                this._currentConsultationVersionIdxList[i]
              ].geometryFiles[value],
              planversionId:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].planversionId,
              planversionName:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].title,
              planversionName_en:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].title_en,
              planversionType: "",
              consultationNoteUUID:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationNoteUUID,
              consultationNote:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationNote,
              consultationDescription:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationDescription,
              consultationVersionIdx:
                this._currentConsultationVersionIdxList[i],
              status: "consultation",
              consultationStart:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationStart,
              consultationEnd:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationEnd,
              effectiveDate:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].effectiveDate,
              descriptionTexts:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].descriptionTexts,
              historic: this._workingVersion <= timeIndex ? false : true,
            });
          }
        }

        // consultation without hearing
        for (
          let i = 0;
          i < this._currentPostConsultationVersionIdxList.length;
          i++
        ) {
          if (
            this._seaplandata.consultationVersions[
              this._currentPostConsultationVersionIdxList[i]
            ].geometryFiles[value]
          ) {
            let isChangeType = value == "changed" || value == "removed";

            consultatationlayers.push({
              type: "consultation",
              name: value,
              styleType: value, //(isChangeType ? value : "consultation"),
              layerType: "approved", //(isChangeType ? value : "consultation"),
              uuid: this._seaplandata.consultationVersions[
                this._currentPostConsultationVersionIdxList[i]
              ].geometryFiles[value],
              planversionId:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].planversionId,
              planversionName:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].title,
              planversionName_en:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].title_en,
              planversionType: "",
              consultationNoteUUID:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationNoteUUID,
              consultationNote:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationNote,
              consultationDescription:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationDescription,
              consultationVersionIdx:
                this._currentPostConsultationVersionIdxList[i],
              status: "approved", //'consultation',
              consultationStart:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationStart,
              consultationEnd:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationEnd,
              effectiveDate:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].effectiveDate,
              descriptionTexts:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].descriptionTexts,
              historic: this._workingVersion <= timeIndex ? false : true,
            });
          }
        }
      } else {
        // Published
        if (
          this._seaplandata.publishedVersions[this._currentPublishedVersionIdx]
            .geometryFiles[value]
        ) {
          publishedlayers.push({
            name: value,
            styleType: value,
            layerType: "approved",
            uuid: this._seaplandata.publishedVersions[
              this._currentPublishedVersionIdx
            ].geometryFiles[value],
            planversionId:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].planversionId,
            planversionName:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].title,
            planversionName_en:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].title_en,
            planversionType: "",
            consultationNoteUUID:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].consultationNoteUUID,
            consultationNote:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].consultationNote,
            consultationDescription:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].consultationDescription,
            currentPublishedVersionIdx: this._currentPublishedVersionIdx,
            status: "approved",
            consultationStart:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].consultationStart,
            consultationEnd:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].consultationEnd,
            effectiveDate:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].effectiveDate,
            descriptionTexts:
              this._seaplandata.publishedVersions[
                this._currentPublishedVersionIdx
              ].descriptionTexts,
            historic: this._workingVersion <= timeIndex ? false : true,
          });
        }

        // Hearings
        for (
          let i = 0;
          i < this._currentConsultationVersionIdxList.length;
          i++
        ) {
          if (
            this._seaplandata.consultationVersions[
              this._currentConsultationVersionIdxList[i]
            ].geometryFiles[value]
          ) {
            let isChangeType = value == "changed" || value == "removed";
            consultatationlayers.push({
              name: value,
              styleType: isChangeType ? value : "consultation",
              layerType: isChangeType ? value : "consultation",
              uuid: this._seaplandata.consultationVersions[
                this._currentConsultationVersionIdxList[i]
              ].geometryFiles[value],
              planversionId:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].planversionId,
              planversionName:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].title,
              planversionName_en:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].title_en,
              planversionType: "",
              consultationNoteUUID:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationNoteUUID,
              consultationNote:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationNote,
              consultationDescription:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationDescription,
              consultationVersionIdx:
                this._currentConsultationVersionIdxList[i],
              status: "consultation",
              consultationStart:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationStart,
              consultationEnd:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].consultationEnd,
              effectiveDate:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].effectiveDate,
              descriptionTexts:
                this._seaplandata.consultationVersions[
                  this._currentConsultationVersionIdxList[i]
                ].descriptionTexts,
              historic: this._workingVersion <= timeIndex ? false : true,
            });
          }
        }

        // v
        for (
          let i = 0;
          i < this._currentPostConsultationVersionIdxList.length;
          i++
        ) {
          if (
            this._seaplandata.consultationVersions[
              this._currentPostConsultationVersionIdxList[i]
            ].geometryFiles[value]
          ) {
            let isChangeType = value == "changed" || value == "removed";

            consultatationlayers.push({
              type: "consultation",
              name: value,
              styleType: value,
              layerType: "approved",
              // name: value,
              // styleType: (isChangeType ? value : "consultation"),
              // layerType: 'approved', //(isChangeType ? value : "consultation"),
              uuid: this._seaplandata.consultationVersions[
                this._currentPostConsultationVersionIdxList[i]
              ].geometryFiles[value],
              planversionId:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].planversionId,
              planversionName:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].title,
              planversionName_en:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].title_en,
              planversionType: "",
              consultationNoteUUID:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationNoteUUID,
              consultationNote:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationNote,
              consultationDescription:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationDescription,
              consultationVersionIdx:
                this._currentPostConsultationVersionIdxList[i],
              status: "approved", //'consultation',
              consultationStart:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationStart,
              consultationEnd:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].consultationEnd,
              effectiveDate:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].effectiveDate,
              descriptionTexts:
                this._seaplandata.consultationVersions[
                  this._currentPostConsultationVersionIdxList[i]
                ].descriptionTexts,
              historic: this._workingVersion <= timeIndex ? false : true,
            });
          }
        }
      }
    }

    this._currentPublishedLayers = publishedlayers;
    this._currentConsultationLayers = consultatationlayers;
  }

  private getStatusInfo(value: string): StatusInfo {
    let response: StatusInfo = new StatusInfo();
    switch (value.toUpperCase()) {
      case "PLAN_CONSULTATION_START":
        response.cmstext = "consultation_attribute_status_in_consultation_plan";
        response.status = "consultation";
        break;
      case "PLAN_CONSULTATION_END":
        response.cmstext =
          "consultation_attribute_status_in_consultation_plan_end";
        response.status = "consultation";
        break;
      case "PLAN_APPROVED":
        response.cmstext =
          "consultation_attribute_status_in_consultation_plan_approved";
        response.status = "approved";
        break;
      case "SUPPLEMENT_CONSULTATION_START":
        response.cmstext =
          "consultation_attribute_status_in_consultation_supplement";
        response.status = "consultation";
        break;
      case "SUPPLEMENT_CONSULTATION_END":
        response.cmstext =
          "consultation_attribute_status_in_consultation_supplement_end";
        response.status = "consultation";
        break;
      case "SUPPLEMENT_APPROVED":
        response.cmstext =
          "consultation_attribute_status_in_consultation_supplement_approved";
        response.status = "approved";
        break;
      case "CANCELLATION":
        response.cmstext =
          "consultation_attribute_status_in_consultation_cancelled";
        response.status = "cancel";
        break;
      default:
        response.cmstext =
          "consultation_attribute_status_in_consultation_unknown";
        response.status = "";
        break;
    }
    return response;
  }

  private getConsultationStatusAndDate(consultationIdx: number): any {
    let response: Timeline = new Timeline();
    let lastConsultationIdx = -1;
    let lastPostConsultationIdx = -1;
    let tempTimeLine = new Array();
    for (let n = 0; n < this._seaplandata.timeline.length; n++) {
      if (
        new Date(this._seaplandata.timeline[n].start) <= this._currentDateTime
      ) {
        tempTimeLine.push(this._seaplandata.timeline[n]);
      }
    }

    for (let n = 0; n < tempTimeLine.length; n++) {
      if (
        tempTimeLine[n].consultationVersionIdxList.indexOf(consultationIdx) !=
        -1
      ) {
        lastConsultationIdx = n;
      }
    }
    for (let n = 0; n < tempTimeLine.length; n++) {
      if (
        tempTimeLine[n].postConsultationVersionIdxList.indexOf(
          consultationIdx
        ) != -1
      ) {
        lastPostConsultationIdx = n;
      }
    }

    if (lastPostConsultationIdx > -1) {
      if (tempTimeLine.length > lastPostConsultationIdx + 1) {
        let newlastPostConsultationIdx = lastPostConsultationIdx + 1;
        response.effectiveDate = tempTimeLine[newlastPostConsultationIdx].start;
        response.statusInfo = this.getStatusInfo(
          tempTimeLine[newlastPostConsultationIdx].timelineType
        );
      } else {
        response.effectiveDate = tempTimeLine[lastPostConsultationIdx].start;
        response.statusInfo = this.getStatusInfo(
          tempTimeLine[lastPostConsultationIdx].timelineType
        );
      }
    } else if (lastConsultationIdx > -1) {
      if (tempTimeLine.length > lastConsultationIdx + 1) {
        let newlastConsultationIdx = lastConsultationIdx + 1;
        response.effectiveDate = tempTimeLine[newlastConsultationIdx].start;
        if (
          tempTimeLine[newlastConsultationIdx].timelineType.indexOf("_END") ==
          -1
        ) {
          response.statusInfo = this.getStatusInfo(
            tempTimeLine[newlastConsultationIdx].timelineType
          );
        } else {
          response.statusInfo = this.getStatusInfo(
            tempTimeLine[newlastConsultationIdx].timelineType.replace(
              "_END",
              "_START"
            )
          );
        }
      } else {
        response.effectiveDate = tempTimeLine[lastConsultationIdx].start;
        if (
          tempTimeLine[lastConsultationIdx].timelineType.indexOf("_END") == -1
        ) {
          response.statusInfo = this.getStatusInfo(
            tempTimeLine[lastConsultationIdx].timelineType
          );
        } else {
          response.statusInfo = this.getStatusInfo(
            tempTimeLine[lastConsultationIdx].timelineType.replace(
              "_END",
              "_START"
            )
          );
        }
      }
    }
    return response;
  }

  public getConsultations(includeHistoric: boolean): Consultation[] {
    let list: Consultation[] = new Array();
    // Get current in time
    // this.currentTimeLineIndex;
    if (includeHistoric) {
      // No historical

      for (let i = 0; i < this._seaplandata.consultationVersions.length; i++) {
        if (
          new Date(this._seaplandata.consultationVersions[i].consultationEnd) >
            this._currentDateTime &&
          new Date(
            this._seaplandata.consultationVersions[i].consultationStart
          ) <= this._currentDateTime
        ) {
          let item: Consultation = this._seaplandata.consultationVersions[
            i
          ] as Consultation;
          item.consultationVersionIdx = i;
          let response: Timeline = this.getConsultationStatusAndDate(i);
          item.statusInfo = response.statusInfo;
          item.effectiveDate = response.effectiveDate;
          item.historic = false;
          list.push(item);
        }
      }

      for (let i = 0; i < this._seaplandata.consultationVersions.length; i++) {
        if (
          new Date(this._seaplandata.consultationVersions[i].consultationEnd) <=
            this._currentDateTime &&
          new Date(
            this._seaplandata.consultationVersions[i].consultationStart
          ) <= this._currentDateTime
        ) {
          let item: Consultation = this._seaplandata.consultationVersions[
            i
          ] as Consultation;
          item.historic = true;
          item.consultationVersionIdx = i;
          let response: Timeline = this.getConsultationStatusAndDate(i);
          item.statusInfo = response.statusInfo;
          item.effectiveDate = response.effectiveDate;
          list.push(item);
        }
      }
    } else {
      for (let i = 0; i < this._seaplandata.consultationVersions.length; i++) {
        if (
          new Date(this._seaplandata.consultationVersions[i].consultationEnd) >
            this._currentDateTime &&
          new Date(
            this._seaplandata.consultationVersions[i].consultationStart
          ) <= this._currentDateTime
        ) {
          let item: Consultation = this._seaplandata.consultationVersions[
            i
          ] as Consultation;
          item.consultationVersionIdx = i;
          let response: Timeline = this.getConsultationStatusAndDate(i);
          item.statusInfo = response.statusInfo;
          item.effectiveDate = response.effectiveDate;
          item.historic = false;
          list.push(item);
        }
      }
    }
    return list;
  }

  public getMapBaseSettings(): MapSettings {
    let defaultSettings = this._seaplandata.configurations[0];
    let mapsettings: MapSettings = new MapSettings();
    this.currentLayerConfig.settings
      ? (mapsettings.settings = this.currentLayerConfig.settings)
      : (mapsettings.settings = defaultSettings.settings);
    this.currentLayerConfig.backgroundlayers
      ? (mapsettings.backgroundlayers =
          this.currentLayerConfig.backgroundlayers)
      : (mapsettings.backgroundlayers = defaultSettings.backgroundlayers);
    this.currentLayerConfig.overlayers
      ? (mapsettings.overlayers = this.currentLayerConfig.overlayers)
      : (mapsettings.overlayers = defaultSettings.overlayers);
    return mapsettings;
  }

  public getNameForZoneType(zone: any): string {
    return this.getTitlePrefixByZoneId(
      zone.refId,
      zone.zoneType[0],
      zone.planInfo.planversionId,
      zone.planInfo.status,
      this.currentLang
    );
  }

  public getDescriptionTextPdf(
    planVersionId: string,
    singleZone: any,
    currentLang: string
  ): DescriptionTextPdf {
    let result: DescriptionTextPdf = null;
    let lang: string = "";
    if (currentLang == "en") {
      lang = "_en";
    }
    if (singleZone.planInfo.status == "consultation") {
      for (let i = 0; i < this._seaplandata.consultationVersions.length; i++) {
        if (
          this._seaplandata.consultationVersions[i].planversionId ==
          planVersionId
        ) {
          let pdfUuid =
            this._seaplandata.consultationVersions[i][
              "descriptionTextPDF" + lang
            ];
          if (pdfUuid != null) {
            let pdfUrl: string =
              "/portalcache/api/v1/file/" +
              currentLang +
              "/" +
              pdfUuid +
              ".pdf";

            if (
              singleZone.zoneType != null &&
              singleZone.zoneType.length == 1
            ) {
              pdfUrl += "#nameddest=" + singleZone.zoneType[0];
            }

            result = {
              pdfUrl: pdfUrl,
              validFrom:
                this._seaplandata.consultationVersions[i][
                  "descriptionTextPDFValidFrom" + lang
                ],
              validTo:
                this._seaplandata.consultationVersions[i][
                  "descriptionTextPDFValidTo" + lang
                ],
              draft: true,
              historic: false,
            };
          }
        }
      }
    }
    //PDF for both da/en version
    if (result == null && this._currentPublishedVersionIdx != -1) {
      let currPublishedVersion =
        this._seaplandata.publishedVersions[this._currentPublishedVersionIdx];
      let pdfUuid = currPublishedVersion["descriptionTextPDF" + lang];
      if (pdfUuid != null) {
        let pdfUrl: string =
          "/portalcache/api/v1/file/" + currentLang + "/" + pdfUuid + ".pdf";

        let isHistoric: boolean = false;
        if (currPublishedVersion["descriptionTextPDFValidTo" + lang] != null) {
          isHistoric = true;
          pdfUrl += "?historic=true"; // to prevent clientside caching without watermark
        }

        if (singleZone.zoneType != null && singleZone.zoneType.length == 1) {
          pdfUrl += "#nameddest=" + singleZone.zoneType[0];
        }

        result = {
          pdfUrl: pdfUrl,
          validFrom: currPublishedVersion["descriptionTextPDFValidFrom" + lang],
          validTo: currPublishedVersion["descriptionTextPDFValidTo" + lang],
          draft: false,
          historic: isHistoric,
        };
      }
    }
    return result;
  }

  public getLawstextByZoneType(
    zoneType: string,
    planversionId: string,
    status: string,
    lang: string
  ): string {
    let key = this.getLawstextKey(zoneType, planversionId, status);
    if (key != null) return this.getLawText(key, lang);
    else return "";
  }

  public getLawstextByZoneId(
    zoneID: string,
    planversionId: string,
    status: string,
    lang: string
  ): string {
    let key = this.getLawstextKey("#" + zoneID, planversionId, status);
    if (key != null) return this.getLawText(key, lang);
    else return "";
  }

  private getLawstextKey(
    identifier: string,
    planversionId: string,
    status: string
  ) {
    let lawsTexts = this.getLawsTexts(planversionId, status);
    let lawTextKey = lawsTexts[identifier];
    if (
      lawTextKey == null &&
      status == "consultation" &&
      this._currentPublishedVersionIdx != -1
    ) {
      let currPublishedVersion =
        this._seaplandata.publishedVersions[this._currentPublishedVersionIdx];
      lawsTexts = this.getLawsTexts(
        currPublishedVersion.planversionId,
        "approved"
      );
      lawTextKey = lawsTexts[identifier];
    }
    return lawTextKey;
  }

  public getTitlePrefixByZoneId(
    zoneID: string,
    zoneType: string,
    planversionId: string,
    status: string,
    lang: string
  ): string {
    let key = this.getLawstextKey("#" + zoneID, planversionId, status);
    if (key == null) {
      key = this.getLawstextKey(zoneType, planversionId, status);
    }

    if (key != null) return this.getTitlePrefix(key, lang);
    else return "";
  }

  public getAllZonesLawText(
    planversionId: string,
    status: string,
    lang: string
  ): string {
    let allZonesText: string = "";
    switch (status) {
      case "approved":
        for (let i = 0; i < this._seaplandata.publishedVersions.length; i++) {
          if (
            this._seaplandata.publishedVersions[i].planversionId ==
            planversionId
          ) {
            if (lang == "en") {
              allZonesText =
                this._seaplandata.publishedVersions[i].allZonesLawText_en;
            } else {
              allZonesText =
                this._seaplandata.publishedVersions[i].allZonesLawText;
            }
            break;
          }
        }
        break;
      case "consultation":
        for (
          let i = 0;
          i < this._seaplandata.consultationVersions.length;
          i++
        ) {
          if (
            this._seaplandata.consultationVersions[i].planversionId ==
            planversionId
          ) {
            if (lang == "en") {
              allZonesText =
                this._seaplandata.consultationVersions[i].allZonesLawText_en;
            } else {
              allZonesText =
                this._seaplandata.consultationVersions[i].allZonesLawText;
            }
            break;
          }
        }
        break;
      default:
        console.log("allZonesLawText is empty");
        break;
    }
    if (status == "consultation" && allZonesText == "") {
      // fallback
      if (this._currentPublishedVersionIdx != -1) {
        let currPublishedVersion =
          this._seaplandata.publishedVersions[this._currentPublishedVersionIdx];
        for (let i = 0; i < this._seaplandata.publishedVersions.length; i++) {
          if (
            this._seaplandata.publishedVersions[i].planversionId ==
            currPublishedVersion.planversionId
          ) {
            if (lang == "en") {
              allZonesText =
                this._seaplandata.publishedVersions[i].allZonesLawText_en;
            } else {
              allZonesText =
                this._seaplandata.publishedVersions[i].allZonesLawText;
            }
            break;
          }
        }
      }
    }
    return allZonesText;
  }

  private getLawsTexts(planversionId: string, status: string) {
    let lawsTexts: any = {};
    let foundLawsTexts = false;
    switch (status) {
      case "approved":
        for (let i = 0; i < this._seaplandata.publishedVersions.length; i++) {
          if (
            this._seaplandata.publishedVersions[i].planversionId ==
            planversionId
          ) {
            lawsTexts = this._seaplandata.publishedVersions[i].lawTexts;
            foundLawsTexts = true;
            break;
          }
        }
        break;
      case "consultation":
        for (
          let i = 0;
          i < this._seaplandata.consultationVersions.length;
          i++
        ) {
          if (
            this._seaplandata.consultationVersions[i].planversionId ==
            planversionId
          ) {
            lawsTexts = this._seaplandata.consultationVersions[i].lawTexts;
            foundLawsTexts = true;
            break;
          }
        }
        break;
      default:
        console.log("getTexts: UNKNOWN");
        break;
    }

    if (foundLawsTexts) {
      return lawsTexts;
    } else {
      return {};
    }
  }

  // text , text_en switcher
  private getLawText(textId: string, lang: string): string {
    let textItems = this._seaplandata.planTexts;
    for (let i = 0; textItems && i < textItems.length; i++) {
      if (textItems[i].textId === textId && textItems[i].published) {
        if (lang == "en") {
          return textItems[i].text_en;
        } else {
          return textItems[i].text;
        }
      }
    }
    return "";
  }

  private getTitlePrefix(textId: string, lang: string) {
    let textItems = this._seaplandata.planTexts;
    for (let i = 0; textItems && i < textItems.length; i++) {
      if (textItems[i].textId === textId && textItems[i].published) {
        if (lang == "en") {
          return textItems[i].zone_title_prefix_en;
        } else {
          return textItems[i].zone_title_prefix;
        }
      }
    }
    return "";
  }

  public getTimeline() {
    let publishedTimeLines = [];
    for (let i = 0; i < this._seaplandata.timeline.length; i++) {
      if (
        new Date(this._seaplandata.timeline[i].start) <= this.currentDateTime
      ) {
        publishedTimeLines.push(this._seaplandata.timeline[i]);
      }
    }
    return publishedTimeLines;
  }
  // TODO FIX WITH UTC TIME
  private findTimeLineIndex() {
    for (let i = 0; i < this._seaplandata.timeline.length; i++) {
      if (
        new Date(this._seaplandata.timeline[i].start) <= this.currentDateTime
      ) {
        if (this._seaplandata.timeline.length == 1) {
          return i;
        }
        let nextIndex = i + 1;
        if (nextIndex < this._seaplandata.timeline.length)
          if (
            new Date(this._seaplandata.timeline[nextIndex].start) >
            this.currentDateTime
          ) {
            return i;
          }
        if (i == this._seaplandata.timeline.length - 1) {
          return i;
        }
      }
    }
    // No timeline match
    return -1;
  }

  private findLastApprovedOrCancelled() {
    let lastApproved = 0;
    for (let i = 0; i < this._seaplandata.timeline.length; i++) {
      if (
        this._seaplandata.timeline[i].timelineType === "PLAN_APPROVED" ||
        this._seaplandata.timeline[i].timelineType === "SUPPLEMENT_APPROVED" ||
        this._seaplandata.timeline[i].timelineType === "CORRECTION_APPROVED" ||
        this._seaplandata.timeline[i].timelineType === "CANCELLATION"
      ) {
        lastApproved = i;
      }
    }
    return lastApproved;
  }

  public findLayerZIndex(layerName: string): number {
    let zIndex = 0;
    let layersConfig = this.currentLayerConfig.layers;
    for (var n = 0; n < layersConfig.length; n++) {
      if (layersConfig[n].name.toLowerCase() == layerName.toLowerCase()) {
        if (layersConfig[n].zindex) {
          return layersConfig[n].zindex;
        }
      } else if (
        layersConfig[n].subtypes &&
        layersConfig[n].subtypes.length > 0
      ) {
        for (var m = 0; m < layersConfig[n].subtypes.length; m++) {
          if (
            layersConfig[n].subtypes[m].name.toLowerCase() ===
            layerName.toLowerCase()
          ) {
            if (layersConfig[n].zindex) {
              return layersConfig[n].zindex;
            }
          }
        }
      }
    }
    return zIndex;
  }

  public getLayerNameByTextName(name: string): string {
    let layerName = name;
    for (
      let i = 0;
      this.currentLayerConfig && i < this.currentLayerConfig.layers.length;
      i++
    ) {
      // Specialcase
      if (name.toLowerCase() == "u") {
        return "zones";
      }
      if (
        this.currentLayerConfig.layers[i].type.toLowerCase() ==
        name.toLowerCase()
      ) {
        return this.currentLayerConfig.layers[i].name;
      }
    }
    return layerName;
  }

  public getPlandataTimeLineInfo(timeIndex: number): PlandataTimeLineInfo {
    let planTimeLineInfo: PlandataTimeLineInfo = new PlandataTimeLineInfo();

    let timelineItem = this._seaplandata.timeline[timeIndex];
    if (timelineItem) {
      let pubVIdx = timelineItem.publishedVersionIdx;
      switch (timelineItem.timelineType) {
        case "PLAN_CONSULTATION_START":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_start_of_see_plan_value"
          );
          for (
            let i = 0;
            i < timelineItem.consultationVersionIdxList.length;
            i++
          ) {
            let conVIdx = timelineItem.consultationVersionIdxList[i];
            if (this._seaplandata.consultationVersions[conVIdx]) {
              if (
                this._seaplandata.consultationVersions[conVIdx]
                  .consultationStart == timelineItem.start
              ) {
                planTimeLineInfo.plantitle =
                  this._seaplandata.consultationVersions[conVIdx].title;
                planTimeLineInfo.plantitle_en =
                  this._seaplandata.consultationVersions[conVIdx].title_en;
                planTimeLineInfo.planConsultationStart =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationStart;
                planTimeLineInfo.planConsultationEnd =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationEnd;
                break;
              }
            }
          }
          break;
        case "PLAN_CONSULTATION_END":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_end_of_see_plan_value"
          );
          for (
            let i = 0;
            i < timelineItem.postConsultationVersionIdxList.length;
            i++
          ) {
            let conVIdx = timelineItem.postConsultationVersionIdxList[i];
            if (this._seaplandata.consultationVersions[conVIdx]) {
              if (
                this._seaplandata.consultationVersions[conVIdx]
                  .consultationEnd == timelineItem.start
              ) {
                planTimeLineInfo.plantitle =
                  this._seaplandata.consultationVersions[conVIdx].title;
                planTimeLineInfo.plantitle_en =
                  this._seaplandata.consultationVersions[conVIdx].title_en;
                planTimeLineInfo.planConsultationStart =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationStart;
                planTimeLineInfo.planConsultationEnd =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationEnd;
                break;
              }
            }
          }
          break;
        case "PLAN_APPROVED":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_force_plan_see_value"
          );
          if (this._seaplandata.publishedVersions[pubVIdx]) {
            if (
              this._seaplandata.publishedVersions[pubVIdx].effectiveDate ==
              timelineItem.start
            ) {
              planTimeLineInfo.plantitle =
                this._seaplandata.publishedVersions[pubVIdx].title;
              planTimeLineInfo.plantitle_en =
                this._seaplandata.publishedVersions[pubVIdx].title_en;
              planTimeLineInfo.planApprovedeffEctiveDate =
                this._seaplandata.publishedVersions[pubVIdx].effectiveDate;
              break;
            }
          }
          break;
        case "SUPPLEMENT_CONSULTATION_START":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_change_label_value"
          );
          for (
            let i = 0;
            i < timelineItem.consultationVersionIdxList.length;
            i++
          ) {
            let conVIdx = timelineItem.consultationVersionIdxList[i];
            if (this._seaplandata.consultationVersions[conVIdx]) {
              if (
                this._seaplandata.consultationVersions[conVIdx]
                  .consultationStart == timelineItem.start
              ) {
                planTimeLineInfo.plantitle =
                  this._seaplandata.consultationVersions[conVIdx].title;
                planTimeLineInfo.plantitle_en =
                  this._seaplandata.consultationVersions[conVIdx].title_en;
                planTimeLineInfo.planConsultationStart =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationStart;
                planTimeLineInfo.planConsultationEnd =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationEnd;
                break;
              }
            }
          }
          break;
        case "SUPPLEMENT_CONSULTATION_END":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_change_value_current"
          );
          for (
            let i = 0;
            i < timelineItem.postConsultationVersionIdxList.length;
            i++
          ) {
            let conVIdx = timelineItem.postConsultationVersionIdxList[i];
            if (this._seaplandata.consultationVersions[conVIdx]) {
              if (
                this._seaplandata.consultationVersions[conVIdx]
                  .consultationEnd == timelineItem.start
              ) {
                planTimeLineInfo.plantitle =
                  this._seaplandata.consultationVersions[conVIdx].title;
                planTimeLineInfo.plantitle_en =
                  this._seaplandata.consultationVersions[conVIdx].title_en;
                planTimeLineInfo.planConsultationStart =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationStart;
                planTimeLineInfo.planConsultationEnd =
                  this._seaplandata.consultationVersions[
                    conVIdx
                  ].consultationEnd;
                break;
              }
            }
          }
          break;
        case "SUPPLEMENT_APPROVED":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_plan_supplement_label_value"
          );
          if (this._seaplandata.publishedVersions[pubVIdx]) {
            if (
              this._seaplandata.publishedVersions[pubVIdx].effectiveDate ==
              timelineItem.start
            ) {
              planTimeLineInfo.plantitle =
                this._seaplandata.publishedVersions[pubVIdx].title;
              planTimeLineInfo.plantitle_en =
                this._seaplandata.publishedVersions[pubVIdx].title_en;
              planTimeLineInfo.planApprovedeffEctiveDate =
                this._seaplandata.publishedVersions[pubVIdx].effectiveDate;
              break;
            }
          }
          break;
        case "CORRECTION_APPROVED":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_plan_correction_value"
          );
          if (this._seaplandata.publishedVersions[pubVIdx]) {
            if (
              this._seaplandata.publishedVersions[pubVIdx].effectiveDate ==
              timelineItem.start
            ) {
              planTimeLineInfo.plantitle =
                this._seaplandata.publishedVersions[pubVIdx].title;
              planTimeLineInfo.plantitle_en =
                this._seaplandata.publishedVersions[pubVIdx].title_en;
              planTimeLineInfo.planApprovedeffEctiveDate =
                this._seaplandata.publishedVersions[pubVIdx].effectiveDate;
              break;
            }
          }
          break;
        case "CANCELLATION":
          planTimeLineInfo.plantype = this.getText(
            this.historyPrefix,
            "history_Cancelled_label_value"
          );
          if (this._seaplandata.publishedVersions[pubVIdx]) {
            planTimeLineInfo.plantitle =
              this._seaplandata.publishedVersions[pubVIdx].title;
            planTimeLineInfo.plantitle_en =
              this._seaplandata.publishedVersions[pubVIdx].title_en;
            planTimeLineInfo.planCancelDate = timelineItem.start;
            // planTimeLineInfo.planApprovedeffEctiveDate = this.getFormateretDatetime(this._seaplandata.publishedVersions[pubVIdx].effectiveDate);
            break;
          }
          break;
        default:
          console.log("NO TYPE: " + timelineItem.timelineType);
          break;
      }
    }
    return planTimeLineInfo;
  }
}
