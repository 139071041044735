import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LanguageService } from "./../services/language.service";
import { PreviewService } from "./../services/preview.service";

export interface Food {
  value: string;
  viewValue: string;
  img: string;
}

@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
})
export class LanguageComponent implements OnInit {
  languages: any = [
    { name: "DAN", icon: "dk" },
    { name: "ENG", icon: "gb" },
  ];
  selectedLanguage: any = null;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private previewService: PreviewService
  ) {
    let selectedLang = this.languageService.getLanguage();
    if (!selectedLang) {
      this.languageService.prepareLanguage();
    } else {
      if (selectedLang == "da") {
        this.selectedLanguage = { name: "DAN", icon: "dk" };
      } else if (selectedLang == "en") {
        this.selectedLanguage = { name: "ENG", icon: "gb" };
      }
    }
  }

  ngOnInit() {
  }

  langChange(language: any) {
    if (language && language.name) {
      if (language.name == "DAN") {
        this.selectedLanguage = language;
        this.loadPath("da");
      } else if (language.name == "ENG") {
        this.selectedLanguage = language;
        this.loadPath("en");
      }
    }
  }

  loadPath(to) {
    let previewParam = this.previewService.getQuerystringParamName();
    let previewParamValue = this.previewService.getQuerystringParamValue();
    this.router.navigate(["/" + to], { queryParams: { isLoaded: false,[previewParam]: previewParamValue } });
    this.previewService.getContentPreviewID
    this.languageService.setLanguage(to);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
