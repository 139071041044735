import {RouteReuseStrategy,ActivatedRouteSnapshot,DetachedRouteHandle
} from '@angular/router';


export class RouteReuseService implements RouteReuseStrategy {


    private handlers: { [key: string]: DetachedRouteHandle } = {};



    shouldDetach(route: ActivatedRouteSnapshot): boolean {

        if (!route.routeConfig || route.routeConfig.loadChildren) {

        return false;

        }
       
        let shouldReuse = false;

      //  console.log('checking if this route should be re used or not', route);

        if (route.routeConfig.data) {

        route.routeConfig.data.reuse ? shouldReuse = true : shouldReuse = false;

        }

        return shouldReuse;

    }



    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
        let url = ""; 
        for(let i = 0; i < route.children.length; i++) {
            url = route.routeConfig.path+"/"+route.children[i].routeConfig.path;
        }   
        this.handlers[url] = handler;
 
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.handlers[this.getUrl(route)];
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {

        if (!route.routeConfig || route.routeConfig.loadChildren) {
            return null;
        };
        return this.handlers[this.getUrl(route)];
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {

        let reUseUrl = false;
        if (future.routeConfig) {

            if (future.routeConfig.data) {
                reUseUrl = future.routeConfig.data.reuse;
            }
        }

        const defaultReuse = (future.routeConfig === current.routeConfig);
            return reUseUrl || defaultReuse;

        }

    getUrl(route: ActivatedRouteSnapshot): string {

        if (route.routeConfig) {
            const url = route.routeConfig.path;
            return url;
        }

    }

}