import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private _lang: string = null;
  // private _changeLang = new Subject<any>();

  constructor() {}

  setLanguage(lang) {
    this._lang = lang;
    // this._changeLang.next(lang);
  }

  getLanguage() {
    if (!this._lang) {
      this.prepareLanguage();
    }
    return this._lang;
  }

  prepareLanguage() {
    if (this._lang) {
      return this._lang;
    } else {
      let origin = window.location.href;
      if (origin.indexOf("/da/") != -1) {
        this.setLanguage("da")
      } else if (origin.indexOf("/en/") != -1) {
        this.setLanguage("en")
      }else{
        //Set default language if not founded from URL.
        this.setLanguage("da")
      }
      return this._lang;
    }
  }

  // onLanguageChange(): Observable<any> {
  //   return this._changeLang.asObservable();
  // }
}
