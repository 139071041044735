import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { ContentService } from "../services/content.service";
import { LanguageService } from "../services/language.service";
import { MapService } from "../services/map.service";
import { PlandataService } from "../services/plandata.service";

@Component({
  selector: "app-change-marking-switcher",
  templateUrl: "./change-marking-switcher.component.html",
  styleUrls: ["./change-marking-switcher.component.scss"],
})
export class ChangMarkingSwitcherComponent implements OnInit, OnDestroy {
  showChangeMarkings: boolean = false;
  changeMarkingLayers: any[] = [];
  currentLang: string = null;
  mapContent: any;
  contentString: any;
  mapPrefix = "/plan/map";

  isLayerAvailable = {
    removed: false,
    changed: false,
  };

  private subscriptions: Subscription[] = new Array();

  constructor(
    private mapService: MapService,
    private planDataService: PlandataService,
    private languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private contentService: ContentService
  ) {
    this.currentLang = this.languageService.prepareLanguage();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.planDataService
        .onLayerConfigEventChanged()
        .subscribe((layerConfig) => {
          const changeLayers = layerConfig.layers.filter(
            (l) => l.name == "changeMarking"
          )[0];
          if (changeLayers != null) {
            this.changeMarkingLayers = changeLayers.subtypes;

            setTimeout(() => {
              this.isLayerAvailable["removed"] = false;
              this.isLayerAvailable["changed"] = false;

              if (this.planDataService.showLegendForConsultation()) {
                this.planDataService.currentConsultationLayers.forEach((l) => {
                  if (l.name == "removed" || l.name == "changed") {
                    this.isLayerAvailable[l.name] = true;
                  }
                });
              } else {
                this.planDataService.currentPublishedLayers.forEach((l) => {
                  if (l.name == "removed" || l.name == "changed") {
                    this.isLayerAvailable[l.name] = true;
                  }
                });
                this.planDataService.currentConsultationLayers.forEach((l) => {
                  if (l.name == "removed" || l.name == "changed") {
                    this.isLayerAvailable[l.name] = true;
                  }
                });
              }

              this.onChangeMarkingToggle();
            }, 300);
          }
        })
    );
  }

  onChangeMarkingToggle() {
    this.showChangeMarkings = true; //!this.showChangeMarkings;
    this.mapService.setChangeMarkingLayersVisibility(
      "removed",
      this.showChangeMarkings
    );
    this.mapService.setChangeMarkingLayersVisibility(
      "changed",
      this.showChangeMarkings
    );

    if (this.changeMarkingLayers) {
      this.changeMarkingLayers.forEach((layer) => {
        layer.active = this.showChangeMarkings;
        // this.handlingActiveLayerType(layer);
        // this.mapService.highlightZoneType(layer.type, layer.active);
      });
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  toggleLayer(layer: any) {
    layer.active = !layer.active;
    this.mapService.setChangeMarkingLayersVisibility(layer.type, layer.active);
    // this.handlingActiveLayerType(layer);
    // this.mapService.highlightZoneType(layer.type, layer.active);
  }

  getText(prefix: string, shortName: string) {
    this.mapContent = this.contentService.getContentByPrefix(
      this.currentLang + "/plan/map"
    );
    return (this.contentString = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
