import { ContentService } from "./../../services/content.service";
import { LanguageService } from "./../../services/language.service";
import Control, { Options } from "ol/control/Control";
import { Vector as VectorLayer } from "ol/layer";
import Feature from "ol/Feature";
import Map from "ol/Map";

export default class DrawingTool extends Control {
  private _mapCompoent: any;
  private _options: any;
  private _hiddenClassName: any;
  element: any;
  private _toolTypes: any;
  private _interActions: any;
  private _editVectorLayer: VectorLayer;
  private _map: Map;
  private _active: boolean;
  private currentLang: string = null;
  private contentService: any;
  content: any;
  mapPrefix = "/plan/map";

  constructor(
    opt_options,
    contentService: ContentService,
    languageService: LanguageService
  ) {
    super({ element: document.createElement("div") });
    this.currentLang = languageService.prepareLanguage();
    this.contentService = contentService;
    this._options = (opt_options as any) || {};
    this._interActions = this._options.interActions;
    this._editVectorLayer = this._options.editVectorLayer;
    this._mapCompoent = this._options.mapComponent;
    this._hiddenClassName = "hidden-drawing-control";
    this.element.className = "drawing-control hidden-drawing-control";

    this._toolTypes = {};
    const navigationBtn = document.createElement("div");
    navigationBtn.setAttribute("title", this.getText(this.mapPrefix,'map_drawtool_arrow'));
    navigationBtn.className = "drawing-btn";
    const navigationI = document.createElement("img");
    navigationI.src = "assets/cursor.svg";
    navigationI.alt = "Naviger";
    navigationBtn.appendChild(navigationI);
    this.element.appendChild(navigationBtn);
    this._toolTypes["navigation"] = navigationBtn;

    const pointBtn = document.createElement("div");
    pointBtn.setAttribute("title", this.getText(this.mapPrefix,'map_drawtool_dot'));
    pointBtn.className = "drawing-btn draw-point-btn";
    const pointI = document.createElement("img");
    pointI.src = "assets/dot.svg";
    pointI.alt = "Tegn punkt";
    pointBtn.appendChild(pointI);
    this.element.appendChild(pointBtn);
    this._toolTypes["drawPoint"] = pointBtn;

    const lineBtn = document.createElement("div");
    lineBtn.setAttribute("title", this.getText(this.mapPrefix,'map_drawtool_hyphen'));
    lineBtn.className = "drawing-btn draw-line-btn";
    const lineI = document.createElement("img");
    lineI.src = "assets/line.svg";
    lineI.alt = "Tegn linje";
    lineBtn.appendChild(lineI);
    this.element.appendChild(lineBtn);
    this._toolTypes["drawLine"] = lineBtn;

    const polygonBtn = document.createElement("div");
    polygonBtn.setAttribute("title", this.getText(this.mapPrefix,'map_drawtool_flat'));
    polygonBtn.className = "drawing-btn draw-polygon-btn";
    const polygonI = document.createElement("img");
    polygonI.src = "assets/shape.svg";
    polygonI.alt = "Tegn flade";
    polygonBtn.appendChild(polygonI);
    this.element.appendChild(polygonBtn);
    this._toolTypes["drawPolygon"] = polygonBtn;

    const deleteBtn = document.createElement("div");
    deleteBtn.setAttribute("title", this.getText(this.mapPrefix,'map_drawtool_click_to_delete'));
    deleteBtn.className = "drawing-btn draw-delete-btn";
    const trashI = document.createElement("img");
    trashI.src = "assets/delete.svg";
    trashI.alt = "Slet";
    deleteBtn.appendChild(trashI);
    this.element.appendChild(deleteBtn);
    this._toolTypes["delete"] = deleteBtn;

    deleteBtn.addEventListener("click", this.deleteFeature.bind(this));
    navigationBtn.addEventListener(
      "click",
      this.setInterActions.bind(this, "", ["navigation"]),
      false
    );
    pointBtn.addEventListener(
      "click",
      this.setInterActions.bind(this, "POINT", ["drawPoint"]),
      false
    );
    lineBtn.addEventListener(
      "click",
      this.setInterActions.bind(this, "LINE", ["drawLine"]),
      false
    );
    polygonBtn.addEventListener(
      "click",
      this.setInterActions.bind(this, "POLYGON", ["drawPolygon"]),
      false
    );
  }

  deleteFeature() {
    this._editVectorLayer.getSource().clear();
  }

  checkGeometryTypeChange(drawingType: string): boolean {
    const features = this._editVectorLayer
      .getSource()
      .getFeatures() as Feature[];
    let activeType: string;
    for (const key in features) {
      if (key) {
        const type = features[key].getGeometry().getType() as string;
        if (type.toUpperCase().indexOf("POINT") > -1) {
          activeType = "POINT";
        }
        if (type.toUpperCase().indexOf("LINE") > -1) {
          activeType = "LINE";
        }
        if (type.toUpperCase().indexOf("POLYGON") > -1) {
          activeType = "POLYGON";
        }
      }
    }
    if (activeType && drawingType !== activeType) {
      return true;
    }
    return false;
  }

  changeDrawingType(tools: string[]) {
    this._editVectorLayer.getSource().clear();
    this._editVectorLayer.setVisible(true);
    this.setActiveTools(tools);
  }

  setInterActions(drawingType: string, tools: string[]): void {
    this.setActiveTools(tools);
    this._editVectorLayer.setVisible(true);
    if (
      drawingType !== "" &&
      this.checkGeometryTypeChange(drawingType) &&
      this._editVectorLayer.getSource().getFeatures().length > 0
    ) {
      console.log("Changing tool");
      //this._mapCompoent.showConfirmBox(tools);
      return;
    }
  }

  private setActiveTools(tools: string[]) {
    for (const key in this._interActions) {
      if (tools.indexOf(key) > -1) {
        this._interActions[key].setActive(true);
      } else {
        this._interActions[key].setActive(false);
      }
    }
    if (this._interActions["select"]) {
      this._interActions["select"].getFeatures().clear();
    }
    for (const key in this._toolTypes) {
      if (key) {
        if (tools.findIndex((p) => p === key) > -1) {
          this._toolTypes[key].className =
            this._toolTypes[key].className + " drawing-btn-active";
        } else {
          this._toolTypes[key].className = this._toolTypes[
            key
          ].className.replace(" drawing-btn-active", "");
        }
      }
    }
  }

  setMap(map) {
    super.setMap(map);
    this._map = map;
  }

  setTypes(types: string[]): void {
    for (const key in this._toolTypes) {
      if (key) {
        if (
          key !== "navigation" &&
          key !== "delete" &&
          this._toolTypes[key].className.indexOf("hidden-drawing-control") < 0
        ) {
          this._toolTypes[key].className =
            this._toolTypes[key].className + " hidden-drawing-control";
        }
      }
    }

    for (const index in types) {
      if (index) {
        switch (types[index].toLocaleUpperCase()) {
          case "POINT":
            this._toolTypes["drawPoint"].className = this._toolTypes[
              "drawPoint"
            ].className.replace(" hidden-drawing-control", "");
            break;
          case "POLYLINE":
            this._toolTypes["drawLine"].className = this._toolTypes[
              "drawLine"
            ].className.replace(" hidden-drawing-control", "");
            break;
          case "POLYGON":
            this._toolTypes["drawPolygon"].className = this._toolTypes[
              "drawPolygon"
            ].className.replace(" hidden-drawing-control", "");
            break;
          default:
            break;
        }
      }
    }
  }

  isDrawActive(): boolean {
    return this._active;
  }

  private disableDrawInteractions() {
    for (const key in this._interActions) {
      if (key) {
        this._interActions[key].setActive(false);
      }
    }
  }

  setVisble(display: boolean) {
    this._active = display;
    if (!display && !this.element.classList.contains(this._hiddenClassName)) {
      this.element.classList.add(this._hiddenClassName);
      this.disableDrawInteractions();
    } else {
      if (display && this.element.classList.contains(this._hiddenClassName)) {
        this.element.classList.remove(this._hiddenClassName);
      }
    }
  }

  getText(prefix: string, shortName: string) {
    return (this.content = this.contentService.getTextByShortName(
      this.currentLang + prefix,
      shortName
    ));
  }
}
